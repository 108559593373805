import styled from 'styled-components';

const Container = styled.div`
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  height: calc(100% - 66px);
  background-color: #fff;
  &&& > .ant-row {
    height: 100%;
    margin-left: -15px !important;
    margin-right: -15px !important;
    & > .ant-col {
      margin-top: 20px;
      &.left-sidebar {
        background-color: #f0f0f0;
        margin-top: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
`;

export default Container;
