import { message } from 'antd';
import Modal from 'components/commons/Modal';
import { RateType } from 'models/class.model';
import { ReactElement, useEffect, useState } from 'react';
import { useGetRateQuery, useSendRateMutation } from 'services/class.service';
import RateContent from './RateContent';

interface ModalProps {
  isShow: boolean;
  closeModal: () => void;
  classId: string;
  lessonId: string;
}

const RateModal = ({ isShow, closeModal, classId, lessonId }: ModalProps): ReactElement => {
  const [rate, setRate] = useState<RateType>({
    student_note: '',
    vote_content: 0,
    vote_teacher: 0,
  });
  const { data } = useGetRateQuery({ id: classId, lessonId });
  const lastReview = data ?? ({} as RateType);
  const [rateLesson] = useSendRateMutation();

  const handleSetRateValue = (field: string, value: number | string): void => {
    setRate({ ...rate, [field]: value });
  };

  const sendRate = () => {
    rateLesson({
      id: classId,
      lessonId,
      data: rate,
    })
      .unwrap()
      .then(() => {
        message.success('Cảm ơn vì đánh giá của bạn 😇');
      })
      .catch((error) => {
        message.error(error.data);
      });
    closeModal();
  };

  useEffect(() => {
    setRate(lastReview);
  }, [lastReview]);

  return (
    <Modal
      title="Đánh giá buổi học"
      visible={isShow}
      closable={false}
      keyboard={false}
      onOk={sendRate}
      onCancel={() => closeModal()}
      cancelText={<span>Huỷ bỏ</span>}
      // okButtonProps={{
      //   disabled: rate.vote_content !== 0 && rate.vote_teacher !== 0 ? false : true,
      // }}
      okText={<span>Xác nhận</span>}
    >
      <RateContent
        {...rate}
        handleRate={(field: string, value: string | number) => handleSetRateValue(field, value)}
      />
    </Modal>
  );
};

export default RateModal;
