import { ReactElement } from 'react';
import LoadingSpin from 'libraries/components/LoadingSpin';
import ProfileContainer from './ProfileContainer';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/auth.slice';

const ProfilePage = (): ReactElement => {
  const data = useSelector(selectUser);

  if (data) {
    return <ProfileContainer {...data} />;
  } else {
    return <LoadingSpin />;
  }
};

export default ProfilePage;
