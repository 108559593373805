import { Select as AntSelect, SelectProps } from 'antd';
import { useGetFindLectureQuery } from 'services/onlinecourse.service';
import React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { selectSearch } from 'store/onlinecourse.slice';
import { v4 } from 'uuid';
import LoadingSpin from 'libraries/components/LoadingSpin';
import { SearchOutlined } from '@ant-design/icons';
import { SelectValue } from 'antd/lib/select';
import { generatePath, useHistory } from 'react-router-dom';
import { learning } from 'routers/routeNames';
import { useSelector } from 'react-redux';
import type { DefaultOptionType } from 'antd/es/select';
import type { RefSelectProps } from 'antd/lib/select/';
type OptionData = DefaultOptionType;
type OptionGroupData = DefaultOptionType & { options: OptionData[] }
import ShowNotFound from 'components/commons/ShowNotFound';
import './style/StyledSelect.css';

type Props = {
  classId: string;
  value?: string;
};

interface StyledSelectProps extends SelectProps<any, DefaultOptionType> {
  $isSearching?: boolean;
}

const Select = React.forwardRef<RefSelectProps, StyledSelectProps>((props, ref) => (
  <AntSelect {...props} ref={ref} className={`styled-select ${props.$isSearching ? 'styled-select-searching' : ''}`} />
));
Select.displayName = 'Select';

const FindLectureInLesson = ({ classId, value = '' }: Props) => {
  const [keyword, setKeyword] = useState('');
  const [search, setSearch] = useState(false);
  const history = useHistory();
  const firstRender = useRef(true);
  const { isLoading, isError, isFetching, error } = useGetFindLectureQuery(
    {
      classes_id: classId,
      keyword: keyword,
    },
    { skip: keyword.length <= 2 },
  );
  const searchResults = useSelector(selectSearch);
  const selectRef = useRef<RefSelectProps>(null);

  useEffect(() => {
    if (!value) return;
    setKeyword(value);
    if (firstRender.current) {
      firstRender.current = false;
    }

    return () => {
      setKeyword('');
    };
  }, [value]);

  const handleSearch = (search: string) => {
    search.length === 0 ? setKeyword(value) : setKeyword(search);
  };

  const optionsGr: OptionGroupData[] = useMemo(
    () =>
      searchResults.map((result, index) => ({
        label: `${index === 0 ? 'Buổi học :' : ''} ${result.name}`,
        key: v4(),
        options: result.lectures.map((lecture) => ({
          title: lecture.title,
          value: lecture.id,
          label: lecture.title,
          lesson_id: result.id,
        })),
      })),
    [searchResults],
  );

  const handleSelect = (arg: SelectValue, options: OptionGroupData | OptionData) => {
    history.push(
      generatePath(learning.learn, {
        classId,
        lessonId: options.lesson_id,
        lectureId: options.value as string,
      }),
    );
  };

  const handleBlur = () => {
    setKeyword(value);
    setSearch(false);
  };

  const handleClickSearch = () => {
    setSearch(true);
    selectRef.current?.focus();
  };

  return (
    <Select
      onClick={handleClickSearch}
      ref={selectRef}
      placeholder="Tìm kiếm bài giảng ..."
      value={keyword}
      filterOption={false}
      showSearch
      bordered={false}
      suffixIcon={<SearchOutlined />}
      options={optionsGr}
      onSelect={handleSelect}
      style={{ width: search ? '100%' : 0, border: search ? '2px solid #fff' : 'none' }}
      $isSearching={search}
      loading={isLoading}
      notFoundContent={
        isFetching ? (
          <LoadingSpin size="small" text="" fit={true} />
        ) : isError ? (
          <ShowNotFound error={error} />
        ) : null
      }
      onSearch={handleSearch}
      onBlur={handleBlur}
      size="large"
      popupClassName="my-dropdown"
      dropdownAlign={{ offset: [-12, 12] }}
    />
  );
};

export default FindLectureInLesson;