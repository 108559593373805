import { Space, Tag } from 'antd';

export const tagCurrent = (type: string) => {
  switch (type) {
    case 'self-learn':
      return (
        <Space style={{ marginLeft: 8 }} size={[0, 8]} wrap>
          <Tag color="#7030a0" style={{ marginRight: 0, borderRadius: 4 }}>
            Tự học
          </Tag>
        </Space>
      );
    case 'in-class':
      return (
        <Space style={{ marginLeft: 8 }} size={[0, 8]} wrap>
          <Tag style={{ marginRight: 0, borderRadius: 4 }} color="#ffc000">
            Làm trên lớp
          </Tag>
        </Space>
      );
    case 'at-home':
      return (
        <Space style={{ marginLeft: 8 }} size={[0, 8]} wrap>
          <Tag style={{ marginRight: 0, borderRadius: 4 }} color="#7030a0">
            Làm ở nhà
          </Tag>
        </Space>
      );
    case 'at-home':
      return (
        <Space style={{ marginLeft: 8 }} size={[0, 8]} wrap>
          <Tag style={{ marginRight: 0, borderRadius: 4 }} color="#7030a0">
            Làm ở nhà
          </Tag>
        </Space>
      );
    case 'reference':
      return (
        <Space style={{ marginLeft: 8 }} size={[0, 8]} wrap>
          <Tag style={{ marginRight: 0, borderRadius: 4 }} color="#7030a0">
            Xem thêm{' '}
          </Tag>
        </Space>
      );
    case 'advance':
      return (
        <Space style={{ marginLeft: 8 }} size={[0, 8]} wrap>
          <Tag style={{ marginRight: 0, borderRadius: 4 }} color="#7030a0">
            Nâng cao{' '}
          </Tag>
        </Space>
      );
  }
};
