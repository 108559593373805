import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base.service';
import { LectureComment } from 'models/comment.model';

interface ContentComment {
  text: string;
  parent_id?: string;
}
interface DeleteComment {

classId: string;
  commentId: string;
  lectureId: string;
}
interface EditComment extends DeleteComment {
  contentEdit: { text: string };
}
interface PostComment {

  classId: string;
  data: ContentComment;
  lectureId: string;
}

export const commentApi = createApi({
  reducerPath: 'commentApi',
  baseQuery,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getLectureComments: builder.query<LectureComment[], {classId:string, lectureId:string}>({
      query: ({ classId, lectureId }) => {
        return { url: `classes/${classId}/lectures/${lectureId}/comments`, method: 'GET' };
      },
      transformResponse: (response: LectureComment[]) => {
        response = response ?? ([] as LectureComment[]);
        response.length > 0 &&
          response.map((item) => {
            item.created_at = item.created_at.includes('0001-01-01T00') ? '' : item.created_at;
          });
        return response;
      },
    }),

    postComment: builder.mutation<LectureComment, PostComment>({
      query: (arg) => {
        const {classId, data, lectureId } = arg;

        return {
          url: `classes/${classId}/lectures/${lectureId}/comments`,
          method: 'POST',
          body: data,
        };
      },
    }),

    replyComment: builder.mutation<LectureComment, PostComment>({
      query: (arg) => {
        const {classId, data, lectureId } = arg;

        return {
          url: `classes/${classId}/lectures/${lectureId}/comments`,
          method: 'POST',
          body: data,
        };
      },
      transformResponse: (response: LectureComment) => {
        response.sub_comments = response.sub_comments ?? [];
        return response;
      },
    }),

    deleteComment: builder.mutation<
      { classId: string; message: string; parent_id: string;id:string },
      DeleteComment
    >({
      query: (arg) => {
        const { classId, commentId ,lectureId} = arg;
        return {
          url: `classes/${classId}/lectures/${lectureId}/comments/${commentId}`,
          method: 'DELETE',
        };
      },
    }),
    editComment: builder.mutation<void, EditComment>({
      query: (arg) => {
        const { classId, commentId, contentEdit,lectureId } = arg;
        return {
          url: `classes/${classId}/lectures/${lectureId}/comments/${commentId}`,
          method: 'POST',
          body: contentEdit,
        };
      },
    }),
  }),
});

export const {
  useGetLectureCommentsQuery,
  useDeleteCommentMutation,
  useEditCommentMutation,
  usePostCommentMutation,
  useReplyCommentMutation,
} = commentApi;
