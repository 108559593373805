const getQuizTrue = (Quizzes: any) => {
  let is_true = true;
  if (Quizzes.is_true == false && !Quizzes.quizzes) {
    return false;
  }
  Quizzes?.quizzes?.forEach((item1: any) => {
    if (item1.is_true == false && !item1.quizzes) {
      is_true = false;
    }
    item1?.quizzes?.forEach((item2: any) => {
      if (item2.is_true == false && !item2.quizzes) {
        is_true = false;
      }
    });
  });

  return is_true;
};
export default getQuizTrue;
