import styled from 'styled-components';

const LecturePageContainer = styled.div`
  position: relative;
  margin-top: 67px;
  a.switch-lecture {
    display: inline-block;
    opacity: 0.7;
    width: 22px;
    height: 40px;
    line-height: 40px;
    background: #5a5a5a;
    color: #fff;
    text-align: center;
    position: absolute;
    top: calc(50% - 20px);
    z-index: 1;
    user-select: none;
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
      @media screen and (min-width: 992px) {
        right: 0;
      }
    }
  }
  a.switch-lecture:hover {
    opacity: 0.9;
  }
  @media screen and (max-width: 991px) {
    position: unset !important;
  }
`;

export default LecturePageContainer;
