import { Avatar, Comment, message, Tooltip } from 'antd';
import ShowMarkdown from 'libraries/components/markdown/ShowMarkdown';
import { LectureComment } from 'models/comment.model';
import { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDeleteCommentMutation, useEditCommentMutation } from 'services/comment.service';
import { selectUser } from 'store/auth.slice';
import { selectLecture } from 'store/onlinecourse.slice';
import timeDistanceToNow from 'utils/timeDistanceToNow';
import { EditCommentEditor } from './CommentEditor';
import DeleteButton from './buttons/DeleteButton';
import EditButton from './buttons/EditButton';
import { useParams } from 'react-router';
interface SubComment {
  sub_comment: LectureComment;
  showMainCommentEditor: (value: boolean) => void;
}

const SubComment = ({ sub_comment, showMainCommentEditor }: SubComment): ReactElement => {
  const [editComment] = useEditCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();
  const { classId: classId } = useParams<{ classId: string }>();
  const user = useSelector(selectUser);
  const lecture = useSelector(selectLecture);
  const { id: lectureId } = lecture;
  const { id: userId } = user;
  const { id, text, author_id, author_name, author_avatar, created_at } = sub_comment;

  const [showEditSubCommentEditor, setShowSubCommentEditor] = useState(false);
  const [contenSubComment, setContentSubComment] = useState(text);

  const handleCancel = () => {
    setShowSubCommentEditor(false);
    showMainCommentEditor(true);
  };

  //// DELETE - DONE
  const handleDelete = () => {
    deleteComment({ classId, lectureId, commentId: id })
      .unwrap()
      .then(() => message.success('Xoá bình luận thành công'))
      .catch((error) => {
        message.error(error.data);
      });
  };

  //// EDIT - DONE
  const editSubCommentBtn = () => {
    showMainCommentEditor(false);
    setShowSubCommentEditor(true);
  };

  const handleEditSubComment = (e: string) => {
    setContentSubComment(e);
  };

  const handleEditSubCommentSubmit = () => {
    setShowSubCommentEditor(false);
    showMainCommentEditor(true);
    editComment({
      classId,
      lectureId,
      commentId: id,
      contentEdit: { text: contenSubComment },
    })
      .unwrap()
      .then(() => message.success('Chỉnh sửa bình luận thành công'))
      .catch(() => message.error('Chỉnh sửa bình luận thất bại'));
  };

  

  const multiAction = [
    <EditButton key="comment-edit" handleEdit={editSubCommentBtn} />,
    <DeleteButton key="comment-delete" handleDelete={handleDelete} />,
  ];
  //   const singleAction = [<ReplyButton key="comment-reply" handleReply={handleReplyBtn} />];
  return (
    <>
      <Comment
        key={id}
        actions={userId === author_id ? multiAction : []}
        author={<h3>{author_name}</h3>}
        avatar={<Avatar src={author_avatar} alt={author_name} />}
        content={
          showEditSubCommentEditor ? (
            <EditCommentEditor
              value={contenSubComment}
              handleChange={handleEditSubComment}
              handleSubmit={handleEditSubCommentSubmit}
              handleCancel={handleCancel}
            />
          ) : (
            <ShowMarkdown markdown={contenSubComment} />
          )
        }
        datetime={
          <Tooltip title={created_at}>
            <span>{timeDistanceToNow(created_at)}</span>
          </Tooltip>
        }
      />
    </>
  );
};

export default SubComment;
