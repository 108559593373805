import { Avatar, Comment as AntComment, message } from 'antd';
import { forwardRef, ReactElement, Ref, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePostCommentMutation } from 'services/comment.service';
import { selectUser } from 'store/auth.slice';
import { CommentEditor } from './CommentEditor';
import ListComment from './ListComment';
import { useParams } from 'react-router';
interface NewUserCommentProps {

  lectureId: string;
}

const NewLectureComment = forwardRef(
  ({ lectureId }: NewUserCommentProps, ref: Ref<HTMLDivElement>): ReactElement => {
    const { classId: classId } = useParams<{ classId: string }>();
    const [postComment] = usePostCommentMutation();
    const [showCommentEditor, setShowCommentEditor] = useState(true);
    const [commentValue, setCommentValue] = useState('');

    const user = useSelector(selectUser);
    const { avatar, full_name } = user;

    const handleChange = (e: string): void => {
      setCommentValue(e);
      console.log('Changing comment value:', e);
    };

  const handleSubmit = () => {
    if (!commentValue) return;
      postComment({ classId,lectureId , data: { text: commentValue }, })
        .unwrap()
        .then(() => {
          message.success('Bình luận thành công');
        setCommentValue('');
          handleChange('');
        })
      .catch((error) => {
          message.error(error.data);
        });
    }; 
    return (
      <div ref={ref}>
        {/* <div style={{height}}> */}
        <ListComment setShowCommentEditor={setShowCommentEditor} />
        {/* </div> */}
    {showCommentEditor && (
          <AntComment
            avatar={<Avatar src={avatar} alt={full_name} />}
            content={
              <CommentEditor
                value={commentValue}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
            }
          />
        )}
      </div>
    );
  },
);

NewLectureComment.displayName = 'NewLectureComment';

export default NewLectureComment;
