import { Col, Row } from 'antd';
import DefaultSubjectThumbnail from 'assets/images/subject-default.jpeg';
import Progress from 'components/commons/Progress';
import { ClassList } from 'models/class.model';
import { ReactElement } from 'react';
import styled from 'styled-components';
import SubjectTag from './SubjectTag';
import SubjectTitle from './SubjectTitle';
import Thumbnail from './Thumbnail';

const Wrapper = styled.div`
  padding: 10px;
  background-color: #fff;
  border: 1px solid rgba(140, 140, 141, 0.48);
`;
// box-shadow: 0 2px 10px #e4e8ee;

const SubjectProgessBar = styled(Progress)`
  margin-bottom: 10px;
  .ant-progress-inner {
    border-radius: unset;
    background-color: #8c8c8d;
    .ant-progress-bg {
      height: 5px !important;
      border-radius: unset;
      background-color: #23c9ac;
    }
  }
`;

const SubjectProgess = styled.span`
  font-family: Mulish;
  font-size: 14px;
  color: #8c8c8d;
`;

const SubjectDescription = styled.div``;

const Subject = ({ learned, total_learns, thumbnail, type, name }: ClassList): ReactElement => {
  const progress =
    learned === 0 ? 0 : learned > total_learns ? 100 : Math.floor((learned / total_learns) * 100);
  const Tag = type === 'lab' ? SubjectTag.Offline : SubjectTag.Online;
  const image = thumbnail !== '' ? thumbnail : DefaultSubjectThumbnail;

  return (
    <Wrapper>
      <Thumbnail>
        <img src={image} alt={name} />
      </Thumbnail>

      <SubjectDescription>
        <SubjectTitle>{name}</SubjectTitle>

        <SubjectProgessBar strokeWidth={12} percent={progress} showInfo={false} />

        <Row justify="space-between">
          <Col>
            <Tag />
          </Col>
          <Col>
            <SubjectProgess>{progress || 0}%</SubjectProgess>
          </Col>
        </Row>
      </SubjectDescription>
    </Wrapper>
  );
};

Subject.defaultProps = {
  link: '/lop-hoc/web-frontend-react-js',
  Name: 'Git từ cơ bản đến nâng cao',
  Lessons: 12,
  TotalLessons: 12,
  Type: 'lab',
};

export default Subject;
