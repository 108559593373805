import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

const Info = styled.div`
  width: 70px;
  & > div {
    margin-top: 10px;
  }
  .anticon {
    cursor: pointer;
  }
  .anticon-delete {
    margin-left: 10px;
  }
`;

const Content = styled.div`
  width: calc(100% - 70px);
  position: relative;
  .add-more-note {
    width: 100%;
    text-align: left;
    height: 45px;
    line-height: 25px;
    padding-left: 15px;
    font-size: 16px;
  }
  .anticon-plus-circle {
    position: absolute;
    right: 15px;
    top: 15px;
  }
  .note-lesson {
    font-weight: 600;
    margin-right: 20px;
    cursor: pointer;
  }
  .note-lecture {
    font-size: 14px;
    color: #666;
    cursor: pointer;
  }
  .note-content {
    padding: 15px;
    background-color: rgba(140, 140, 141, 0.1);
  }
  @media screen and (min-width: 992px) {
    max-width: 800px;
  }
`;

export default { Wrapper, Info, Content };
