import { Checkbox as AntCheckbox, Radio as AntRadio } from 'antd';
import styled from 'styled-components';

const Radio = styled(AntRadio.Group)`
  width: 100%;
  p {
    margin-bottom: 0;
  }
  .ant-space {
    width: 100%;
  }
  .ant-radio-wrapper {
    position: relative;
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    border: 1px solid #8c8c8d;
    border-radius: 4px;
    &.ant-radio-wrapper-checked {
      .ant-radio-disabled + span {
        color: #fff;
      }
    }
    /* &.ant-radio-wrapper-checked.right-answer {
      border-color:#58a700;
      background-color: #d8ecd2;
      .anticon.anticon-check {
        opacity: 1;
        visibility: visible;
        color:#58a700;
      }
      .show-markdown {
        color: #4fb6a7;
        font-weight: bold;
      }
    }
    &.ant-radio-wrapper-checked.wrong-answer {
      border-color: rgb(229, 115, 115);
      background-color: #f9e0df;
      .anticon.anticon-close {
        opacity: 1;
        visibility: visible;
        color: rgb(229, 115, 115);
      }
      .show-markdown {
        color: rgb(227, 107, 134);
        font-weight: bold;
      }
    } */
    .ant-radio-disabled + span {
      color: initial;
    }
    .ant-radio {
      top: 0;
    }
    /* @media screen and (max-width: 991px) {
      .anticon {
        display: none;
      }
    } */
    .anticon {
      position: absolute;
      top: calc(50% - 10px);
      right: 10px;
      font-size: 20px;
      opacity: 0;
      visibility: hidden;
    }
    &.ant-radio-wrapper-checked {
      .ant-radio-disabled + span {
        color: initial;
      }
    }
    &.ant-radio-wrapper-checked.right-answer {
      border-color:#58a700;
      background-color: #d8ecd2;
      .anticon.anticon-check {
        opacity: 1;
        visibility: visible;
        color:#58a700;
      }
      .show-markdown {
        color: #4fb6a7;
        font-weight: bold;
      }
    }
    &.ant-radio-wrapper-checked.wrong-answer {
      border-color: rgb(229, 115, 115);
      background-color: #f9e0df;
      .anticon.anticon-close {
        opacity: 1;
        visibility: visible;
        color: rgb(229, 115, 115);
      }
      .show-markdown {
        color: rgb(227, 107, 134);
        font-weight: bold;
      }
    }
    .ant-radio-disabled + span {
      color: initial;
    }
  }
`;

const Checkbox = styled(AntCheckbox.Group)`
  p {
    margin-bottom: 0;
  }
  .ant-checkbox-wrapper {
    position: relative;
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    border: 1px solid #8c8c8d;
    border-radius: 4px;
    &.ant-checkbox-wrapper-checked {
      .ant-checkbox-disabled + span {
        color:#58a700;
      }
    }
    &.ant-checkbox-wrapper-checked.right-answer {
      border-color:#58a700;
      background-color: #d7ffb8;
      .anticon.anticon-check {
        opacity: 1;
        visibility: visible;
        color:#58a700;
      }
    }
    &.ant-checkbox-wrapper-checked.wrong-answer {
      background-color:  #ffdfe0;
      color:#ea2b2b;
    }
    .ant-checkbox-disabled + span {
      color: initial;
    }
    .ant-checkbox {
      top: 0;
    }
    @media screen and (max-width: 991px) {
      .anticon {
        display: none;
      }
    }
    @media screen and (min-width: 992px) {
      .anticon {
        position: absolute;
        top: calc(50% - 10px);
        right: 10px;
        font-size: 20px;
        opacity: 0;
        visibility: hidden;
      }
      &.ant-checkbox-wrapper-checked.right-answer {
        border-color: #58a700;
        background-color:#d7ffb8;
        .anticon.anticon-check {
          opacity: 1;
          visibility: visible;
          color: #58a700;
        }
      }
      &.ant-checkbox-wrapper-checked.wrong-answer {
        border-color: #ea2b2b;
        background-color: #ffdfe0;
        .anticon.anticon-close {
          opacity: 1;
          visibility: visible;
          color:#ea2b2b;
          font-size:25px;
        }
      }
      &.ant-checkbox-wrapper-checked {
        .ant-checkbox-disabled + span {
          color: initial;
        }
      }
    }
  }
`;

export default { Radio, Checkbox };
