const getListAllQuiz = (arrayQuizzes: any) => {
  const arr: any = [];
  arrayQuizzes?.forEach((item1: any) => {
    if (item1.answers) {
      arr.push(item1);
    }
    if (item1.quizzes) {
      item1.quizzes?.forEach((item2: any) => {
        if (item2.answers) {
          arr.push(item2);
        }
        if (item2.quizzes) {
          item2.quizzes?.forEach((item3: any) => {
            if (item3.answers) {
              arr.push(item3);
            }
            if (item3.quizzes) {
            }
          });
        }
      });
    }
  });
  return arr;
};
export default getListAllQuiz;
