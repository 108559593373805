import { Button, Form, Input, message } from 'antd';
import Main from 'components/commons/Main';
import MainTitle from 'components/commons/MainTitle';
import { ReactElement } from 'react';
import {
  ChangePasswordProps,
  useChangePasswordMutation,
  useLogoutMutation,
} from 'services/auth.service';
import styled from 'styled-components';

const FormAction = styled(Form.Item)`
  &&& a:last-child {
    margin-left: 15px;
  }
`;

const ChangePassword = (): ReactElement => {
  const [changePassword] = useChangePasswordMutation();
  const [logout] = useLogoutMutation();

  const onFinish = (values: ChangePasswordProps) => {
    changePassword(values)
      .unwrap()
      .then(() => {
        message.success('Thay đổi mật khẩu thành công. Vui lòng đăng nhập lại với mật khẩu mới.');
        setTimeout(() => {
          logout();
        }, 700);
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  return (
    <Main>
      <Form layout="vertical" onFinish={onFinish} style={{ maxWidth: '400px' }}>
        <MainTitle>Thay đổi mật khẩu</MainTitle>

        <Form.Item
          name="password"
          label="Mật khẩu cũ"
          rules={[
            {
              required: true,
              message: 'Vui lòng cung cấp mật khẩu trước đó',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="new_password"
          label="Mật khẩu mới (4 đến 32 ký tự)"
          rules={[{ required: true, message: 'Mật khẩu không được để trống' }]}
        >
          <Input.Password autoComplete="off" />
        </Form.Item>

        <Form.Item
          name="confirm_password"
          label="Xác nhận lại mật khẩu mới"
          rules={[
            {
              required: true,
              message: 'Mật khẩu không được để trống',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('new_password') === value) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(new Error('Mật khẩu không khớp'));
                }
              },
            }),
          ]}
        >
          <Input.Password autoComplete="off" />
        </Form.Item>

        <FormAction>
          <Button type="primary" htmlType="submit">
            Cập nhật mật khẩu
          </Button>
        </FormAction>
      </Form>
    </Main>
  );
};

export default ChangePassword;
