import { ReactElement } from 'react';
import checkUserRole from 'utils/checkUserRole';
import { highestRoles, ROLE_STUDENT, ROLE_TRAINER } from 'libraries/utils/globalVariable';
import NotAuthorized from 'components/commons/NotAuthorized';
import GetClass from './GetClass';

const HomePage = (): ReactElement => {
  const checkRole = checkUserRole([...highestRoles, ROLE_STUDENT, ROLE_TRAINER]);

  if (!checkRole) {
    return <NotAuthorized />;
  }

  return <GetClass />;
};

export default HomePage;
