import { ReactElement } from 'react';
import { Avatar } from 'antd';
import { StarFilled } from '@ant-design/icons';
import styled from 'styled-components';

import UserDefaultAvatar from 'assets/images/avatar.png';
import CrownImg from 'assets/images/crown.png';
import { StudentRank } from 'models/class.model';
interface PropsType {
  student: StudentRank;
  isTop?: boolean;
  rank?: number;
}

const Wrapper = styled.div`
  #crown-img {
    position: absolute;
    top: -28px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
  }
  .student-rank {
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const AvatarContainer = styled.div`
  width: 95px;
  height: 95px;
  margin: 0 auto;
  position: relative;
  .ant-avatar-image {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const StudentName = styled.p`
  font-family: Mulish;
  margin-top: 16px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

const StudentRankContainer = styled.div`
  .anticon-star {
    color: #ffc107;
    margin-right: 8px;
  }
  .student-score {
    font-weight: 600;
  }
`;

const TopRankInfo = ({ student }: PropsType): ReactElement => {
  const { rank, full_name, average, avatar } = student;
  const size = rank === 1 ? 95 : 75;
  const src = avatar !== '' ? avatar : UserDefaultAvatar;
  return (
    <Wrapper>
      {rank === 1 ? (
        <img id="crown-img" src={CrownImg} />
      ) : (
        <span className="student-rank">{rank}</span>
      )}
      <AvatarContainer>
        <Avatar size={size} src={src} alt={full_name} />
      </AvatarContainer>
      <StudentName>{full_name}</StudentName>
      <StudentRankContainer>
        <StarFilled />
        <span className="student-score">{average.toFixed(1)}</span>
      </StudentRankContainer>
    </Wrapper>
  );
};

export default TopRankInfo;
