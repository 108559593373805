import { forwardRef, ReactElement, Ref } from 'react';
import { useSelector } from 'react-redux';
import { selectLecture } from 'store/onlinecourse.slice';
import AddNewNote from './AddNewNote';
import NoteItem from './NoteItem';

const UserNoteContent = forwardRef((_, ref:Ref<HTMLDivElement>): ReactElement => {
  const { notes } = useSelector(selectLecture);

  return (
    <div ref={ref}>
      <AddNewNote />
      {notes.length > 0 ? (
        notes.map((note, index) => <NoteItem key={index} {...note} />)
      ) : (
        <div style={{ textAlign: 'center' }}>Chưa có ghi chú nào.</div>
      )}
    </div>
  );
});

UserNoteContent.displayName="UserNoteContent";

export default UserNoteContent;
