import { ReactElement } from 'react';
import { Result } from 'antd';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';

interface PropsType {
  title: string;
  subTitle?: string;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotAuthorized = ({ title, subTitle }: PropsType): ReactElement => {
  return (
    // <Redirect to={"https://techmaster.vn"} />
    <Container>
      <Result status="403" title={title} subTitle={subTitle} />
    </Container>
  );
};

NotAuthorized.defaultProps = {
  title: 'Bạn không có quyền thực hiện tác vụ này',
  subTitle: '',
};

export default NotAuthorized;
