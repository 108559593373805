import { Col, Row } from 'antd';
import { checkBeforeDate } from 'libraries/utils/timeDistanceToNow';
import { ReactElement, SyntheticEvent } from 'react';
import styled from 'styled-components';
import ParentTitle from './ParentTitle';

interface ParentCollapseHeaderProps {
  title: string;
  moduleId: string;
}

const ParentCollapseHeader = ({ title, moduleId }: ParentCollapseHeaderProps): ReactElement => {
  return (
    <>
      <Row justify="space-between" align="middle" gutter={[8, 0]}>
        <Col xs={24}>
          <ParentTitle title={title} />
        </Col>
      </Row>
    </>
  );
};

export default ParentCollapseHeader;
