import { Divider } from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { selectQuizSubmit, selectTotalFalse, selectTotalTrue } from 'store/onlinecourse.slice';
import { selectPractice, selectUpdateLocalStorage } from 'store/practice.slice';
import styled from 'styled-components';
import getListAllQuiz from 'utils/getListAllQuiz';
import { getNumberQuizNoHasAnswer1 } from 'utils/getNumberQuizNoHasAnswer';
import getQuizTrue from 'utils/getQuizTrue';
import getQuizTrueNumber from 'utils/getQuizTrueNumber';

const QuizzStyle = styled.div`
  background-color: #eeeeee;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #58a700;
  font-weight: 700;
  font-size: 18px;
  margin: 12px;
  cursor: pointer;
`;
const QuizzListStyle = styled.div`
  border-right: 1px solid #ccc;
  height: 100%;
  margin-right: 12px;
`;
const SpanMarginRight = styled.span`
  margin-right: 14px;
`;
const Quizzcontainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  // justify-content:center;
  margin: 0 24px;
  // flex-direction: column;
`;
const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const FlexContainerItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1253px) {
    margin-top: 6px;
  }
`;
const CircleStyle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 8px;
`;
interface props {
  quizzes: any;
  is_submit: any;
}

const Circle = ({ color }: any): ReactElement => {
  return <CircleStyle style={{ background: `${color}` }}></CircleStyle>;
};

const QuizzListLecture = ({ quizzes, is_submit }: props): ReactElement => {
  const { classroom: cls } = useParams<{ classroom: string }>();
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const question = query.get('question');
  const [arrIdQuizShow, setArrIdQuizShow] = useState<any>(
    JSON.parse(localStorage.getItem('ArrIdQuizShow') || '[]'),
  );
  const QuizSubmit = useSelector(selectQuizSubmit);
  const totalTrue = useSelector(selectTotalTrue);
  const totalFalse = useSelector(selectTotalFalse);
  const updateLocalStorage = useSelector(selectUpdateLocalStorage);
  useEffect(() => {
    setArrIdQuizShow(JSON.parse(localStorage.getItem('ArrIdQuizShow') || '[]'));
  }, [updateLocalStorage]);

  const changeQuestion = (newQuestion: number) => {
    const currentLocation = history.location;
    const currentSearch = currentLocation.search;
    const searchParams = new URLSearchParams(currentSearch);
    searchParams.set('question', newQuestion.toString());
    history.push({
      pathname: currentLocation.pathname,
      search: searchParams.toString(),
    });
  };
  const handleClick = (quizzNUmber: number) => {
    changeQuestion(quizzNUmber);
  };
  return (
    <QuizzListStyle>
      <div
        style={{
          paddingTop: '34.5px',
          margin: ' 0 24px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2 style={{ margin: 0 }}>{quizzes.length} câu hỏi</h2>
      </div>

      <Divider />
      <div style={{ margin: '0 24px' }}>
        {QuizSubmit == false && (
          <FlexContainer>
            <FlexContainer>
              <Circle color={'#FEF4C2'}></Circle>
              <SpanMarginRight>Đã trả lời</SpanMarginRight>
            </FlexContainer>
            <FlexContainer>
              <Circle color={'#F6F9FC'}></Circle>
              <SpanMarginRight>Chưa trả lời</SpanMarginRight>
            </FlexContainer>
          </FlexContainer>
        )}
        {QuizSubmit && (
          <FlexContainer>
            <FlexContainer>
              <Circle color={'#d5f2ea'}></Circle>
              <SpanMarginRight>{totalTrue} Câu đúng</SpanMarginRight>
            </FlexContainer>
            <FlexContainer>
              <Circle color={'#FF9696'}></Circle>
              <SpanMarginRight>{totalFalse} Câu sai</SpanMarginRight>
            </FlexContainer>
            {/* <FlexContainerItem>
              <Circle color={'#F6F9FC'}></Circle>
              <SpanMarginRight>
                {quizzes.length - totalTrue - totalFalse} Chưa trả lời
              </SpanMarginRight>
            </FlexContainerItem> */}
          </FlexContainer>
        )}
      </div>
      <Quizzcontainer>
        <p style={{ margin: '12px 0 0 0' }}>Bấm vào ô dưới để xem lại câu hỏi</p>
        <Divider />
        {quizzes &&
          QuizSubmit &&
          quizzes?.map((item: any, i: number) => (
            <QuizzStyle
              key={i}
              onClick={() => handleClick(i + 1)}
              style={{
                border: question === (i + 1).toString() ? '2px solid #222' : '0px  solid #222',
                backgroundColor:
                  item.is_true == undefined
                    ? 'rgb(246, 249, 252)'
                    : item.is_true == true
                    ? '#D5F2EA'
                    : '#FF9696',
                color:
                  item.is_true == undefined
                    ? 'rgb(85, 85, 85)'
                    : item.is_true == true
                    ? '#00C48C'
                    : '#fff',
              }}
            >
              <span>{i + 1}</span>
            </QuizzStyle>
          ))}
        {quizzes &&
          !QuizSubmit &&
          quizzes?.map((item: any, i: number) => (
            <QuizzStyle
              key={i}
              onClick={() => handleClick(i + 1)}
              style={{
                border: question === (i + 1).toString() ? '2px solid #222' : '0px  solid #222',
                backgroundColor:
                  item.is_true == undefined ? 'rgb(246, 249, 252)' : 'rgb(254, 244, 194)',

                color: 'rgb(85, 85, 85)',
              }}
            >
              <span>{i + 1}</span>
            </QuizzStyle>
          ))}
      </Quizzcontainer>
    </QuizzListStyle>
  );
};

export default QuizzListLecture;
