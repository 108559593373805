import { createSlice } from '@reduxjs/toolkit';
import { ProfileProps } from 'models/user.model';
import { RootState } from 'store';
import { authApi } from 'services/auth.service';
import UserDefaultAvatar from 'assets/images/avatar.png';

interface UserState {
  user: ProfileProps;
  isLoginSuccess: boolean;
  loginMessage: string;
}

const initialState: UserState = {
  user: {} as ProfileProps,
  isLoginSuccess: false,
  loginMessage: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserAvatar: (state, { payload }) => {
      state.user.avatar = payload;
    },
    setUserQrCode: (state, { payload }) => {
      state.user.qr_code = payload;
    },
  },
  extraReducers: (builder) => {
    // Update state user when login
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      state.user = {
        ...payload,
      };
    });

    // Update state user when logout
    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
      state.user = {} as ProfileProps;
    });

    builder.addMatcher(authApi.endpoints.updateInformation.matchFulfilled, (state, { payload }) => {
      state.user = {
        ...payload,
      };
    });
  },
});

export const { setUser, setUserAvatar } = authSlice.actions;

export const selectUser = (state: RootState): ProfileProps => state.auth.user;
export const selectUserAvatar = (state: RootState): string =>
  state.auth.user.avatar || UserDefaultAvatar;
export const selectUserQrCode = (state: RootState): string => state.auth.user.qr_code;

export default authSlice.reducer;
