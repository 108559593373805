import { ReactElement } from 'react';

type DurationProp = {
  seconds: number;
  className?: string;
};

const Duration = ({ seconds, className }: DurationProp): ReactElement => {
  return (
    <time dateTime={`P${Math.round(seconds)}S`} className={className}>
      {format(seconds)}
    </time>
  );
};

export default Duration;

function format(seconds: number) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = ('0' + date.getUTCSeconds()).slice(-2);
  if (hh) {
    return `${hh}:${('0' + mm).slice(-2)}:${ss}`;
  }
  return `${mm}:${ss}`;
}
