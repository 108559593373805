import { ReactElement, useEffect, useState } from 'react';
import { Button, Col, message, Row, Form, Input, Select } from 'antd';
import Main from 'components/commons/Main';
import MainTitle from 'components/commons/MainTitle';
import { useUpdateInformationMutation } from 'services/auth.service';
import { ProfileProps } from 'models/user.model';
import styled from 'styled-components';
import ChangeAvatar from './ChangeAvatar';
import { useUploadFileMutation } from 'libraries/services/media.service';
import { CloseCircleFilled } from '@ant-design/icons';
import UploadFile from 'libraries/components/UploadFile';
import UploadFileCV from 'libraries/components/UploadFileCV';
import ChangeQrCode from './ChangeQrCode';

const { Option } = Select;
const FormButton = styled(Form.Item)`
  &&& .ant-col-offset-5 {
    @media screen and (max-width: 575px) {
      margin-left: 0 !important;
    }
  }
`;

export const DownloadLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 15px;
  .anticon-close-circle {
    cursor: pointer;
  }
`;

export const DownloadLink = styled.div`
  display: flex;
  width: 100%;
  max-width: 600px;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

interface Bank {
  id: number;
  name: string;
  code: string;
  bin: string;
  shortName: string;
  logo: string;
  transferSupported: number;
  lookupSupported: number;
  short_name: string;
  support: number;
  isTransfer: number;
  swift_code: string;
}

function getShortNameByBin(banks: Bank[], bin: string): string | undefined {
  const bank = banks.find((b) => b.bin === bin);
  return bank ? bank.shortName : undefined;
}

const ProfileContainer = (data: ProfileProps): ReactElement => {
  const [updateProfile] = useUpdateInformationMutation();
  const [linkCV, setLinkCV] = useState(data.link_cv);
  const [fileList, setFileList] = useState<any>([]);
  const [uploading, setUploading] = useState(false);
  const [uploadFile] = useUploadFileMutation();
  const [checkCv, setCheckCV] = useState(false);
  const [banks, setBanks] = useState<Bank[]>([]);

  const [tmpContent, setTmpContent] = useState({
    phone: data.phone,
    bank_account: data.bank_account,
    bank_name: data.bank_name,
    description: data.description,
    full_name: data.full_name,
    avatar: data.avatar,
    email: data.email,
    link_cv: data.link_cv,
    bank_code: data.bank_code,
  });
  useEffect(() => {
    if (data.link_cv != '') {
      setCheckCV(true);
    }
  }, []);
  useEffect(() => {
    fetch('https://api.vietqr.io/v2/banks')
      .then((res) => res.json())
      .then((data) => setBanks(data.data));
  }, []);

  const options = banks?.map((bank: any) => ({
    value: bank.bin,
    label: ` ${bank.shortName} (${bank.name})`,
    shortName: bank.shortName,
  }));
  const [fields, setFields] = useState([
    {
      name: ['avatar'],
      value: data.avatar,
    },
    {
      name: ['username'],
      value: data.full_name,
    },
    {
      name: ['numberValue'],
      value: data.phone,
    },
    {
      name: ['email'],
      value: data.email,
    },
    {
      name: ['bank-name'],
      value: data.bank_name,
    },
    {
      name: ['account-number'],
      value: data.bank_account,
    },
    {
      name: ['description'],
      value: data.description,
    },
    {
      name: ['link_cv'],
      value: data.link_cv,
    },
  ]);
  const [isChange, setIsChange] = useState<boolean>(false);

  const handleChangeTmpValue = (field: string, value: string) => {
    setTmpContent({
      ...tmpContent,
      [field]: value,
    });
    setIsChange(true);
  };

  const handleConfirmChange = (): void => {
    const data = { ...tmpContent, link_cv: linkCV };
    updateProfile(data)
      .unwrap()
      .then(() => {
        message.success('Thay đổi thông tin thành công');
      })
      .catch((error: any) => {
        message.error(error.data);
      });
    setIsChange(false);
  };
  const handleUploadFile = (value: FormData) => {
    uploadFile({ data: value })
      .unwrap()
      .then((res: any) => {
        setLinkCV(res.url);
        setCheckCV(true);
        setIsChange(true);
        const newArr = [
          // ...files,
          {
            name: res.filename,
            id: res.id,
            link: res.url,
          },
        ];
        // onChangeLectureField('files', newArr);
        message.success('Upload file thành công!');
        setFileList([]);
        setUploading(false);
        // hasEdit(true);
      })
      .catch((error: any) => {
        message.error(error.data);
        setUploading(false);
        setFileList([]);
      });
  };
  const handleRemoveLink = () => {
    setLinkCV('');
    setCheckCV(false);
  };
  const handleUpdateAvatar = (value: string) => {
    updateProfile({
      ...tmpContent,
      avatar: value,
    })
      .unwrap()
      .then(() => {
        message.success('Cập nhật ảnh đại diện thành công');
      })
      .catch((error: any) => {
        message.error(error.data);
      });
  };

  const handleCancelChange = (): void => {
    setFields([
      {
        name: ['avatar'],
        value: data.avatar,
      },
      {
        name: ['username'],
        value: data.full_name,
      },
      {
        name: ['numberValue'],
        value: data.phone,
      },
      {
        name: ['email'],
        value: data.email,
      },
      {
        name: ['bank-name'],
        value: data.bank_name,
      },
      {
        name: ['account-number'],
        value: data.bank_account,
      },
      {
        name: ['description'],
        value: data.description,
      },
      {
        name: ['link_cv'],
        value: data.link_cv,
      },
    ]);
  };
  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 10 },
  };

  return (
    <Main>
      <MainTitle>Hồ sơ cá nhân</MainTitle>

      <Row>
        <Col span={24}>
          <Form
            {...layout}
            name="nest-messages"
            onFinish={handleConfirmChange}
            fields={fields}
            onFieldsChange={(_, allFields: any) => {
              setFields(allFields);
            }}
          >
            <Form.Item name="avatar" label="Avatar">
              <ChangeAvatar
                uploading={uploading}
                setUploading={setUploading}
                updateUserAvatar={handleUpdateAvatar}
              />
            </Form.Item>
            <Form.Item
              name="username"
              label="Họ tên"
              rules={[{ required: true, message: 'Họ tên không được bỏ trống!' }]}
            >
              <Input onChange={(e) => handleChangeTmpValue('full_name', e.target.value)} />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  type: 'email',
                  message: 'Địa chỉ email không hợp lệ!',
                },
                {
                  required: true,
                  message: 'Email không được bỏ trống!',
                },
              ]}
            >
              <Input
                defaultValue={tmpContent.email}
                onChange={(e) => handleChangeTmpValue('email', e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Số điện thoại"
              name="numberValue"
              rules={[
                {
                  pattern: /^(?:\d*)$/,
                  message: 'Số điện thoại phải là dạng số',
                },
                {
                  pattern: /^[\d]{0,10}$/,
                  message: 'số điện thoại không được vượt quá 10 ký tự',
                },
                {
                  required: true,
                  message: 'Số điện thoại không được bỏ trống!',
                },
              ]}
            >
              <Input
                defaultValue={tmpContent.phone}
                onChange={(e) => handleChangeTmpValue('phone', e.target.value)}
              />
            </Form.Item>
            <Form.Item name="bank-name" label="Ngân hàng">
              {/* <Input
                defaultValue={tmpContent.bank_name}
                onChange={(e) => handleChangeTmpValue('bank_name', e.target.value)}
              /> */}
              {banks.length != 0 && (
                <Select
                  showSearch
                  placeholder="Chọn ngân hàng"
                  optionFilterProp="children"
                  onChange={(value: any) => {
                    setTmpContent({
                      ...tmpContent,
                      bank_code: value,
                      bank_name: getShortNameByBin(banks, value) || '',
                    });
                    setIsChange(true);
                  }}
                  // onChange={onChange}
                  filterOption={(input, option) =>
                    (option?.shortName ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={options}
                />
              )}
            </Form.Item>

            <Form.Item
              label="Số tài khoản"
              name="account-number"
              rules={[
                {
                  pattern: /^(?:\d*)$/,
                  message: 'Số tài khoản phải là dạng số',
                },
              ]}
            >
              <Input
                defaultValue={tmpContent.bank_account}
                onChange={(e) => handleChangeTmpValue('bank_account', e.target.value)}
              />
            </Form.Item>
            <Form.Item name="description" label="Giới thiệu">
              <Input.TextArea
                value={tmpContent.description}
                onChange={(e) => handleChangeTmpValue('description', e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Cập nhật CV" name="link_cv">
              {checkCv && (
                <DownloadLinkContainer>
                  <DownloadLink>
                    <a href={linkCV} target="_blank" rel="noreferrer">
                      Xem CV
                    </a>
                    <CloseCircleFilled onClick={handleRemoveLink} />
                  </DownloadLink>
                </DownloadLinkContainer>
              )}

              <UploadFileCV
                fileList={fileList}
                // eslint-disable-next-line
                setFileList={(value: any) => setFileList(value)}
                handleUploadFile={handleUploadFile}
                uploading={uploading}
                setUploading={(value: boolean) => setUploading(value)}
              />
              <p style={{ marginTop: '8px' }}>Định dạng PDF, kích thước &lt; 5 MB</p>
            </Form.Item>

            <FormButton wrapperCol={{ ...layout.wrapperCol, offset: 5 }}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={isChange ? false : true}
                style={{ marginRight: 10 }}
              >
                Cập nhật
              </Button>
              <Button
                type="primary"
                danger
                onClick={handleCancelChange}
                disabled={isChange ? false : true}
              >
                Huỷ bỏ
              </Button>
            </FormButton>
          </Form>
        </Col>
      </Row>
    </Main>
  );
};

export default ProfileContainer;
