import { learning } from './routeNames';
import { IContentRoute } from 'models/route.model';
import LearnPage from 'pages/learn/LearnPage';
import WelcomePage from 'pages/learn/WelcomePage';

export const routes:  IContentRoute[] = [
  {
    path: learning.learn,
    meta: {
      title: 'Khoá học online',
      content: LearnPage,
    },
  },
  {
    path: learning.welcomePage,
    meta: {
      title: 'Khoá học online',
      content: WelcomePage,
    },
  },
  
];
