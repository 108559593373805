import { Button, Form, InputNumber, message, Select, TimePicker } from 'antd';
import Text from 'antd/lib/typography/Text';

import Modal from 'libraries/components/commons/Modal';
import moment from 'moment';
import { ReactElement, useLayoutEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useCreateExamMutation } from 'services/class.service';

import styled from 'styled-components';
const { Option } = Select;

interface Props {
  subject_id: string;
  isModalVisible: boolean;
  classSubject: any;
  total_quiz: any;
  handleCancel: any;
}

const TitleText = styled.div`
  cursor: pointer;

  &:hover {
    color: #40a9ff;
  }
`;
const ButtonCenter = styled.div`
  display: flex;
  justify-content: center;
`;

const convertToSeconds = (time: string) => {
  const [hour, minute, second] = time.split(':');
  return +hour * 3600 + +minute * 60 + +second;
};

const ModalCustomExam = ({
  subject_id,
  classSubject,
  total_quiz,
  handleCancel,
  isModalVisible,
}: Props): ReactElement => {
  const { classroom: cls } = useParams<{ classroom: string }>();
  const history = useHistory();
  const [form] = Form.useForm();
  const [createExam] = useCreateExamMutation();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<string>('time');

  const onCloseModal = () => {
    form.resetFields(['type', 'duration', 'subjects', 'count_quiz']);
    setType('time');
    handleCancel();
  };
  // const handleOpenChange = (open) => {
  //   if (!open ) {
  //     console.log(open)

  // };
  const onFinish = (values: any) => {
    let data;

    if (type == 'time') {
      data = { ...values, duration: convertToSeconds(values.duration.format('HH:mm:ss')) };
    } else {
      data = values;
    }
    if (values.is_advance == 'false') {
      data = { ...data, is_advance: false };
    } else {
      data = { ...data, is_advance: true };
    }
    if (subject_id == '') {
      data = { ...data, subjects: [] };
    }
    createExam({
      class_id: cls,
      data,
    })
      .unwrap()
      .then((data: any) => {
        if (!data.id) {
          message.error(data);
        } else {
          setTimeout(() => {
            history.push(generatePath(`practice/${data.id}?question=1`));
          }, 1);
        }
      })
      .catch((error: any) => {
        message.error(error.data);
      });
  };
  useLayoutEffect(() => {
    form.setFieldsValue({
      subjects: [subject_id],
      count_quiz: total_quiz <= 20 ? total_quiz : 20,
    });
  }, [subject_id]);

  return (
    <>
      <Modal
        title="Tùy chọn"
        open={isModalVisible}
        onCancel={onCloseModal}
        footer={null}
        width={400}
        centered
      >
        <Form
          form={form}
          //   name="lecture-search"
          //   wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          initialValues={{
            type: 'time',
            is_advance: 'false',
            duration: moment('00:20:00', 'HH:mm:ss'),
            subjects: [subject_id],
            count_quiz: total_quiz <= 20 ? total_quiz : 20,
          }}
          style={{ justifyContent: 'flex-end' }}
        >
          <Form.Item
            name="is_advance"
            label={<Text style={{ fontWeight: '400', fontSize: '16px' }}>Loại đề</Text>}
          >
            <Select style={{ width: '100%' }}>
              <Option value="false"> Đề buổi sáng</Option>
              <Option value="true">Đề buổi chiều</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="type"
            label={<Text style={{ fontWeight: '400', fontSize: '16px' }}>Hình thức</Text>}
          >
            <Select style={{ width: '100%' }} onChange={(value: string) => setType(value)}>
              <Option value="time">Tính thời gian</Option>
              <Option value="no_time">Không tính thời gian</Option>
            </Select>
          </Form.Item>

          {type == 'time' && (
            <Form.Item
              name="duration"
              label={<Text style={{ fontWeight: '400', fontSize: '16px' }}>Thời gian</Text>}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập thời gian',
                },
              ]}
            >
              <TimePicker style={{ width: '100%' }} />
            </Form.Item>
          )}
          {subject_id != '' && (
            <Form.Item
              label={<Text style={{ fontWeight: '400', fontSize: '16px' }}>Danh sách chủ đề</Text>}
              name="subjects"
              rules={[
                {
                  required: true,
                  message: 'Xin vui lòng chọn danh sách chủ đề!',
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                style={{ width: '100%' }}
                placeholder="Danh sách chủ đề"
                mode="multiple"
              >
                {classSubject?.map((item: any, index: number) => {
                  return (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            name="count_quiz"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập số lượng câu hỏi !',
              },
            ]}
            label={<Text style={{ fontWeight: '400', fontSize: '16px' }}>Số lượng câu hỏi</Text>}
          >
            <InputNumber
              min={1}
              step={1}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <ButtonCenter>
            <Button type="primary" htmlType="submit">
              Bắt đầu
            </Button>
          </ButtonCenter>
        </Form>
      </Modal>
    </>
  );
};

export default ModalCustomExam;
