import { Result } from 'antd';
const updateQuizAnswers = (answers: any, real_answers: any) => {
  const result = answers.map((item: any) => {
    if (real_answers.includes(item.id)) {
      return { ...item, is_true: true };
    }
    return item;
  });

  return result;
};
export default updateQuizAnswers;
