import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

const NavLink = styled(Link)`
  &&& {
    font-family: Mulish;
    letter-spacing: 0.2px;
    color: #343a40;
  }
`;

export default NavLink;
