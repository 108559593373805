import styled from 'styled-components';

const Sidebar = styled.aside`
  font-family: 'Mulish';
  background-color: #fff;
  overflow: auto;
  transition: transform 0.25s;
  .course-title {
    padding: 8px;
    position: relative;
    background-color: #fff;
    // border-bottom: 1px solid #aaa;
    // border-left: 1px solid #aaa;
    z-index: 999;
    .course-status {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: rgba(35, 201, 172, 0.38);
    }
  }
  @media screen and (min-width: 992px) {
    position: fixed;
    right: 0;
    top: 66px;
    bottom: 0;
    border-left: 1px solid #aaa;
  }
`;

export default Sidebar;
