const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};
export const scrollToTopNoSmooth = () => {
  window.scrollTo({
    top: 0,
  });
};

export default scrollToTop;
