import { ReactElement, SyntheticEvent, useEffect } from 'react';

import AttendStatus from 'components/commons/LearningState';
import Button from 'libraries/components/commons/Button';

interface LessonAttendanceProps {
  handleOpenRateModal: (e: SyntheticEvent, lessonId: string) => void;
  lessonId: string;
  isVote: boolean;
  isAttendance: boolean;
  complete_date: string;
}

const LessonStatus = ({
  handleOpenRateModal,
  lessonId,
  isVote,
  isAttendance,
  complete_date,
}: LessonAttendanceProps): ReactElement => {
  const openRateModal = (e: SyntheticEvent) => {
    handleOpenRateModal(e, lessonId);
  };
  return (
    <>
      {isAttendance ? (
        <AttendStatus.Success>Đi học</AttendStatus.Success>
      ) : complete_date !== '0001-01-01T00:00:00Z' ? (
        <AttendStatus.Danger>Nghỉ học</AttendStatus.Danger>
      ) : (
        ''
      )}

      {isAttendance && (
        <Button.History onClick={openRateModal} disabled={!isVote}>
          Đánh giá
        </Button.History>
      )}
    </>
  );
};

export default LessonStatus;
