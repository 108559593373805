import { CheckOutlined, CloseOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Checkbox, Col, Form, Modal, Radio, Row, Space } from 'antd';
import Button from 'libraries/components/commons/Button';
import ShowMarkdown from 'libraries/components/markdown/ShowMarkdown';
import { Quiz } from 'models/class.model';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAnswerQuizMutation } from 'services/onlinecourse.service';

import Image101 from 'assets/images/ImageQuizSubmit/1010/053099400_1606102528-AP20327734601646.jpg';
import Image102 from 'assets/images/ImageQuizSubmit/1010/0_rashford.webp';
import Image103 from 'assets/images/ImageQuizSubmit/1010/5f5e3cbe-1224-4a46-b8e7-612f5de03c8c.jpg';
import Image104 from 'assets/images/ImageQuizSubmit/1010/bruyne-0810223838.jpg';
import Image105 from 'assets/images/ImageQuizSubmit/1010/c96ccd8b5e69dcfe9a714c6c8ba43a30.jpg';
import Image106 from 'assets/images/ImageQuizSubmit/1010/khongthetinduocvoithanhtichcuakylianmbappe-1649050615910.webp';
import Image107 from 'assets/images/ImageQuizSubmit/1010/z3936803783337_80d339bd554ac613cf669f4c1b0f9756.jpg';

import Image91 from 'assets/images/ImageQuizSubmit/0910/1_Newcastle-United-v-Manchester-City-Premier-League.jpg';
import Image92 from 'assets/images/ImageQuizSubmit/0910/26beacbf534e9eec5ae96b59685fe8dc7963b8a1w-605x450.jpg';
import Image93 from 'assets/images/ImageQuizSubmit/0910/antony_169.jpeg';
import Image94 from 'assets/images/ImageQuizSubmit/0910/ava-1678060155733.jpg';
import Image95 from 'assets/images/ImageQuizSubmit/0910/b25lY21zOmM0YjU2ZGIyLTVmZTYtNGQ4Mi05MjBlLWYxYTA5OTQ1YjczZjoyYmFjOTIzMC1hMjA4LTRhMGEtOWQ5MC03MThiMGU4NmVmZDQ=.jpg';
import Image96 from 'assets/images/ImageQuizSubmit/0910/download-1360-1588742504.jpg';
import Image97 from 'assets/images/ImageQuizSubmit/0910/raphinha-9177.jpeg';
import Image98 from 'assets/images/ImageQuizSubmit/0910/vinicius.jpg';

import Image81 from 'assets/images/ImageQuizSubmit/0810/1576719220-737-quang-hai-bat-ngo-viet-hai-3-1576718825-width668height532.jpg';
import Image82 from 'assets/images/ImageQuizSubmit/0810/610x-76.jpeg';
import Image83 from 'assets/images/ImageQuizSubmit/0810/balotellieuro2012.jpg';
import Image84 from 'assets/images/ImageQuizSubmit/0810/gettyimages-1141503005-1024x576.webp';
import Image85 from 'assets/images/ImageQuizSubmit/0810/ggggggtttyimage009(3).jpg';
import Image86 from 'assets/images/ImageQuizSubmit/0810/u23vn-indo126-5-22-1651847210591.webp';

import Image71 from 'assets/images/ImageQuizSubmit/0710/3153608-64627888-2560-1440.jpg';
import Image72 from 'assets/images/ImageQuizSubmit/0710/3767e9815738b435077f7694ed3b10a3.jpg';
import Image73 from 'assets/images/ImageQuizSubmit/0710/59390473-0-image-a-24_1655906201316.avif';
import Image74 from 'assets/images/ImageQuizSubmit/0710/Danny-Welbeck-008.webp';

const Image10_10 = [Image101, Image102, Image103, Image104, Image105, Image106, Image107];
const Image09_10 = [Image91, Image92, Image93, Image94, Image95, Image96, Image97, Image98];
const Image08_10 = [Image81, Image82, Image83, Image84, Image85, Image86];
const Image07_10 = [Image71, Image72, Image73, Image74];

import audioSuccess from 'assets/audio/Am-thanh-tra-loi-dung.mp3';
import audioFail from 'assets/audio/Am-thanh-tra-loi-sai.mp3';

import ImageCat from 'assets/images/cach-noi-con-meo-trong-tieng-han.jpg';
const getRandomImage = (imageList: any) => {
  return imageList[Math.floor(Math.random() * imageList.length)];
};

const scoreQuiz = (totalTrue: any, totalquiz: any) => {
  return Math.floor((totalTrue * 10) / totalquiz);
};
import {
  iQuiz,
  iQuizItem,
  resetTotalQuiz,
  selectLecture,
  selectQuizSubmit,
  selectTotalFalse,
  selectTotalTrue,
  setNotAnswer,
  setQuizSubmit,
  updateQuizList,
} from 'store/onlinecourse.slice';
import QuizContainer from './QuizContainer';
import QuizGroup from './QuizGroup';
import QuizzListLecture from './QuizzListLecture';
import styled from 'styled-components';
import { useParams } from 'react-router';
const ButtonDirectional = styled.div`
  @media (max-width: 576px) {
    gap: 12px;
  }
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;
interface QuizPropsType {
  quizes: Quiz[];
  currentQuestion: number;
  defaultQuizesArr: iQuiz;
  currentQuiz: iQuiz;
  currentQuizItem: iQuizItem;
  isCompleteLocal: boolean | undefined;
  scoreLocal: number | undefined;
  resetQuiz: any;
  updateAnwser: any;
}

const getQuizAnwser = (anwserList: any, id: any) => {
  const term = anwserList.filter((item: any) => id == item.id);
  if (term.length == 0) return [];
  return term[0].anwserChoonse;
};
const QuizItem = ({
  quizes,
  currentQuestion,
  currentQuizItem,
  currentQuiz,
  isCompleteLocal,
  scoreLocal,
  resetQuiz,
  updateAnwser,
}: QuizPropsType): ReactElement => {
  const { classId: classId } = useParams<{ classId: string }>();
  const QuizSubmit = useSelector(selectQuizSubmit);
  const [playQuizFail, setPlayQuizFail] = useState<any>(false);
  const [playQuizSuccess, setPlayQuizSuccess] = useState<any>(false);
  const [values, setValues] = useState<any>();
  const history = useHistory();
  const url = window.location.href;
  const urlObject = new URL(url);
  const question = urlObject.searchParams.get('question') || 1;
  const dispatch = useDispatch();
  const totalTrue = useSelector(selectTotalTrue);
  const totalFalse = useSelector(selectTotalFalse);
  const { id, content, is_single, answers, is_true } = quizes[+question - 1];
  const [is_submit, setIs_submit] = useState(false);
  const { id: lectureId } = useSelector(selectLecture);
  const [answerQuiz] = useAnswerQuizMutation();
  const { isCorrect, isAnswered, notAnswer, id: itemId } = currentQuizItem;
  const requiredActionProps = { id: lectureId, itemId: itemId };
  const currentLocation = history.location;
  const AnwserQuiz = JSON.parse(window.localStorage.getItem('AnwserQuiz') || ` []`);
  const goToNextQuestion = () => {
    if (+question < quizes.length) {
      history.push({
        pathname: currentLocation.pathname,
        search: `question=${+question + 1}`,
      });
    }
  };
  const goToPrevQuestion = () => {
    if (+question > 1) {
      history.push({
        pathname: currentLocation.pathname,
        search: `question=${+question - 1}`,
      });
    }
  };

  useEffect(() => {
    if (AnwserQuiz.length == 0) return;
    setValues(getQuizAnwser(AnwserQuiz, id));
  }, [id]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    resetQuiz();
    setValues([]);
    setIsModalOpen(false);
    setPlayQuizSuccess(false);
    setPlayQuizFail(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(resetTotalQuiz);
  };

  const submitButton = totalTrue + totalFalse != quizes.length && (
    <Form.Item style={{ textAlign: 'end' }}>
      <Button.Default
        type="primary"
        htmlType="submit"
        disabled={values?.length == 0 || is_true == false || is_true == true}
      >
        Trả lời
      </Button.Default>
    </Form.Item>
  );
  const onChangeCheckBox = (checkedValues: any) => {
    setValues(checkedValues);
  };
  const onChange = (e: any) => {
    setValues([e.target.value]);
  };
  const submitAnswer = () => {
    answerQuiz({classId, id, data: { answers: values } })
      .unwrap()
      .then((res: any) => {
        dispatch(updateQuizList({ id, is_true: res }));
        let newTotalTrue = totalTrue;
        let newTotalFalse = totalFalse;
        if (res == true) {
          newTotalTrue += 1;
        } else {
          newTotalFalse += 1;
        }
        if (newTotalTrue + newTotalFalse == quizes.length) {
          if (scoreQuiz(newTotalTrue, quizes.length) >= 7) {
            setPlayQuizSuccess(true);
          } else {
            setPlayQuizFail(true);
          }

          dispatch(setQuizSubmit());
          setTimeout(() => {
            showModal();
          }, 1000);
        }
      })
      .catch((err: any) => console.log(err));

    updateAnwser();
    if (AnwserQuiz.length == 0) {
      window.localStorage.setItem('AnwserQuiz', JSON.stringify([{ id, anwserChoonse: values }]));
    } else {
      window.localStorage.setItem(
        'AnwserQuiz',
        JSON.stringify([...AnwserQuiz, { id, anwserChoonse: values }]),
      );
    }
    goToNextQuestion();
  };
  return (
    <QuizContainer.Container>
      <Row>
        <Col xs={0} lg={9} xl={9}>
          <QuizzListLecture quizzes={quizes} is_submit={is_submit} />
        </Col>

        <Col xs={24} lg={15} xl={15}>
          <QuizContainer.Header>
            <QuizContainer.Title>
              {/* <span>Câu hỏi {question}</span> */}
              <QuizContainer.CountAnswer>
                {is_single ? '1 đáp án' : 'Nhiều đáp án'}
              </QuizContainer.CountAnswer>
              {/* <QuizContainer.Count>
                {currentQuestion + 1}/{quizes.length}
              </QuizContainer.Count> */}
            </QuizContainer.Title>
          </QuizContainer.Header>
          <QuizContainer.Content>
            {content.includes('`') ? (
              <ShowMarkdown markdown={content} />
            ) : (
              <ShowMarkdown
                markdown={content
                  .replace(/</g, '&lt;')
                  .replace(/>/g, '&gt;')
                  .replace(/#/g, '&#35;')}
              />
            )}
          </QuizContainer.Content>

          {is_single ? (
            <Form
              onValuesChange={(values: { answerRadioChosen: number }) => {
                if (!values.answerRadioChosen)
                  dispatch(setNotAnswer({ ...requiredActionProps, notAnswer: true }));
                else dispatch(setNotAnswer({ ...requiredActionProps, notAnswer: false }));
              }}
              onFinish={(values: { answerRadioChosen: number }) => {
                // const answersData: number[] = [];
                // answersData.push(values.answerRadioChosen);
                // submitAnswer(answersData);
                submitAnswer();
              }}
            >
              <Form.Item name="answerRadioChosen">
                <QuizGroup.Radio>
                  <Radio.Group
                    onChange={onChange}
                    value={values ? values[0] : false}
                    style={{ display: 'block' }}
                  >
                    <Space direction="vertical">
                      {answers?.map((item) => (
                        <Radio
                          value={item.id}
                          key={item.id}
                          className={
                            !QuizSubmit
                              ? ''
                              : isAnswered && isCorrect
                              ? 'right-answer'
                              : 'wrong-answer'
                          }
                          disabled={is_true == true || is_true == false}
                        >
                          {/* <ShowMarkdown markdown={item.answer} /> */}
                          {item.answer.includes('`') ? (
                            <ShowMarkdown markdown={item.answer} />
                          ) : (
                            <ShowMarkdown
                              markdown={item.answer
                                .replace(/</g, '&lt;')
                                .replace(/>/g, '&gt;')
                                .replace(/#/g, '&#35;')}
                            />
                          )}
                          <CheckOutlined />
                          <CloseOutlined />
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </QuizGroup.Radio>
              </Form.Item>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '12px',
                  flexWrap: 'wrap',
                }}
              >
                {submitButton}
                {totalTrue + totalFalse == quizes.length && (
                  <div style={{ marginBottom: 25 }}>
                    <Button.Default
                      onClick={() => (
                        resetQuiz(),
                        setValues([]),
                        setPlayQuizSuccess(false),
                        setPlayQuizFail(false)
                      )}
                    >
                      Làm lại quiz
                    </Button.Default>
                  </div>
                )}
                <ButtonDirectional>
                  <Button.Outline
                    icon={<LeftOutlined />}
                    onClick={goToPrevQuestion}
                    disabled={+question > 1 ? false : true}
                  >
                    Câu trước
                  </Button.Outline>

                  <Button.Outline
                    onClick={goToNextQuestion}
                    disabled={+question < quizes.length ? false : true}
                  >
                    Câu sau{' '}
                    <span style={{ marginLeft: 6 }}>
                      <RightOutlined />
                    </span>
                  </Button.Outline>
                  {/* <Button.Primary>Nộp bài tập</Button.Primary> */}
                </ButtonDirectional>
              </div>
            </Form>
          ) : (
            <Form
              onValuesChange={(values: { answerCheckboxChosen: number[] }) => {
                if (values.answerCheckboxChosen.length === 0)
                  dispatch(setNotAnswer({ ...requiredActionProps, notAnswer: true }));
                else dispatch(setNotAnswer({ ...requiredActionProps, notAnswer: false }));
              }}
              onFinish={(values: { answerCheckboxChosen: number[] }) => {
                // submitAnswer(values.answerCheckboxChosen);
                submitAnswer();
              }}
            >
              <Form.Item className="multiple" name="answerCheckboxChosen">
                <QuizGroup.Checkbox style={{ width: '100%' }}>
                  <Checkbox.Group
                    style={{ width: '100%', display: 'block' }}
                    value={values ? values : []}
                    onChange={onChangeCheckBox}
                  >
                    <Row>
                      {answers?.map((item) => (
                        <Col span={24} key={item.id}>
                          <Checkbox
                            checked={true}
                            value={item.id}
                            className={
                              !QuizSubmit
                                ? ''
                                : isAnswered && isCorrect
                                ? 'right-answer'
                                : 'wrong-answer'
                            }
                            disabled={is_true == false || is_true == true}
                          >
                            {/* {<ShowMarkdown markdown={item.answer} />} */}
                            {item.answer.includes('`') ? (
                              <ShowMarkdown markdown={item.answer} />
                            ) : (
                              <ShowMarkdown
                                markdown={item.answer
                                  .replace(/</g, '&lt;')
                                  .replace(/>/g, '&gt;')
                                  .replace(/#/g, '&#35;')}
                              />
                            )}
                            <CheckOutlined />
                            <CloseOutlined />
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </QuizGroup.Checkbox>
              </Form.Item>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '12px',
                  flexWrap: 'wrap',
                }}
              >
                {submitButton}

                {totalTrue + totalFalse == quizes.length && (
                  <div style={{ marginBottom: 25 }}>
                    <Button.Default
                      onClick={() => (
                        resetQuiz(),
                        setValues([]),
                        setPlayQuizSuccess(false),
                        setPlayQuizFail(false)
                      )}
                    >
                      Làm lại quiz
                    </Button.Default>
                  </div>
                )}
                <ButtonDirectional>
                  <Button.Outline
                    onClick={goToPrevQuestion}
                    disabled={+question > 1 ? false : true}
                    icon={<LeftOutlined />}
                  >
                    Câu trước
                  </Button.Outline>
                  <Button.Outline
                    onClick={goToNextQuestion}
                    disabled={+question < quizes.length ? false : true}
                  >
                    Câu sau{' '}
                    <span style={{ marginLeft: 6 }}>
                      <RightOutlined />
                    </span>
                  </Button.Outline>
                  {/* <Button.Primary>Nộp bài tập</Button.Primary> */}
                </ButtonDirectional>
              </div>
            </Form>
          )}
        </Col>
      </Row>
      {playQuizSuccess && (
        <audio autoPlay={true}>
          <source src={audioSuccess} type="audio/mpeg" />
        </audio>
      )}
      {playQuizFail && (
        <audio autoPlay={true}>
          <source src={audioFail} type="audio/mpeg" />
        </audio>
      )}
      <Modal
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Bỏ qua"
        okText="Làm lại quiz"
      >
        {scoreQuiz(totalTrue, quizes.length) >= 7 && (
          <div>
            <div style={{ marginTop: 31, height: 300 }}>
              <img
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                src={
                  scoreQuiz(totalTrue, quizes.length) == 10
                    ? getRandomImage(Image10_10)
                    : scoreQuiz(totalTrue, quizes.length) == 9
                    ? getRandomImage(Image09_10)
                    : scoreQuiz(totalTrue, quizes.length) == 8
                    ? getRandomImage(Image08_10)
                    : getRandomImage(Image07_10)
                }
                alt="Ảnh cầu thủ bóng đá"
              />
            </div>

            <h2 style={{ color: '#204BE5', textAlign: 'center', marginTop: 12 }}>Hoàn thành</h2>
            <p style={{ textAlign: 'center', fontSize: 16 }}>
              Chúc mừng bạn đã hoàn thành bài quiz với{' '}
              <span style={{ color: '#204BE5', fontWeight: '600' }}>{totalTrue}</span>/
              <span style={{ fontWeight: '600' }}>{quizes.length} </span>
              câu đúng!
            </p>
          </div>
        )}

        {scoreQuiz(totalTrue, quizes.length) < 7 && (
          <div>
            <div style={{ marginTop: 31 }}>
              <img src={ImageCat} alt="image cat" />
            </div>
            <h2 style={{ color: 'red', textAlign: 'center', marginTop: 12 }}>Hãy cố gắng lên</h2>
            <div style={{ textAlign: 'center', fontSize: 16 }}>
              Rất tiếc bạn chưa vượt qua bài quiz với{' '}
              <span style={{ color: '#204BE5', fontWeight: '600' }}>{totalTrue}</span>/
              <span style={{ fontWeight: '600' }}>{quizes.length} </span> câu đúng.
            </div>
            <div style={{ textAlign: 'center', fontSize: 16 }}>Hãy thử lại nhé !</div>
          </div>
        )}
      </Modal>
    </QuizContainer.Container>
  );
};

export default QuizItem;
