import Container from 'components/commons/Container';
import NotAuthorized from 'components/commons/NotAuthorized';
import Header from 'layouts/layout-components/header';
import { highestRoles, ROLE_STUDENT, ROLE_TRAINER } from 'libraries/utils/globalVariable';
import { ReactElement, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import checkUserRole from 'utils/checkUserRole';
import GetClass from './GetClass';
import GetPractice from './GetPractice';

const ClassroomLayout = (): ReactElement => {
  const checkRole = checkUserRole([...highestRoles, ROLE_STUDENT, ROLE_TRAINER]);
  const [layout, setLayout] = useState('default');
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname.includes('/practice/')) {
      setLayout('practice');
    } else {
      setLayout('default');
    }
  }, [pathname]);
  if (!checkRole) {
    return (
      <div style={{ height: '100%' }}>
        <Header />

        <Container>
          <NotAuthorized />
        </Container>
      </div>
    );
  }

  return (
    <div style={{ height: '100%' }}>
      <Header />
      <Container>
        {layout == 'default' && <GetClass />}
        {layout == 'practice' && <GetPractice />}
      </Container>
    </div>
  );
};

export default ClassroomLayout;
