import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { selectUser } from 'store/auth.slice';
import { ILayoutRoute } from 'models/route.model';

import LoginPage from 'libraries/pages/LoginPage';

const LayoutRoute = ({ routes }: { routes: ILayoutRoute[] }): ReactElement => {
  const user = useSelector(selectUser);

  return (
    <Switch>
      {user?.full_name ? (
        routes.map(({ path, meta }: ILayoutRoute) => (
          <Route key={path} path={path}>
            <meta.layout />
          </Route>
        ))
      ) : (
        <LoginPage />
      )}
    </Switch>
  );
};

export default LayoutRoute;
