import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeleteUserNoteMutation, useEditUserNoteMutation } from 'services/onlinecourse.service';
import {
  selectLecture,
  setSeekTime,
  updateDeletedNote,
  updateEditNote,
} from 'store/onlinecourse.slice';
import { Note } from 'models/class.model';
import { message, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import ShowMarkdown from 'libraries/components/markdown/ShowMarkdown';
import Editor from 'libraries/editor';
import Duration from 'libraries/components/Duration';
import TimeSlot from './TimeSlot';
import NoteStyle from './NoteStyle';
import Button from 'libraries/components/commons/Button';
import { useParams } from 'react-router';
const SubmitGroupButton = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 20px;
  gap: 24px;
`;

const { confirm } = Modal;

const NoteItem = ({ id: noteId, content, played_seconds }: Note): ReactElement => {
  const { classId: classId } = useParams<{ classId: string }>();
  const dispatch = useDispatch();
  const { id } = useSelector(selectLecture);
  const [isEdit, setIsEdit] = useState(false);
  const [tmpContent, setTmpContent] = useState(content);
  const [deleteNote, deleteResponse] = useDeleteUserNoteMutation();
  const [editUserNote, editResponse] = useEditUserNoteMutation();

  const showDeleteConfirm = () => {
    confirm({
      title: 'Xoá ghi chú này?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ',
      onOk() {
        deleteNote({classId, lectureId: id, noteId: noteId });
        if (!deleteResponse.isError) {
          dispatch(updateDeletedNote(noteId));
          message.success('Xoá ghi chú thành công!');
        } else {
          message.error('Xoá ghi chú không thành công!');
        }
      },
    });
  };

  const handleEditNote = () => {
    setIsEdit(false);
    editUserNote({classId, lectureId: id, noteId: noteId, data: { content: tmpContent } });
    if (!editResponse.isError) {
      dispatch(updateEditNote({ id: noteId, content: tmpContent }));
      message.success('Sửa ghi chú thành công!');
    } else {
      message.error('Sửa ghi chú không thành công!');
    }
  };

  return (
    <NoteStyle.Wrapper>
      <NoteStyle.Info>
        <TimeSlot
          onClick={() => {
            dispatch(setSeekTime(played_seconds));
          }}
        >
          <Duration seconds={played_seconds} />
        </TimeSlot>
        <div>
          <EditOutlined onClick={() => setIsEdit(true)} />
          <DeleteOutlined onClick={showDeleteConfirm} />
        </div>
      </NoteStyle.Info>
      <NoteStyle.Content>
        {isEdit ? (
          <>
            <Editor
              height={150}
              value={tmpContent}
              onChange={(value) => setTmpContent(value)}
            ></Editor>

            <SubmitGroupButton>
              <Button.Default onClick={handleEditNote}>Lưu</Button.Default>
              <Button.Cancel onClick={() => setIsEdit(false)}>Huỷ</Button.Cancel>
            </SubmitGroupButton>
          </>
        ) : (
          <ShowMarkdown markdown={content} className="note-content" />
        )}
      </NoteStyle.Content>
    </NoteStyle.Wrapper>
  );
};

export default NoteItem;
