import { ReactElement, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

enum Type {
  online = 'online',
  offline = 'offline',
}

interface SubjectTagProp {
  type: Type;
}

const SubjectTag = styled.div<PropsWithChildren<SubjectTagProp>>`
  font-family: Mulish;
  font-size: 14px;
  border-radius: 10px;
  padding: 0 8px;
  color: #212529;

  ${({ type }) =>
    type === Type.online
      ? css`
          border: 1px solid #8c8c8d;
        `
      : css`
          border: 1px solid #8c8c8d;
        `}
`;

const Online = (): ReactElement => <SubjectTag type={Type.online}>Online tương tác</SubjectTag>;
const Offline = (): ReactElement => <SubjectTag type={Type.offline}>Offline phòng lab</SubjectTag>;

export default { Online, Offline };
