import { ReactElement, useState } from 'react';
import { message } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useSubmitExerciseMutation } from 'services/class.service';
import { selectClass, updateExercisesAfterSubmit } from 'store/class.slice';
import styled from 'styled-components';
import Button from 'libraries/components/commons/Button';
import Editor from 'libraries/editor';

interface SubmitProp {
  id: number;
}

const ExerciseAnswerWrapper = styled.div`
  margin-bottom: 16px;
  .link-item {
    position: relative;
    margin-bottom: 10px;
    input[type='text'] {
      width: 100%;
      height: 32px;
      border: 0.5px solid #8c8c8d;
      padding-left: 10px;
      &:focus-visible {
        outline: none;
      }
    }
    .anticon {
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: poiter;
    }
  }
  .insert-more-link {
    margin-top: 15px;
    text-align: right;
  }
`;

const SubmitGroupButton = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 20px;
  gap: 24px;
`;

const ExerciseSubmitForm = ({ id }: SubmitProp): ReactElement => {
  const dispatch = useDispatch();

  const { id: classroomId } = useSelector(selectClass);
  const [links, setLinks] = useState<string[]>(['']);
  const [note, setNote] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);

  const [submitExercise] = useSubmitExerciseMutation();

  const handleInputLink = (index: number, value: string) => {
    const newLinks = [...links];
    newLinks[index] = value;
    setLinks(newLinks);
    value == '' ? setIsSubmit(false) : setIsSubmit(true);
  };

  const handleRemoveLink = (index: number) => {
    const newLinks = [...links];
    newLinks.splice(index, 1);
    setLinks(newLinks);
  };

  const addMoreLink = () => {
    const newLinks = [...links];
    newLinks.push('');
    setLinks(newLinks);
  };

  const handleSubmit = () => {
    const newLinks = links.filter((item) => item !== '');

    const linksParams = newLinks.map((link) =>
      link.startsWith('https://') || link.startsWith('http://') ? link : `https://${link}`,
    );

    submitExercise({ classroomId: classroomId, data: { id, note, links: linksParams } })
      .unwrap()
      .then((response) => {
        message.success('Nộp bài tập thành công');
        dispatch(
          updateExercisesAfterSubmit({
            exerciseId: id,
            history: {
              submit_date: response.submit_date,
              mark: response.mark,
              links: response.links,
              comment: response.comment,
              note: response.note,
            },
          }),
        );
        setLinks(['']);
        setNote('');
        setIsSubmit(false);
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  return (
    <>
      <ExerciseAnswerWrapper>
        <p>Link nộp bài tập:</p>
        {links.map((link, index) => (
          <div className="link-item" key={index}>
            <input
              type="text"
              value={link}
              onChange={(e) => handleInputLink(index, e.target.value)}
            />
            {index > 0 && <CloseCircleFilled onClick={() => handleRemoveLink(index)} />}
          </div>
        ))}
        <div className="insert-more-link">
          <Button.Default onClick={addMoreLink}>Thêm link</Button.Default>
        </div>
      </ExerciseAnswerWrapper>
      <div className="answer-detail answers">
        <p>Ghi chú cho giảng viên:</p>
        <div>
          <Editor value={note} onChange={(value) => setNote(value)} height={200}></Editor>
        </div>
        <div className="submit-mode">
          <SubmitGroupButton>
            <Button.Action onClick={handleSubmit} disabled={!isSubmit}>
              Nộp bài
            </Button.Action>
          </SubmitGroupButton>
        </div>
      </div>
    </>
  );
};

export default ExerciseSubmitForm;
