import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { selectClass } from 'store/class.slice';
import Main from '../Main';
import CommonInfo from './CommonInfo';

const InfoPage = (): ReactElement => {
  const { teachers } = useSelector(selectClass);

  return (
    <Main>
      <h3>Danh sách giảng viên</h3>
      <CommonInfo teachers={teachers} />
    </Main>
  );
};

export default InfoPage;
