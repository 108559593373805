import styled from 'styled-components';

const TimeSlot = styled.span`
  display: inline-block;
  padding: 3px 6px;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
`;

export default TimeSlot;
