import { ReactElement } from 'react';
import { Dropdown, Avatar } from 'antd';
import { UserOutlined, CaretDownOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { selectUserAvatar } from 'store/auth.slice';
import UserDefaultAvatar from 'assets/images/avatar.png';
import UserDropdown from './UserDropdown';

const User = (): ReactElement => {
  const avatar = useSelector(selectUserAvatar);
  const imgSrc = avatar !== '' ? avatar : UserDefaultAvatar;

  return (
    <Dropdown overlay={<UserDropdown />} placement="bottomRight" trigger={['click']}>
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <Avatar size={32} icon={<UserOutlined />} src={imgSrc} />
        &nbsp;
        <CaretDownOutlined style={{ color: '#ffffff' }} />
      </a>
    </Dropdown>
  );
};

export default User;
