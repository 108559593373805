import styled from 'styled-components';

const LearningState = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const Danger = styled(LearningState)`
  color: #ff4d4f;
`;

const Success = styled(LearningState)`
  color: #28a745;
`;
const Warning = styled(LearningState)`
  color: #ffc107;
`;
const Primary = styled(LearningState)`
  color: #0275d8;
`;

// Special using for Lesson page (RollCall button)
const Attendance = styled(LearningState)`
  background: rgb(255, 193, 7);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    color: black;
    background: #e0a800;
  }
`;
export default { Danger, Success, Warning, Attendance, Primary };
