import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { selectClass } from 'store/class.slice';
import Main from '../Main';
import Review from './Review';

const ReviewsPage = (): ReactElement => {
  const { teachers } = useSelector(selectClass);

  return (
    <Main>
      <h4>Đánh giá chất lượng của khóa học</h4>
      <Review />
    </Main>
  );
};

export default ReviewsPage;
