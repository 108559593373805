import { ReactElement } from 'react';
import Main from 'components/commons/Main';
import MainTitle from 'components/commons/MainTitle';
import TransactionItem from './TransactionItem';
import { useGetUserTransactionQuery } from 'services/transaction.service';
import ShowNotFound from 'components/commons/ShowNotFound';

const TransactionPage = (): ReactElement => {
  const { data: transactionsList, isError, error } = useGetUserTransactionQuery();

  if (isError) {
    return <ShowNotFound error={error} />;
  }

  return (
    <Main>
      <MainTitle>Lịch sử giao dịch</MainTitle>
      {transactionsList ? (
        <TransactionItem transactionsList={transactionsList} />
      ) : (
        <TransactionItem transactionsList={[]} />
      )}
    </Main>
  );
};

export default TransactionPage;
