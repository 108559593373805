import VideoPlayer from 'components/video/VideoPlayer';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { selectLecture, selectPlayVideo, selectSeekTime } from 'store/onlinecourse.slice';

// TODO: Khi play video, hiện đang lưu played_seconds (thời gian chạy video) vào redux để hiển thị ở nút tạo note mới. Dẫn đến component bị re-render liên tục, cần tối ưu lại.
const CourseVideo = (): ReactElement => {
  const { video_url } = useSelector(selectLecture);
  const playVideo = useSelector(selectPlayVideo);
  const seekTime = useSelector(selectSeekTime);
  
  return (
    <>
      {video_url !== '' && (
        <VideoPlayer url={video_url} seekTime={seekTime} width="100%" playVideo={playVideo} />
      )}
    </>
  );
};

export default CourseVideo;
