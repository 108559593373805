import { ReactElement } from 'react';
import styled from 'styled-components';

interface LessonTitleProp {
  className: string;
}

const LessonTitleWrapper = styled.div`
  font-size: 18px;
  font-weight: 600;
  height: 44px;
  line-height: 44px;
  margin: 0 auto;
  color: #ffffff;
  text-align: center;
  background-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LessonTitle = ({ className }: LessonTitleProp): ReactElement => {
  return <LessonTitleWrapper>{className}</LessonTitleWrapper>;
};
export default LessonTitle;
