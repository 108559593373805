import { ReactElement } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { ClassList } from 'models/class.model';

import { generatePath } from 'react-router';
import { app } from 'routers/routeNames';
import Subject from 'components/subject/Subject';
import EmptyClass from 'components/commons/EmptyClass';

interface SubjectListProps {
  list: ClassList[];
}

const SubjectList = (props: SubjectListProps): ReactElement => {
  return (
    <Row gutter={[30, 30]}>
      {props.list.length === 0 ? (
        <EmptyClass title="Không có lớp học nào" returnTitle="Đăng ký học" />
      ) : (
        props.list.map((subject, i) => {
          return (
            <Col key={i} xs={24} sm={12} md={12} xl={8}>
              <Link
                to={generatePath(app.classroom, {
                  classroom: subject.id,
                })}
              >
                <Subject {...subject} />
              </Link>
            </Col>
          );
        })
      )}
    </Row>
  );
};

export default SubjectList;
