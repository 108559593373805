import { ReactElement } from 'react';
import { Drawer as AntdDrawer, DrawerProps as AntdDrawerProps } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { hideDrawer, selectOpenDrawer } from 'store/general.slice';

const Drawer = (props: AntdDrawerProps): ReactElement => {
  const openDrawer = useSelector(selectOpenDrawer);
  const dispatch = useDispatch();

  return (
    <AntdDrawer
      {...props}
      bodyStyle={{ padding: '0' }}
      title="Nội dung"
      open={openDrawer}
      onClose={() => dispatch(hideDrawer())}
    />
  );
};

Drawer.defaultProps = {
  placement: 'right',
  width: 320,
};

export default Drawer;
