import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { selectClass } from 'store/class.slice';
import { useGetExerciseDiscussesQuery } from 'services/class.service';
import styled from 'styled-components';
import MakeExerciseDiscuss from './MakeExerciseDiscuss';
import ExerciseDiscussContent from './ExerciseDiscussContent';

interface PropsType {
  exerciseId: number;
  exercise_name: string;
}

const Wrapper = styled.div`
  p.note {
    font-size: 14px;
    color: #8c8c8d;
    text-align: center;
  }
`;

const ExerciseDiscuss = ({ exerciseId, exercise_name }: PropsType): ReactElement => {
  const { id } = useSelector(selectClass);
  useGetExerciseDiscussesQuery({
    id: id,
    exerciseId: exerciseId,
  });

  return (
    <Wrapper>
      <p className="note">Chỉ bạn đọc được thông tin này</p>
      <ExerciseDiscussContent />
      <MakeExerciseDiscuss exerciseId={exerciseId} exercise_name={exercise_name} id={id} />
    </Wrapper>
  );
};

export default ExerciseDiscuss;
