import { ReactElement } from 'react';
import { useGetOnlineClassesQuery } from 'services/onlinecourse.service';

import Main from 'components/commons/Main';
import SubjectList from '../learn/subject/SubjectList';

import EmptyClass from 'components/commons/EmptyClass';
import ShowNotFound from 'components/commons/ShowNotFound';
import LoadingSpin from 'libraries/components/LoadingSpin';
import { ClassList } from 'models/class.model';

const GetList = (): ReactElement => {
  const { data, isLoading, isError, error } = useGetOnlineClassesQuery({ type: 'video' });

  const classList = data ? data : ([] as ClassList[]);

  return (
    <Main>
      {isLoading ? (
        <LoadingSpin text="Đang lấy dữ liệu lớp học" />
      ) : isError ? (
        <Main>
          <ShowNotFound error={error} />
        </Main>
      ) : (
        <>{classList.length > 0 ? <SubjectList list={classList} /> : <EmptyClass />}</>
      )}
    </Main>
  );
};

export default GetList;
