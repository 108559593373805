import { FileOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Checkbox, Tabs } from 'antd';
import { Collapse, Panel } from 'components/commons/Collapse';
import ParentCollapseHeader from 'pages/class-detail/module/parentCollapse/ParentCollapseHeader';
import { ReactElement, memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { learning } from 'routers/routeNames';
import { useGetOnlineClassesQuery } from 'services/onlinecourse.service';
import { createModule, selectModules } from 'store/class.slice';
import { hideDrawer, selectIsSidebarCollapse } from 'store/general.slice';
import { selectCourse, updateLearned } from 'store/onlinecourse.slice';
import styled from 'styled-components';
import { tagCurrent } from 'utils/tagCurrent';
import useMedia from 'utils/useMedia';
import { Lesson } from '../../../models/class.model';
import Menu from '../style/Menu';
import Sidebar from './Sidebar';
const { Item } = Menu;
const { TabPane } = Tabs;

const CourseTitle = styled.h5`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 8px;
`;
const TabsStyle = styled.div`
  background-color: #ffffff;
  .ant-tabs-nav-wrap {
    margin-left: 16px;
    font-weight: 600;
  }
  .ant-tabs-tab-btn {
    font-size: 16px;
  }
`;
const ProgressContainer = styled.div`
  .ant-menu-submenu-title {
    padding-left: 13px !important;
  }
`;
const ModuleContainer = styled.div`
  .ant-collapse-header {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #7c7c7c;
    min-height: 60px;
  }
  .ant-collapse-content-box {
    padding: 0 !important;
  }
  .ant-menu-submenu-title {
    padding-left: 34px !important;
  }
  .ant-menu ant-menu-sub {
    padding-left: 34px !important;
  }
  // .ant-menu-submenu .ant-menu-submenu {
  //   margin-left: 0;
  // }
  .ant-collapse-header {
    font-size: 18px;
  }
  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    display: block;
    background-color: #e3f2fd;
    border: none;
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    padding-left: 34px !important;
  }
  .ant-collapse-item-active .ant-collapse-header {
    background-color: #f5f5f5;
  }

  .ant-collapse-item {
    margin-bottom: 0;
    border-bottom: 1px solid #7c7c7c;
  }

  .ant-collapse-header {
    border: none;
  }
  .ant-collapse-item:first-child {
    border-top: 1px solid #7c7c7c;
  }
`;

const CourseStatus = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: #7e7373;
`;
const MenuItem = styled(Item)`
  &.ant-menu-item-selected {
    background-color: #f0fcff !important;
  }
`;

interface LessonsMenuProp {
  totalLessons: number;
  currentLesson: string;
  currentLecture: string;
  isLoading?: boolean;
}
export interface ILocalStorageProps {
  id: string;
  checked?: boolean;
  score?: number;
}

const LessonsMenu = ({
  totalLessons,
  currentLesson,
  currentLecture,
  isLoading,
}: LessonsMenuProp): ReactElement => {
  const { data } = useGetOnlineClassesQuery({ type: 'video' });

  const lessonIdRef = useRef(currentLesson);
  const { xs, sm, md } = useMedia();
  const dispatch = useDispatch();
  const isSidebarCollapse = useSelector(selectIsSidebarCollapse);
  const { id, lessons, name: className, learned } = useSelector(selectCourse);
  const url = window.location.href;
  const regex = /\/learn\/([a-zA-Z0-9-]+)\//;
  const match = url.match(regex);
  const classId = (match && match[1]) || id;
  const urlObj = new URL(url);
  const searchParams = new URLSearchParams(urlObj.search);
  const groupId = searchParams.get('group_id') || '';
  if (groupId != '') {
    window.localStorage.setItem('groupId', groupId);
  }
  const groupIdLocalStorage = window.localStorage.getItem('groupId') || '';
  const [openKeys, setOpenKeys] = useState<string[]>([`lesson-${currentLesson}`]);
  const [complete, setComplete] = useState(0);
  const [activeTab, setActiveTab] = useState<string>('');
  const [classInfo, setClassInfo] = useState<any>();
  useEffect(() => {
    if (!data) return;
    const newData = data.filter((item) => item.id == classId);
    if (newData.length == 0) return;
    setClassInfo(newData[0]);
  }, [data]);

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };
  useEffect(() => {
    if (!lessons) return;
    const groups = [] as any;

    lessons.forEach((lesson, i) => {
      const groupIndex = groups.findIndex((group: any) => group.name === lesson.group_name);

      if (groupIndex !== -1) {
        groups[groupIndex].lessons.push(lesson);
      } else {
        groups.push({ id: i, name: lesson.group_name, lessons: [lesson] });
      }
    });

    dispatch(createModule(groups));
  }, [lessons]);

  const modules = useSelector(selectModules);

  const onOpenChange = (keys: string[]) => {
    const rootSubmenuKeys: string[] = [];
    lessons?.map((section: Lesson) => {
      rootSubmenuKeys.push(`lesson-${section.id}`);
    });

    const latestOpenKey = keys.find((key: string) => openKeys.indexOf(key) === -1) || '';
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const scrollLectureIntoView = (behavior: ScrollBehavior, block: ScrollLogicalPosition) => {
    // const element = document.getElementById(`lecture-${currentLecture}`);
    const element = document.querySelector(`[data-menu-id*=${openKeys}]`);

    if (element) element.scrollIntoView({ behavior, block });
  };

  const handleSelect = () => {
    if (xs || sm || md) dispatch(hideDrawer());
  };
  const tabmenu = window.localStorage.getItem('tabMenu') || '';
  useEffect(() => {
    if (tabmenu == 'tab1') {
      setActiveTab('tab1');
      return;
    } else if (tabmenu == 'tab2') {
      setActiveTab('tab2');
      return;
    }
  }, []);

  useEffect(() => {
    if (activeTab == 'tab1') {
      window.localStorage.setItem('tabMenu', 'tab1');
    } else {
      window.localStorage.setItem('tabMenu', 'tab2');
    }
  }, [activeTab]);

  useEffect(() => {
    if (
      lessons &&
      currentLesson !== lessons[0]?.id &&
      currentLecture !== lessons[0]?.lectures[0]?.id
    ) {
      setTimeout(() => {
        scrollLectureIntoView('smooth', 'start');
      }, 500);
    }
  }, [isLoading]);

  // Chuyển lesson, mở submenu tương ứng
  useEffect(() => {
    setOpenKeys([`lesson-${currentLesson}`]);
  }, [currentLesson]);

  // Chuyển lecture, tự động scroll đến lecture đó
  useEffect(() => {
    if (lessonIdRef.current === currentLesson) {
      return;
    } else {
      lessonIdRef.current = currentLesson;
    }

    setTimeout(() => {
      scrollLectureIntoView('smooth', 'start');
    }, 500);
  });
  useEffect(() => {
    setComplete(
      learned === 0 || totalLessons === 0
        ? 0
        : learned === totalLessons
        ? 100
        : Math.floor((learned / totalLessons) * 100),
    );
  }, [learned, totalLessons]);

  useEffect(() => {
    const json = localStorage.getItem('checkbox');
    if (json) {
      const item = JSON.parse(json);
      dispatch(updateLearned(item.length));
      // dispatch(updateLearned(learned));
    }
  }, [learned]);

  // Local Storage Checkbox
  const onChange = (id: string, checked: boolean) => {
    const json = localStorage.getItem('checkbox');
    if (typeof json == 'string' && checked) {
      const item = JSON.parse(json);
      localStorage.setItem('checkbox', JSON.stringify([...item, { id: id, checked: checked }]));
    } else if (typeof json == 'string' && !checked) {
      const items = JSON.parse(json);
      const deleted = items.filter((item: ILocalStorageProps) => item.id !== id);
      localStorage.setItem('checkbox', JSON.stringify([...deleted]));
    } else {
      localStorage.setItem('checkbox', JSON.stringify([{ id: id, checked: checked }]));
    }
    //set learned
    if (checked) {
      dispatch(updateLearned(learned + 1));
    } else {
      dispatch(updateLearned(learned - 1));
    }
  };

  const checked = (id: string): boolean => {
    const json = localStorage.getItem('checkbox');
    if (typeof json == 'string') {
      const items = JSON.parse(json);
      return items.some((item: ILocalStorageProps) => item.id == id);
    } else return false;
  };

  return (
    <Sidebar style={{ width: isSidebarCollapse ? 0 : 320 }}>
      <div className="course-title">
        <CourseTitle>{className}</CourseTitle>
        <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
          <CourseStatus>{complete > 100 ? 100 : complete}%</CourseStatus>
          <div
            style={{
              width: `100%`,
              height: '12px',
              background: '#ccc',
              position: 'relative',
            }}
          >
            <div
              className=""
              style={{
                width: `${complete}%`,
                height: '12px',
                background: '#1890ff',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 100,
              }}
            ></div>
          </div>
        </div>
        {/* <Progress percent={complete} /> */}
      </div>
      <TabsStyle>
        <Tabs activeKey={activeTab} onChange={handleTabChange}>
          <TabPane tab="Tiến độ" key="tab1">
            <ProgressContainer>
              <Menu.Main
                mode="inline"
                selectedKeys={[`lecture-${currentLecture}`]}
                defaultSelectedKeys={[`lecture-${currentLecture}`]}
                openKeys={openKeys}
                defaultOpenKeys={[`lesson-${currentLesson}`]}
                onOpenChange={onOpenChange}
                style={{ height: '100%', borderTop: '1px solid #888' }}
                inlineCollapsed={isSidebarCollapse}
                onSelect={() => handleSelect()}
              >
                {lessons?.map((section, index) => {
                  // if (section.lectures.length > 0) {
                  return (
                    <Menu.SubMenu
                      key={`lesson-${section.id}`}
                      title={`${index + 1}. ${section.name}`}
                    >
                      {section.lectures.map((item) => {
                        if (item.id === '') {
                          return '';
                        }

                        let isComplete: boolean | undefined;
                        if (item.type === 'quiz') {
                          let itemsLocal;
                          const json = localStorage.getItem('checkbox');
                          json && (itemsLocal = JSON.parse(json));
                          const itemLocal = itemsLocal?.find(
                            (local: ILocalStorageProps) => local.id === item.id,
                          );
                          isComplete = itemLocal ? true : false;
                        }

                        return (
                          <MenuItem
                            key={`lecture-${item.id}`}
                            icon={
                              item.type === 'quiz' ? (
                                <QuestionCircleOutlined
                                  style={isComplete ? { color: '#1890ff' } : { color: 'inherit' }}
                                />
                              ) : (
                                <Checkbox
                                  defaultChecked={checked(item.id)}
                                  onChange={(e) => onChange(item.id, e.target.checked)}
                                />
                              )
                            }
                          >
                            <Link
                              to={{
                                pathname: generatePath(learning.learn, {
                                  classId,
                                  lessonId: section.id,
                                  lectureId: item.id,
                                }),
                                search: item.type === 'quiz' ? '?question=1' : '',
                              }}
                            >
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                  <span style={{ marginRight: '4px' }}>{item.title}</span>
                                  {item.type === 'assignment' && <FileOutlined />}
                                </div>
                                {classInfo?.type != 'video' && tagCurrent(item.tag)}
                              </div>
                            </Link>
                            <p className="video-duration">{item.duration}</p>
                          </MenuItem>
                        );
                      })}
                    </Menu.SubMenu>
                  );
                  // }
                  // return '';
                })}
              </Menu.Main>
            </ProgressContainer>
          </TabPane>
          <TabPane tab="Khung chương trình" key="tab2">
            <ModuleContainer>
              <Collapse accordion defaultActiveKey={groupId != '' ? groupId : groupIdLocalStorage}>
                {modules?.map((module, i) => {
                  const { id, name, lessons: lessonList } = module;
                  return (
                    <Panel key={id} header={<ParentCollapseHeader moduleId={id} title={name} />}>
                      {lessonList.length > 0 && (
                        <Menu.Main
                          mode="inline"
                          selectedKeys={[`lecture-${currentLecture}`]}
                          defaultSelectedKeys={[`lecture-${currentLecture}`]}
                          openKeys={openKeys}
                          defaultOpenKeys={[`lesson-${currentLesson}`]}
                          onOpenChange={onOpenChange}
                          style={{ height: '100%' }}
                          inlineCollapsed={isSidebarCollapse}
                          onSelect={() => handleSelect()}
                        >
                          {lessonList?.map((section, index) => {
                            // if (section.lectures.length > 0) {
                            return (
                              <Menu.SubMenu key={`lesson-${section.id}`} title={`${section.name}`}>
                                {section.lectures?.map((item) => {
                                  if (item.id === '') {
                                    return '';
                                  }
                                  let isComplete: boolean | undefined;
                                  if (item.type === 'quiz') {
                                    let itemsLocal;
                                    const json = localStorage.getItem('checkbox');
                                    json && (itemsLocal = JSON.parse(json));
                                    const itemLocal = itemsLocal?.find(
                                      (local: ILocalStorageProps) => local.id === item.id,
                                    );
                                    isComplete = itemLocal ? true : false;
                                  }

                                  return (
                                    <MenuItem
                                      key={`lecture-${item.id}`}
                                      icon={
                                        item.type === 'quiz' ? (
                                          <QuestionCircleOutlined
                                            style={
                                              isComplete
                                                ? { color: '#1890ff' }
                                                : { color: 'inherit' }
                                            }
                                          />
                                        ) : (
                                          <Checkbox
                                            defaultChecked={checked(item.id)}
                                            onChange={(e) => onChange(item.id, e.target.checked)}
                                          />
                                        )
                                      }
                                    >
                                      <Link
                                        to={{
                                          pathname: generatePath(learning.learn, {
                                            classId,
                                            lessonId: section.id,
                                            lectureId: item.id,
                                          }),
                                          search: `?group_id=${id}`,
                                        }}
                                      >
                                        {' '}
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                          }}
                                        >
                                          <div>
                                            <span style={{ marginRight: '4px' }}>{item.title}</span>
                                            {item.type === 'assignment' && <FileOutlined />}
                                          </div>

                                          {classInfo?.type != 'video' && tagCurrent(item.tag)}
                                        </div>
                                      </Link>
                                      <p className="video-duration">{item.duration}</p>
                                    </MenuItem>
                                  );
                                })}
                              </Menu.SubMenu>
                            );
                            // }
                            // return '';
                          })}
                        </Menu.Main>
                      )}
                    </Panel>
                  );
                })}
              </Collapse>
            </ModuleContainer>
          </TabPane>
        </Tabs>
      </TabsStyle>
    </Sidebar>
  );
};

export default memo(LessonsMenu);
