import { CheckOutlined, CloseOutlined, FlagOutlined } from '@ant-design/icons';
import {
  Checkbox,
  Col,
  Divider,
  Form,
  message,
  Popconfirm,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
} from 'antd';
import Button from 'libraries/components/commons/Button';
import ShowMarkdown from 'libraries/components/markdown/ShowMarkdown';
import QuizGroup from 'pages/learn/quiz/QuizGroup';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import {
  selectPractice,
  selectQuizStatus,
  selectTimeSubmit,
  updateIsSubmit,
  updateIsTimeSubmit,
  updateQuizAfterSelect,
} from 'store/practice.slice';
import styled from 'styled-components';

import {
  useGetPracticeQuery,
  useSendAnswerQuizMutation,
  useSubmitPracticeMutation,
} from 'services/class.service';
import ModalReportQuiz from './ModalReportQuiz';
import SubQuizContent from './SubQuizContent';
import scrollToTop, { scrollToTopNoSmooth } from 'utils/scrollToTop';
import getNumberQuizNoHasAnswer from 'utils/getNumberQuizNoHasAnswer';
interface props {
  refetch: any;
  end_time: any;
  quizzes: any;
  is_submit: any;
}
const QuizzContent = styled.div`
  padding: 24px 24px 42px 24px;
  h2 {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
  }
  .Is_single {
    font-size: 18px;
    margin-left: 12px;
    background-color: #ccc;
    padding: 4px 8px;
  }
`;
const FlexStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const QuizAnwser = styled.div``;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 455px) {
    flex-direction: column;
  }
  button {
    margin-top: 12px;
    padding: 6px 24px;
    height: 40px;
  }
`;

const getAnswertrue = (arrayAnswer: any) => {
  return arrayAnswer.filter((item: any) => item.is_true);
};
const QuizzPracticeContent = ({ end_time, quizzes, is_submit, refetch }: props): ReactElement => {
  const [values, setValues] = useState<any>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const question = query.get('question');
  const { classroom: cls } = useParams<{ classroom: string }>();
  const { practiceId: practice_id } = useParams<{ practiceId: string }>();
  const quiz_status = useSelector(selectQuizStatus);
  const is_time_submit = useSelector(selectTimeSubmit);
  const [sendAnswerQuiz] = useSendAnswerQuizMutation();
  const [submitPractice] = useSubmitPracticeMutation();

  useEffect(() => {
    if (!question || !quizzes) return;
    const quizzItem = quizzes[+question - 1];
    localStorage.setItem('question', question);
    setValues(quizzItem.answers_choose);
  }, [end_time, question]);
  useEffect(() => {
    scrollToTopNoSmooth();
  }, [question]);

  useEffect(() => {
    if (!is_time_submit || is_submit) return;
    handleSubmit();
    dispatch(updateIsSubmit(true));
    dispatch(updateIsTimeSubmit(false));
  }, [is_time_submit, is_submit]);

  if (!question || !quizzes) return <></>;

  const quizzItem = quizzes[+question - 1];
  const {
    is_single,
    content,
    answers,
    id,
    answers_choose,
    explannation,
    is_true,
    quizzes: Subquizzes,
  } = quizzItem;

  const changeQuestion = (newQuestion: number) => {
    const currentLocation = history.location;
    const currentSearch = currentLocation.search;
    const searchParams = new URLSearchParams(currentSearch);
    searchParams.set('question', newQuestion.toString());
    history.push({
      pathname: currentLocation.pathname,
      search: searchParams.toString(),
    });
  };

  const confirm = () => {
    handleSubmit();
  };

  const handlePrevQuiz = () => {
    if (+question == 1) return;
    changeQuestion(+question - 1);
  };
  const handleNext = () => {
    if (+question == quizzes.length) return;

    changeQuestion(+question + 1);
  };
  const handleNextQuiz = () => {
    if ((!values && answers) || (values?.length == 0 && answers)) {
      message.error('Vui lòng chọn 1 đáp án để trả lời');
      return;
    }
    if (+question == quizzes.length) {
      // message.success('Trả lời thành công câu hỏi cuối, Vui lòng nộp bài để kết thúc !');
      return;
    }

    changeQuestion(+question + 1);
  };
  function handleSubmit() {
    submitPractice({
      classId: cls,
      practiceId: practice_id,
    })
      .unwrap()
      .then((data: any) => {
        message.success('Nộp bài thành công!');
        refetch();
      })
      .catch((error: any) => {
        message.error(error.data);
      });
  }

  const onChangeCheckBox = (checkedValues: any) => {
    setValues(checkedValues);
    sendAnswerQuiz({
      class_id: cls,
      practice_id,
      data: {
        answers: checkedValues,
        quiz_id: id,
      },
    })
      .unwrap()
      .then((data: any) => {
        dispatch(
          updateQuizAfterSelect({ quizzesId: id, has_answer: true, answers_choose: checkedValues }),
        );
      })
      .catch((error: any) => {
        message.error(error.data);
      });
  };
  const onChange = (e: RadioChangeEvent) => {
    setValues([e.target.value]);
    sendAnswerQuiz({
      class_id: cls,
      practice_id,
      data: {
        answers: [e.target.value],
        quiz_id: id,
      },
    })
      .unwrap()
      .then((data: any) => {
        dispatch(
          updateQuizAfterSelect({
            quizzesId: id,
            has_answer: true,
            answers_choose: [e.target.value],
          }),
        );
      })
      .catch((error: any) => {
        message.error(error.data);
      });
  };

  return (
    <QuizzContent>
      <FlexStyle>
        <h2>Đề bài {question}</h2>

      </FlexStyle>
      <div>
        <ShowMarkdown markdown={content} />
      </div>
      <Divider />
      {!is_submit && answers && (
        <>
          <QuizAnwser>
            {is_single ? (
              <Form
                onFinish={(values: { answers: number }) => {
                  // const answersData: number[] = [];
                  // answersData.push(values.answerRadioChosen);
                  // submitAnswer(answersData);
                }}
              >
                <Form.Item name="answers">
                  <QuizGroup.Radio>
                    <Radio.Group
                      onChange={onChange}
                      value={values ? values[0] : false}
                      style={{ display: 'block' }}
                    >
                      <Space direction="vertical">
                        {answers?.map((item: any) => (
                          <Radio
                            value={item.id}
                            key={item.id}
                            className={
                              is_submit && values?.includes(item.id) && is_true
                                ? 'right-answer'
                                : is_submit && values?.includes(item.id) && !is_true
                                  ? 'wrong-answer'
                                  : ''
                            }
                            disabled={is_submit || !quiz_status}
                          >
                            <ShowMarkdown markdown={item.content} />
                            <CheckOutlined />
                            <CloseOutlined />
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  </QuizGroup.Radio>
                </Form.Item>

                {/* {submitButton} */}
              </Form>
            ) : (
              <Form
                onValuesChange={(values: { answerCheckboxChosen: number[] }) => {
                  // if (values.answerCheckboxChosen.length === 0)
                  //   dispatch(setNotAnswer({ ...requiredActionProps, notAnswer: true }));
                  // else dispatch(setNotAnswer({ ...requiredActionProps, notAnswer: false }));
                }}
                onFinish={(values: { answerCheckboxChosen: number[] }) => {
                  // submitAnswer(values.answerCheckboxChosen);
                }}
              >
                <Form.Item className="multiple" name="answerCheckboxChosen">
                  <QuizGroup.Checkbox style={{ width: '100%', display: 'block', }}>
                    <Checkbox.Group
                      style={{ width: '100%', display: 'block' }}
                      value={values ? values : []}
                      onChange={onChangeCheckBox}
                    >
                      <Row>
                        {answers?.map((item: any) => (
                          <Col span={24} key={item.id}>
                            <Checkbox
                              checked={true}
                              value={item.id}
                              className={
                                is_submit && values?.includes(item.id) && is_true
                                  ? 'right-answer'
                                  : is_submit && values?.includes(item.id) && !is_true
                                    ? 'wrong-answer'
                                    : ''
                              }
                              disabled={is_submit || !quiz_status}
                            >
                              <ShowMarkdown markdown={item.content} />
                              <CheckOutlined />
                              <CloseOutlined />
                            </Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </Checkbox.Group>
                  </QuizGroup.Checkbox>
                </Form.Item>

                {/* {submitButton} */}
              </Form>

            )}

          </QuizAnwser>
          <Divider />

        </>
      )}
      {is_submit && (
        <>

          <Form.Item className="multiple" name="answerCheckboxChosen">
            <QuizGroup.Checkbox style={{ width: '100%', display: 'block' }}>
              <Checkbox.Group
                style={{ width: '100%', display: 'block' }}
                value={values ? values : []}
                onChange={onChangeCheckBox}
              >

                <Row>
                  {answers?.map((item: any) => {
                    const isSelected = values?.includes(item.id);
                    const isCorrect = item.is_true;
                  
                    return (
                      <Col span={24} key={item.id}>
                        <Checkbox
                          checked={true}
                          value={item.id}
                          className={
                            is_submit && isSelected
                              ? isCorrect
                                ? 'right-answer'
                                : 'wrong-answer'
                              : ''
                          }
                          disabled={is_submit || !quiz_status}
                        >
                          <ShowMarkdown markdown={item.content} />
                          {/*   <CheckOutlined /> */}
                          <CloseOutlined />

                        </Checkbox>
                        {is_submit && (
                          <span className={`correct-answer ${isCorrect ? 'show' : ''}`}>
                            {isCorrect && <CheckOutlined style={{ color: '#52c41a', position: 'absolute', marginTop: '-52px', fontSize: '25px', right: '11px' }} />}
                          </span>
                        )}
                      </Col>
                    );
                  })}
                </Row>

              </Checkbox.Group>
            </QuizGroup.Checkbox>
          </Form.Item>
          {explannation != '' && (
            <div>
              <h4>Giải thích:</h4>

              <ShowMarkdown markdown={explannation} />
            </div>
          )}
          <Divider />
        </>
      )}
      {Subquizzes &&
        Subquizzes?.map((quizItem: any, i: any) => (
          <SubQuizContent key={i} quizItem={quizItem} numberQuesiton={i + 1} />
        ))}

      <ButtonContainer>
        <Button.Outline disabled={+question == 1 ? true : false} onClick={handlePrevQuiz}>
          Câu trước
        </Button.Outline>
        {is_submit && (
          <Button.Outline
            onClick={handleNext}
            disabled={+question == quizzes?.length ? true : false}
          >
            Câu sau
          </Button.Outline>
        )}
        {!is_submit && +question < quizzes?.length && (
          <Button.Outline onClick={handleNextQuiz}>Câu sau</Button.Outline>
        )}

        {/* {!is_submit && +question == quizzes.length && (
          <Button.History onClick={handleNextQuiz}>Trả lời</Button.History>
        )} */}
        {!is_submit && (
          <Popconfirm
            placement="top"
            title={
              <>
                {getNumberQuizNoHasAnswer(quizzes) != 0 && (
                  <>
                    <span style={{ fontSize: '16px', fontWeight: '600' }}>
                      Còn {getNumberQuizNoHasAnswer(quizzes)} câu chưa trả lời
                    </span>
                    <br />
                  </>
                )}
                Bạn có chắc chắn muốn kết thúc bài kiểm tra này?
              </>
            }
            onConfirm={confirm}
            okText="Có"
            cancelText="Không"
          >
            <Button.Primary>Kết thúc luyện tập</Button.Primary>
          </Popconfirm>
        )}

        {is_submit && <Button.Primary disabled={true}>Kết thúc luyện tập</Button.Primary>}
      </ButtonContainer>
      <ModalReportQuiz
        isModalVisible={isModalVisible}
        handleCancel={() => setIsModalVisible(false)}
      />
    </QuizzContent>
  );
};

export default QuizzPracticeContent;