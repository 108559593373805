import { ReactElement, useState } from 'react';
import styled from 'styled-components';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import HistoryCommentModal from './HistoryCommentModal';
import { ExerciseHistory } from 'models/exercise.model';
import { formatDate } from 'utils/timeDistanceToNow';

interface HistoryProp {
  history: ExerciseHistory[];
}

const TableStyle = styled.div`
  font-size: 16px;
  width: 100%;
  overflow-x: scroll;
`;

function convertContentHtml(text: string) {
  return { __html: text };
}

const ExerciseHistorySubmitted = ({ history }: HistoryProp): ReactElement => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalComment, setModalComment] = useState('');

  const openModal = (comment: string) => {
    setModalComment(comment);
    setIsModalVisible(true);
  };

  const columns: ColumnsType<ExerciseHistory> = [
    {
      key: 'submit_date',
      title: 'Ngày nộp',
      width: '10%',
      dataIndex: 'submit_date',
      render: (submit_date) => formatDate(submit_date),
    },
    {
      key: 'mark',
      title: 'Điểm',
      dataIndex: 'mark',
      width: '12%',
      render: (mark) => (mark !== 0 ? `${mark}/10` : 'Chưa chấm'),
    },
    {
      key: 'links',
      title: 'Link bài làm',
      dataIndex: 'links',
      width: '24%',
      render: (links) => {
        return (
          <ul style={{ listStyleType: 'circle', paddingLeft: 20, maxWidth: '300px' }}>
            {links.map((item: string, index: number) => (
              <li key={index}>
                <a href={item} target="_blank" rel="noreferrer">
                  {item}
                </a>
              </li>
            ))}
          </ul>
        );
      },
    },
    {
      key: 'comment',
      title: 'Nhận xét',
      dataIndex: 'comment',
      width: '45%',
      render: (comment) => {
        return (
          <div
            style={{ cursor: 'pointer', textOverflow: 'ellipsis', minWidth: 200 }}
            dangerouslySetInnerHTML={convertContentHtml(comment)}
            onClick={() => openModal(comment)}
          ></div>
        );
      },
    },
  ];

  return (
    <>
      <TableStyle>
        <Table<ExerciseHistory>
          columns={columns}
          dataSource={history}
          pagination={false}
          rowKey="submit_date"
          bordered
        />
      </TableStyle>
      {isModalVisible && (
        <HistoryCommentModal
          isModalVisible={isModalVisible}
          comment={modalComment}
          closeModal={() => setIsModalVisible(false)}
        />
      )}
    </>
  );
};

export default ExerciseHistorySubmitted;
