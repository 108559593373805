import Modal from 'libraries/components/commons/Modal';
import { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetPracticeHistoryQuery } from 'services/class.service';

import TableQuizzHistory from './TableQuizzHistory';

interface Props {
  isModalVisible: boolean;
  handleCancel: any;
}

const ModalQuizHistory = ({ handleCancel, isModalVisible }: Props): ReactElement => {
  const onCloseModal = () => {
    handleCancel();
  };

  return (
    <>
      <Modal
        title="Lịch sử luyện thi"
        visible={isModalVisible}
        onCancel={onCloseModal}
        footer={null}
        width={1000}
        centered
      >
        <TableQuizzHistory />
      </Modal>
    </>
  );
};

export default ModalQuizHistory;
