import { ReactElement } from 'react';
import { Avatar } from 'antd';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/auth.slice';
import { selectDiscusses } from 'store/class.slice';
import styled from 'styled-components';
import UserDefaultAvatar from 'assets/images/avatar.png';
import timeDistanceToNow from 'utils/timeDistanceToNow';
import ShowMarkdown from 'libraries/components/markdown/ShowMarkdown';

const ConversationItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 15px;
  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
`;
const AuthorAvatar = styled.div`
  width: 50px;
  text-align: center;
`;
const ConversationUserContent = styled.div`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  @media screen and (min-width: 992px) {
    width: calc(100% - 100px);
  }
  &.ask {
    background-color: #dfeefc;
  }
  &.answer {
    background-color: #f3f3f3;
  }
  .conversation-time {
    font-size: 12px;
    color: #8c8c8d;
    margin-bottom: 0;
    margin-top: 10px;
  }
  .author-name {
    font-weight: 600;
    font-size: 16px;
  }
`;

const ExerciseDiscussContent = (): ReactElement => {
  const discusses = useSelector(selectDiscusses);
  const { id: userId } = useSelector(selectUser);

  return (
    <div>
      {discusses.map((c) => {
        const imgSrc = c.author_avatar !== '' ? c.author_avatar : UserDefaultAvatar;
        return (
          <ConversationItem key={c.id}>
            <AuthorAvatar>
              {c.author_id !== userId && <Avatar size={30} src={imgSrc} alt="avatar" />}
            </AuthorAvatar>

            <ConversationUserContent className={c.author_id === userId ? 'ask' : 'answer'}>
              {c.author_id !== userId && <div className="author-name">{c.author_name}</div>}
              <ShowMarkdown markdown={c.text} />
              <p className="conversation-time">{timeDistanceToNow(c.created_at)}</p>
            </ConversationUserContent>

            <AuthorAvatar>
              {c.author_id === userId && <Avatar size={30} src={imgSrc} alt="avatar" />}
            </AuthorAvatar>
          </ConversationItem>
        );
      })}
    </div>
  );
};

export default ExerciseDiscussContent;
