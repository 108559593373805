import { ReactElement } from 'react';
import { Row, Col, message } from 'antd';
import { generatePath, useHistory } from 'react-router-dom';
import { app, home, learning } from 'routers/routeNames';
import { useDispatch } from 'react-redux';
import { ClassList } from 'models/class.model';
import Subject from 'components/subject/Subject';
import { setBackLink } from 'store/general.slice';

interface SubjectListProps {
  list: ClassList[];
}

const SubjectList = ({ list }: SubjectListProps): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();

  const goToLearnPage = (subject: ClassList) => {
    const { lecture_id, lesson_id } = subject;
    if (lecture_id === '' || lesson_id === '') {
      message.warning('Lớp học chưa có bài giảng!');
    } else {
      const classId = subject.id;
      const link = JSON.parse(`${localStorage.getItem(classId)}`);
      let url = '';
      if (link === null) {
        url = generatePath(learning.welcomePage, {
          classId: classId,
        });
      } else {
        url = generatePath(learning.learn, {
          classId: classId,
          lessonId: link.lessonId,
          lectureId: link.lectureId,
        });
      }
      dispatch(setBackLink(home.onlineCourse));
      history.push(url);
    }
  };

  return (
    <Row gutter={[30, 30]}>
      {list.map((subject, i) => {
        return (
          <Col key={i} xs={24} sm={12} md={12} xl={8}>
            <a onClick={() => goToLearnPage(subject)}>
              <Subject {...subject} />
            </a>
          </Col>
        );
      })}
    </Row>
  );
};

export default SubjectList;
