import { ReactElement, useRef, useState } from 'react';
import { useMakeExerciseDiscussMutation } from 'services/class.service';
import styled from 'styled-components';
import Button from 'libraries/components/commons/Button';
import Editor from 'libraries/editor';

const ReplyQuesttion = styled.div`
  margin-top: 30px;
  .ant-btn.btn--submit {
    margin-top: 15px;
  }
`;

interface PropType {
  exerciseId: number;
  exercise_name: string;
  id: string;
}

const MakeExerciseDiscuss = ({ id, exerciseId, exercise_name }: PropType): ReactElement => {
  const [makeDiscuss, { error: makeError }] = useMakeExerciseDiscussMutation();
  const [tmpText, setTmpText] = useState('');
  const inputRef = useRef(0);
  const handleMakeDiscuss = () => {
    makeDiscuss({ id, exerciseId, data: { exercise_name, text: tmpText } })
      .unwrap()
      .then(() => {
        inputRef.current += 1;
        setTmpText('');
      });
  };

  let errorStatus;
  if (makeError && 'status' in makeError) {
    errorStatus = makeError.status;
  }

  return (
    <ReplyQuesttion>
      <Editor key={inputRef.current} height={200} value={tmpText} onChange={setTmpText} />
      <Button.Default
        className="btn--submit"
        onClick={tmpText !== '' ? () => handleMakeDiscuss() : undefined}
      >
        Gửi
      </Button.Default>
    </ReplyQuesttion>
  );
};

export default MakeExerciseDiscuss;
