import { ReactElement } from 'react';
import { routes } from 'routers/app.route';
import { useGetLoggedInUserQuery } from 'services/auth.service';
import { useDispatch } from 'react-redux';
import { setUser } from 'store/auth.slice';
import LayoutRoute from 'layouts/layout-components/LayoutRoute';
import LoadingSpin from './libraries/components/LoadingSpin';

const App = (): ReactElement => {
  const { isLoading, isError, data } = useGetLoggedInUserQuery();
  const dispatch = useDispatch();

  // Waiting check authentication
  if (isLoading) {
    return <LoadingSpin />;
  }

  // Render Login Page if not authenticated
  if (isError) {
    dispatch(setUser({}));
  }

  // Authenticated, update state in store
  if (data) {
    dispatch(setUser(data));
  }

  return <LayoutRoute routes={routes} />;
};

export default App;
