import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseOptions: FetchBaseQueryArgs = {
  baseUrl: process.env.REACT_APP_API_DOMAIN,

  // Send cookies
  credentials: 'include',

  // Mark requests as XMLHttpRequest
  prepareHeaders: (headers: Headers) => {
    headers.set('X-Requested-With', `XMLHttpRequest`);
    return headers;
  },
};

export const baseQuery = fetchBaseQuery({
  ...baseOptions,
});

export const baseLogin = fetchBaseQuery({
  ...baseOptions,
  baseUrl: process.env.REACT_APP_API_LOGIN,
});

export const mediaQuery = fetchBaseQuery({
  ...baseOptions,
  baseUrl: process.env.REACT_APP_MEDIA_API_DOMAIN,
});

export const mediaServerQuery = fetchBaseQuery({
  ...baseOptions,
  baseUrl: process.env.REACT_APP_API_MEDIA_SERVER,
});
