import { ReactElement } from 'react';
import styled from 'styled-components';
import { Avatar } from 'antd';
import { StarFilled } from '@ant-design/icons';

import UserDefaultAvatar from 'assets/images/avatar.png';
import { StudentRank } from 'models/class.model';

interface PropsType {
  student: StudentRank;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 20px;
  &:last-child {
    border-bottom: none;
  }
`;

const Rank = styled.div`
  width: 20px;
  font-size: 18px;
  font-weight: 600;
`;

const AvatarContainer = styled.div`
  width: 100px;
  text-align: center;
  .ant-avatar-image {
  }
`;

const StudentName = styled.div`
  font-size: 16px;
  font-weight: 700;
  width: calc(100% - 200px);
`;

const StudentRankContainer = styled.div`
  width: 80px;
  .anticon-star {
    color: #ffc107;
    margin-right: 8px;
  }
  .student-score {
    font-weight: 600;
  }
`;

const OtherRank = ({ student }: PropsType): ReactElement => {
  const { rank, full_name, average, avatar } = student;
  const src = avatar !== '' ? avatar : UserDefaultAvatar;
  return (
    <Wrapper>
      <Rank>{rank}</Rank>
      <AvatarContainer>
        <Avatar size={50} src={src} alt={full_name} />
      </AvatarContainer>
      <StudentName>{full_name}</StudentName>
      <StudentRankContainer>
        <StarFilled />
        <span className="student-score">{average.toFixed(1)}</span>
      </StudentRankContainer>
    </Wrapper>
  );
};

export default OtherRank;
