import { PlayCircleOutlined } from '@ant-design/icons';
import { Col, message, Row } from 'antd';
import Main from 'components/commons/Main';
import Button from 'libraries/components/commons/Button';
import ModalQuizHistory from 'pages/class-detail/practice/ModalQuizHistory';
import { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';

import { generatePath, useHistory, useParams } from 'react-router';
import { useGetCheckPracticeQuery, useGetClassSubjectQuery } from 'services/class.service';
import { changeQuizStatus } from 'store/practice.slice';

import styled from 'styled-components';

import ModalCustomExam from './ModalCustomExam';

const SubjectDetailStyle = styled.div`
  background: #e3f2fd;
  padding: 12px 16px;
  margin-bottom: 12px;
  border-radius: 6px;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 10%);
`;
const ColStyle = styled(Col)`
  display: flex;
  justify-content: end;
  @media (max-width: 576px) {
    justify-content: start;
    margin-top: 20px;
  }
`;

const SubjectDetail = ({ classSubject }: any): ReactElement => {
  const [modalData, setModalData] = useState<any>({ isModalVisible: false, subject_id: '' });
  const { classroom: cls } = useParams<{ classroom: string }>();
  const { data } = useGetCheckPracticeQuery({ classId: cls });
  const [NoClassSubject]: any = classSubject.filter((item: any) => item.id == '');
  const classSubjectCustom = classSubject.filter((item: any) => item.id !== '');
  return (
    <>
      {NoClassSubject && (
        <SubjectDetailStyle>
          <Row>
            <Col xs={24} sm={20}>
              <Row>
                <Col xs={24} sm={9}>
                  Tất cả các câu
                </Col>
                <Col xs={24} sm={5}>
                  Số câu hỏi: {NoClassSubject?.total_quiz}
                </Col>
                <Col xs={24} sm={5}>
                  Trọng số: 100 %
                </Col>
                <Col xs={24} sm={5}>
                  {NoClassSubject?.correct_rate < 50 ? (
                    <span style={{ color: 'red' }}>
                      {' '}
                      Hoàn thành: {NoClassSubject?.correct_rate}%
                    </span>
                  ) : NoClassSubject?.correct_rate >= 50 && NoClassSubject?.correct_rate < 80 ? (
                    <span style={{ color: '#ffba00' }}>
                      {' '}
                      Hoàn thành: {NoClassSubject?.correct_rate}%
                    </span>
                  ) : (
                    <span style={{ color: '#0275d8' }}>
                      {' '}
                      Hoàn thành: {NoClassSubject?.correct_rate}%
                    </span>
                  )}
                </Col>
              </Row>
            </Col>

            <ColStyle xs={24} sm={4}>
              <Button.Primary
                icon={<PlayCircleOutlined />}
                onClick={() => {
                  if (data?.id) {
                    message.warn('Bạn có bài thi đang làm dở, không thể chuyển sang bài mới !');
                    return;
                  }
                  setModalData({
                    ...modalData,
                    isModalVisible: true,
                    subject_id: '',
                    total_quiz: 20,
                  });
                }}
              >
                Luyện tập
              </Button.Primary>
            </ColStyle>
          </Row>
        </SubjectDetailStyle>
      )}

      {classSubjectCustom?.map((item: any, i: number) => (
        <SubjectDetailStyle key={i}>
          <Row>
            <Col xs={24} sm={20}>
              <Row>
                <Col xs={24} sm={9}>
                  {item.name}
                </Col>
                <Col xs={24} sm={5}>
                  Số câu hỏi: {item.total_quiz}
                </Col>
                <Col xs={24} sm={5}>
                  Trọng số: {item.weight}%
                </Col>
                <Col xs={24} sm={5}>
                  {item.correct_rate < 50 ? (
                    <span style={{ color: 'red' }}> Hoàn thành: {item.correct_rate}%</span>
                  ) : item.correct_rate >= 50 && item.correct_rate < 80 ? (
                    <span style={{ color: '#ffba00' }}> Hoàn thành: {item.correct_rate}%</span>
                  ) : (
                    <span style={{ color: '#0275d8' }}> Hoàn thành: {item.correct_rate}%</span>
                  )}
                </Col>
              </Row>
            </Col>

            <ColStyle xs={24} sm={4}>
              <Button.Primary
                icon={<PlayCircleOutlined />}
                onClick={() => {
                  if (data?.id) {
                    message.warn('Bạn có bài thi đang làm dở, không thể chuyển sang bài mới !');
                    return;
                  }
                  setModalData({
                    ...modalData,
                    isModalVisible: true,
                    subject_id: item.id,
                    total_quiz: item.total_quiz,
                  });
                }}
              >
                Luyện tập
              </Button.Primary>
            </ColStyle>
          </Row>
        </SubjectDetailStyle>
      ))}
      <ModalCustomExam
        subject_id={modalData.subject_id}
        classSubject={classSubjectCustom}
        isModalVisible={modalData.isModalVisible}
        handleCancel={() => setModalData({ ...modalData, isModalVisible: false })}
        total_quiz={modalData.total_quiz}
      />
    </>
  );
};

const ExamPage = (): ReactElement => {
  const { classroom: cls } = useParams<{ classroom: string }>();
  const { data: classSubject } = useGetClassSubjectQuery({ id: cls });

  const { data } = useGetCheckPracticeQuery({ classId: cls });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const question = localStorage.getItem('question');
  const dispatch = useDispatch();
  const history = useHistory();
  const handleClick = () => {
    dispatch(changeQuizStatus(true));
    if (!question) {
      history.push(generatePath(`practice/${data?.id}?question=1`));
    } else {
      history.push(generatePath(`practice/${data?.id}?question=${question}`));
    }
  };

  return (
    <Main>
      <Row gutter={[0, 30]}>
        <div style={{ display: 'flex' }}>
          {/* <Button.Primary
            type="primary"
            icon={<PlayCircleOutlined />}
            style={{ marginRight: '12px', height: '40px', backgroundColor: '#00C48C' }}
          >
            Luyện thi
          </Button.Primary> */}
          {data?.id && (
            <Button.Primary style={{ height: '40px', marginRight: '12px' }} onClick={handleClick}>
              Tiếp tục làm bài
            </Button.Primary>
          )}
          <Button.Outline
            style={{ height: '40px', marginRight: '12px' }}
            onClick={() => setIsModalVisible(true)}
          >
            Lịch sử
          </Button.Outline>
        </div>
        <Col xs={24}>{classSubject && <SubjectDetail classSubject={classSubject} />}</Col>
      </Row>
      <ModalQuizHistory
        isModalVisible={isModalVisible}
        handleCancel={() => setIsModalVisible(false)}
      />
    </Main>
  );
};

export default ExamPage;
