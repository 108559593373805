import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ReactElement } from 'react';

import { generatePath, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { classroom } from 'routers/routeNames';
import { useGetPracticeHistoryQuery } from 'services/class.service';
import { formatDate } from 'utils/timeDistanceToNow';

const TableQuizzHistory = (): ReactElement => {
  const { classroom: cls } = useParams<{ classroom: string }>();

  const { data: historyPractice } = useGetPracticeHistoryQuery({
    classId: cls,
  });

  const columns: ColumnsType<any> = [
    {
      width: '5%',
      title: 'Ngày làm',
      dataIndex: 'start_time',
      key: 'start_time',
      render: (start_time, item) =>
        start_time !== '' ? formatDate(start_time, 'dd/MM/yyyy H:mm:ss') : '',
    },
    {
      width: '5%',
      title: 'Bài làm',
      dataIndex: 'id',
      key: 'id',
      render: (id, item) => (
        <Link
          to={
            generatePath(classroom.practice, {
              classroom: cls,
              practiceId: id,
            }) + '?question=1'
          }
        >
          Xem bài làm
        </Link>
      ),
    },
    {
      width: '5%',
      title: 'Chủ đề',
      dataIndex: 'subjects',
      key: 'subjects',
      render: (subjects, item) =>
        subjects.map((item: any, i: number) =>
          i < subjects.length - 1 ? <span key={i}>{item}, </span> : <span key={i}>{item}</span>,
        ),
    },
    {
      width: '5%',
      title: 'Trả lời đúng /Tổng',
      dataIndex: 'mark',
      key: 'mark',
      render: (mark, item) => <span>{mark}</span>,
    },
  ];

  return (
    <>
      {historyPractice && (
        <Table
          columns={columns}
          dataSource={historyPractice}
          pagination={{ pageSize: 8 }}
          rowKey="order_id"
          bordered
        />
      )}
    </>
  );
};

export default TableQuizzHistory;
