import { ReactElement } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { IContentMeta, IContentRoute, ILayoutContentRoute } from 'models/route.model';

import useTitle from 'utils/useTitle';
import NotFoundPage from 'pages/not-found/NotFoundPage';

const HandleDocumentTitle = (props: IContentMeta) => {
  useTitle(props.title);

  return <props.content />;
};

const ContentRouteWithoutTransition = ({ routes }: { routes: IContentRoute[] }) => {
  return (
    <Switch>
      {routes.map(({ path, meta }: IContentRoute) => (
        <Route key={path} path={path} exact>
          <HandleDocumentTitle {...meta} />
        </Route>
      ))}

      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

const ContentRoute = ({ routes, useTransition }: ILayoutContentRoute): ReactElement => {
  if (!useTransition) {
    return <ContentRouteWithoutTransition routes={routes} />;
  }

  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition key={location.pathname} classNames="fade" timeout={{ enter: 600, exit: 0 }}>
        <Switch>
          {routes.map(({ path, meta }: IContentRoute) => (
            <Route key={path} path={path} exact>
              <HandleDocumentTitle {...meta} />
            </Route>
          ))}

          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

ContentRoute.defaultProps = {
  useTransition: true,
};

export default ContentRoute;
