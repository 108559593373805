import { ReactElement } from 'react';
import { Breadcrumb as AntBreadcrumb } from 'antd';
import { HomeFilled } from '@ant-design/icons';
import { withRouter, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledBreadcrumb = styled(AntBreadcrumb)`
  margin-bottom: 20px;
  padding-left: 20px;
  font-size: 18px;
`;

interface RouteMap {
  [key: string]: string;
}

interface BreadcrumbProps extends RouteComponentProps {
  routes: RouteMap;
}

const Breadcrumb = withRouter(({ location, routes }: BreadcrumbProps): ReactElement => {
  const pathSnippets = location.pathname.split('/').filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.slice(1).map((_, index, arr) => {
    const url = `/${pathSnippets[0]}/${arr.slice(0, index + 1).join('/')}`;
    return (
      <AntBreadcrumb.Item key={url}>
        {index !== arr.length - 1 ? <Link to={url}>{routes[url]}</Link> : routes[url]}
      </AntBreadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <AntBreadcrumb.Item key={'/'}>
      <Link to="/">
        <HomeFilled style={{ fontSize: '20px' }} />
      </Link>
    </AntBreadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return <StyledBreadcrumb>{breadcrumbItems}</StyledBreadcrumb>;
});

export default Breadcrumb;
