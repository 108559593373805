import styled from 'styled-components';

const SubjectThumbnail = styled.div`
  width: 100%;
  padding-top: 61%;
  position: relative;
  background-color: ##e5e5e5;
  overflow: hidden;

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default SubjectThumbnail;
