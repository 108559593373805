import { ReactElement, useEffect, useState } from 'react';
import { Progress as AntdProgress, ProgressProps } from 'antd';

const Progress = (props: ProgressProps): ReactElement => {
  const [percent, setPercent] = useState(0);

  useEffect((): (() => void) => {
    let timer: NodeJS.Timeout;
    if (props.percent && percent <= props.percent) {
      const random = Math.floor(Math.random() * 10) + 1;
      const up =
        percent + random < props.percent ? random : props.percent - percent;

      timer = setTimeout(() => setPercent(percent + up), 30);
    }

    return () => timer && clearTimeout(timer);
  });

  return <AntdProgress {...props} percent={percent} />;
};

Progress.defaultProps = {
  strokeWidth: 10,
};

export default Progress;
