import { Col, message, Row } from 'antd';
import Breadcrumb from 'components/commons/Breadcrumb';
import EmptyData from 'components/commons/EmptyData';
import ShowNotFound from 'components/commons/ShowNotFound';
import ContentRoute from 'layouts/layout-components/ContentRoute';
import Button from 'libraries/components/commons/Button';
import { ClassList } from 'models/class.model';
import CountDownTimer from 'pages/class-detail/practice/CountDownTimer';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useParams } from 'react-router';
import { routes } from 'routers/classroom.route';
import { classroom } from 'routers/routeNames';
import {
  useGetClassByIdQuery,
  useGetClassExercisesQuery,
  useGetPracticeQuery,
  useSendDurationLeftMutation,
} from 'services/class.service';
import { changeQuizStatus, selectDurationLeft, selectQuizStatus } from 'store/practice.slice';
import styled from 'styled-components';
const SpanMarginRight = styled.span`
  margin-right: 8px;
`;
const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;
const TimeFlexContainer = styled.div`
  h2 {
    margin: 0;
    margin-right: 24px;
    display: none;
  }
  display: flex;
  justify-content: space-between;
  align-item: center;
  flex-wrap: wrap;
  @media (max-width: 576px) {
    margin-top: 12px;
    width: 100%;
    h2 {
      display: block;
    }
  }
`;
const HeaderContainer = styled.div`
  padding: 0 40px;
  font-size: 16px;
  @media (max-width: 576px) {
    padding: 0 14px;
  }
`;
const GetPractice = (): ReactElement => {
  const { classroom: cls } = useParams<{ classroom: string }>();
  const [sendDurationLeft] = useSendDurationLeftMutation();
  //   2 dong code 2 dưới để lấy practiceId do không lấy dc qua useParams
  const arrPathname = window.location.pathname.split('/');
  const practiceId = arrPathname[arrPathname.length - 1];
  const { data: practice, refetch } = useGetPracticeQuery({ classId: cls, practiceId });
  const { data, isError, error } = useGetClassByIdQuery(cls);
  const { isError: getExerciseError } = useGetClassExercisesQuery(cls);
  const [durationTime, setDurationTime] = useState({ hour: 0, minute: 0, second: 0 });
  const duration_left = useSelector(selectDurationLeft);
  const quiz_status = useSelector(selectQuizStatus);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (!practice) return;
  //   setDurationTime({
  //     ...durationTime,
  //     hour: Math.floor(practice.duration / 3600),
  //     minute: Math.floor((practice.duration % 3600) / 60),
  //     second: practice.duration % 60,
  //   });
  // }, [practice]);

  const classinfo = data ? data : ({} as ClassList);

  const breadcrumbs = {
    [generatePath(classroom.index, { classroom: cls })]: classinfo.name
      ? classinfo.name
      : 'Lớp học của tôi',
  };

  if (isError || getExerciseError) {
    return <ShowNotFound error={error} />;
  }
  const handlePauseTime = () => {
    // if (!duration_left) return;
    dispatch(changeQuizStatus(false));
    sendDurationLeft({
      classId: cls,
      practiceId,
      duration_left: duration_left,
    })
      .unwrap()
      .then((data: any) => {
        message.success('Tạm dừng bài làm thành công!');
      })
      .catch((error: any) => {
        message.error(error.data);
      });
  };
  const handleContinuteTime = () => {
    dispatch(changeQuizStatus(true));
    message.success('Tiếp tục làm bài!');
    refetch();
  };

  return (
    <Row gutter={20}>
      <Col xs={24} lg={24} xl={24}>
        <>
          {classinfo.id ? <Breadcrumb routes={breadcrumbs} /> : <EmptyData />}
          <HeaderContainer>
            <Row>
              <Col xs={24} sm={12}>
                {practice && (
                  <h2 style={{ fontSize: '20px' }}>
                    {practice?.subjects?.map((item: string, i: number) =>
                      i < practice?.subjects?.length - 1 ? (
                        <span key={i}>{item},</span>
                      ) : (
                        <span key={i}>{item}</span>
                      ),
                    )}
                  </h2>
                )}
              </Col>
              <Col xs={24}>
                <FlexContainer>
                  <div>
                    {<SpanMarginRight>Tổng: {practice?.quizzes?.length} câu hỏi</SpanMarginRight>}
                    {practice?.is_submit == false && practice?.type == 'time' && (
                      <SpanMarginRight>Thời gian:</SpanMarginRight>
                    )}
                    {durationTime.hour != 0 && (
                      <SpanMarginRight>{durationTime.hour} Giờ</SpanMarginRight>
                    )}
                    {durationTime.minute != 0 && (
                      <SpanMarginRight>{durationTime.minute} Phút</SpanMarginRight>
                    )}
                    {durationTime.second != 0 && <span>{durationTime.second} Giây</span>}
                  </div>
                  <TimeFlexContainer>
                    {practice?.end_time && practice?.is_submit == false && (
                      <CountDownTimer endTime={practice?.end_time} />
                    )}
                    {(practice?.is_submit == false && practice?.type) == 'time' && quiz_status && (
                      <Button.Warning
                        style={{ background: '#FF9457', height: '40px', padding: '0 24px' }}
                        onClick={handlePauseTime}
                      >
                        Tạm dừng
                      </Button.Warning>
                    )}
                    {practice?.type == 'time' && !quiz_status && (
                      <Button.Primary
                        style={{ height: '40px', padding: '0 24px' }}
                        onClick={handleContinuteTime}
                      >
                        Tiếp tục
                      </Button.Primary>
                    )}
                  </TimeFlexContainer>
                </FlexContainer>
              </Col>
            </Row>
          </HeaderContainer>
        </>

        <ContentRoute routes={routes} />
      </Col>
    </Row>
  );
};

export default GetPractice;
