import { ReactElement } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';

import LearningStatus from 'components/commons/LearningState';

const HeaderRow = styled(Row)`
  && {
    align-items: center;
    .exercise-status {
      span {
        margin-left: 10px;
      }
    }
  }
`;

interface PropsType {
  title: string;
  status_submit: boolean;
  status_mark: boolean;
  mark: number | string;
  assignment_type: string;
  can_submit: boolean;
}

const ExerciseCollapseTitle = ({
  title,
  status_submit,
  status_mark,
  mark,
  assignment_type,
  can_submit,
}: PropsType): ReactElement => {
  return (
    <HeaderRow>
      <Col xs={24} sm={24} xl={10}>
        <span className="exercise-title">{title}</span>
      </Col>
      <Col xs={24} sm={24} xl={3}>
        <span style={{ color: '#0275d8' }}> {assignment_type} </span>
      </Col>
      <Col xs={24} sm={12} xl={5}>
        <span className="exercise-status">
          Trạng thái:
          {status_submit ? (
            <LearningStatus.Success as="span">Đã nộp</LearningStatus.Success>
          ) : can_submit ? (
            <LearningStatus.Primary as="span">Đang thu</LearningStatus.Primary>
          ) : (
            <LearningStatus.Danger as="span">Chưa nộp</LearningStatus.Danger>
          )}
        </span>
      </Col>
      <Col xs={24} sm={12} xl={5}>
        <span className="exercise-score">
          Điểm:&nbsp;
          {status_mark ? (
            <LearningStatus.Success as="span">{mark}</LearningStatus.Success>
          ) : (
            <LearningStatus.Danger as="span">Chưa chấm</LearningStatus.Danger>
          )}
        </span>
      </Col>
    </HeaderRow>
  );
};

export default ExerciseCollapseTitle;
