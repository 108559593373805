import { ReactElement } from 'react';
import { useGetClassesQuery } from 'services/class.service';

import Main from 'components/commons/Main';
import SubjectList from './subject/SubjectList';
import EmptyClass from 'components/commons/EmptyClass';
import LoadingSpin from 'libraries/components/LoadingSpin';
import Tabs from 'components/commons/Tabs';
import { ClassList } from 'models/class.model';
import ShowNotFound from 'components/commons/ShowNotFound';

const { TabPane } = Tabs;

const GetClass = (): ReactElement => {
  const { data, isLoading, isError, error } = useGetClassesQuery();

  const classList = data ? data : ([] as ClassList[]);

  if (isError) {
    return (
      <Main>
        <ShowNotFound error={error} />
      </Main>
    );
  }

  return (
    <Main>
      {isLoading ? (
        <LoadingSpin text="Đang lấy dữ liệu lớp học" />
      ) : (
        <>
          {classList.length > 0 ? (
            <Tabs defaultActiveKey="1">
              <TabPane tab="Lớp đang học" key="1">
                <SubjectList list={classList.filter((cls) => cls.status === 'active')} />
              </TabPane>
              <TabPane tab="Lớp sắp khai giảng" key="2">
                <SubjectList list={classList.filter((cls) => cls.status === 'scheduled')} />
              </TabPane>
              <TabPane tab="Lớp đã kết thúc" key="4">
                <SubjectList list={classList.filter((cls) => cls.status === 'completed')} />
              </TabPane>
            </Tabs>
          ) : (
            <EmptyClass />
          )}
        </>
      )}
    </Main>
  );
};

export default GetClass;
