import { ReactElement } from 'react';

import User from '../../layout-components/UserAvatarSidebar';
import Nav from './Nav';

const Sider = (): ReactElement => {
  return (
    <aside>
      <User />
      <Nav />
    </aside>
  );
};

export default Sider;
