import { StudentRank } from 'models/class.model';
import { ReactElement } from 'react';
import styled from 'styled-components';
import TopRankInfo from './TopRankInfo';

interface StudentsListProp {
  students: StudentRank[];
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 20px;
  padding-top: 52px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  .top__item {
    position: relative;
  }
`;

const TopRank = ({ students }: StudentsListProp): ReactElement => {
  const firstRank = students.filter((student) => student.rank === 1);
  const secondRank = students.filter((student) => student.rank === 2);
  const thirdRank = students.filter((student) => student.rank === 3);
  return (
    <Wrapper>
      <div className="top__item top__item-top-2">
        {secondRank.length > 0 && <TopRankInfo student={secondRank[0]} />}
      </div>
      <div className="top__item top__item-top-1">
        {firstRank.length > 0 && <TopRankInfo student={firstRank[0]} />}
      </div>
      <div className="top__item top__item-top-3">
        {thirdRank.length > 0 && <TopRankInfo student={thirdRank[0]} />}
      </div>
    </Wrapper>
  );
};

export default TopRank;
