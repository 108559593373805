import LogoImage from 'assets/images/logo.png';
import { ReactElement } from 'react';
import styled from 'styled-components';

const LogoWrapper = styled.div`
  max-width: 120px;
`;

const Logo = (): ReactElement => {
  return (
    <LogoWrapper>
      <a href="https://techmaster.vn/">
        <img src={LogoImage} alt="Techmaster Việt Nam" />
      </a>
    </LogoWrapper>
  );
};

export default Logo;
