import { ReactElement } from 'react';

import NotAuthorized from 'components/commons/NotAuthorized';
import checkUserRole from 'utils/checkUserRole';
import { highestRoles, ROLE_STUDENT, ROLE_TRAINER } from 'libraries/utils/globalVariable';
import GetList from './GetList';

const CoursePage = (): ReactElement => {
  const checkRole = checkUserRole([...highestRoles, ROLE_STUDENT, ROLE_TRAINER]);

  if (!checkRole) {
    return <NotAuthorized />;
  }

  return <GetList />;
};

export default CoursePage;
