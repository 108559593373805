import styled from 'styled-components';

const Main = styled.main`
  & {
    font-family: Mulish;
    font-size: 16px;
    padding: 20px;
    border-radius: 5px;
  }
`;

export default Main;
