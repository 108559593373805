import { ReactElement } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import 'antd/dist/antd.min.css';
import styled from 'styled-components';
import { Transaction } from 'models/user.model';
import { formatDate } from 'utils/timeDistanceToNow';
import { orderStatusValues } from 'libraries/utils/globalVariable';
import Span from 'libraries/components/commons/Span';

const TransactionContainer = styled.div`
  font-size: 16px;

  .trans-success {
    font-weight: 550;
    color: green;
  }
  .trans-fail {
    font-weight: 550;
    color: red;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    overflow-x: scroll;
  }
`;

interface PropsType {
  transactionsList: Transaction[];
}

const columns: ColumnsType<Transaction> = [
  {
    title: 'ID đơn hàng',
    dataIndex: 'order_id',
    render: (order_id) => <a href="#">{order_id}</a>,
  },
  {
    title: 'Tên khoá học',
    dataIndex: 'item',
  },
  {
    title: 'Học phí',
    dataIndex: 'total',
    sorter: {
      compare: (a, b) => a.total - b.total,
    },
    render: (total) =>
      new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
      }).format(total),
  },
  {
    title: 'Ngày đăng kí',
    dataIndex: 'order_at',
    render: (date) => <span>{formatDate(date)}</span>,
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: (status) => {
      if (status !== '') {
        for (let i = 0; i < orderStatusValues.length; i++) {
          const item = orderStatusValues[i];
          if (item.value === status) {
            return (
              <Span
                style={{
                  wordWrap: 'break-word',
                  wordBreak: 'break-word',
                }}
                className={item.className}
              >
                {item.label}
              </Span>
            );
          }
        }
      }
      return '';
    },
  },
];

const TransactionItem = ({ transactionsList }: PropsType): ReactElement => {
  return (
    <TransactionContainer>
      <Table
        columns={columns}
        dataSource={transactionsList}
        bordered
        pagination={false}
        rowKey="order_id"
      />
    </TransactionContainer>
  );
};

export default TransactionItem;
