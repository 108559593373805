import { CheckOutlined, CloseOutlined, FlagOutlined } from '@ant-design/icons';
import { Checkbox, Col, Divider, Form, message, Radio, RadioChangeEvent, Row, Space } from 'antd';
import Button from 'libraries/components/commons/Button';
import ShowMarkdown from 'libraries/components/markdown/ShowMarkdown';
import QuizGroup from 'pages/learn/quiz/QuizGroup';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import {
  selectPractice,
  selectQuizStatus,
  selectTimeSubmit,
  updateIsSubmit,
  updateIsTimeSubmit,
  updateQuizAfterSelect,
} from 'store/practice.slice';
import styled from 'styled-components';

import {
  useGetPracticeQuery,
  useSendAnswerQuizMutation,
  useSubmitPracticeMutation,
} from 'services/class.service';
import ModalReportQuiz from './ModalReportQuiz';
const QuizzContent = styled.div`
  padding: 0 24px;

  h2 {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
  }
  .Is_single {
    font-size: 18px;
    margin-left: 12px;
    background-color: #ccc;
    padding: 4px 8px;
  }
`;
const FlexStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const QuizAnwser = styled.div``;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 455px) {
    flex-direction: column;
  }
  button {
    margin-top: 12px;
    padding: 6px 24px;
    height: 40px;
  }
`;

const getAnswertrue = (arrayAnswer: any) => {
  return arrayAnswer?.filter((item: any) => item.is_true);
};
interface props {
  quizItem: any;
  numberQuesiton: number;
}
const SubSubQuizContent = ({ quizItem, numberQuesiton }: props): ReactElement => {
  const [values, setValues] = useState<any>(quizItem.answers_choose);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const { classroom: cls } = useParams<{ classroom: string }>();
  const { practiceId: practice_id } = useParams<{ practiceId: string }>();
  const quiz_status = useSelector(selectQuizStatus);

  const { end_time, quizzes, is_submit } = useSelector(selectPractice);
  const [sendAnswerQuiz] = useSendAnswerQuizMutation();
  const { is_single, content, answers, id, answers_choose, explannation, is_true } = quizItem;

  const onChangeCheckBox = (checkedValues: any) => {
    setValues(checkedValues);
    sendAnswerQuiz({
      class_id: cls,
      practice_id,
      data: {
        answers: checkedValues,
        quiz_id: id,
      },
    })
      .unwrap()
      .then((data: any) => {
        dispatch(
          updateQuizAfterSelect({ quizzesId: id, has_answer: true, answers_choose: checkedValues }),
        );
      })
      .catch((error: any) => {
        message.error(error.data);
      });
  };
  const onChange = (e: RadioChangeEvent) => {
    setValues([e.target.value]);
    sendAnswerQuiz({
      class_id: cls,
      practice_id,
      data: {
        answers: [e.target.value],
        quiz_id: id,
      },
    })
      .unwrap()
      .then((data: any) => {
        dispatch(
          updateQuizAfterSelect({
            quizzesId: id,
            has_answer: true,
            answers_choose: [e.target.value],
          }),
        );
      })
      .catch((error: any) => {
        message.error(error.data);
      });
  };

  return (
    <QuizzContent>
      <FlexStyle>
        <h2>Câu {numberQuesiton}</h2>
      </FlexStyle>

      <div>
        <ShowMarkdown markdown={content} />
      </div>
      <Divider />
      <QuizAnwser>
        {is_single ? (
          <Form>
            <Form.Item name="answers">
              <QuizGroup.Radio>
                <Radio.Group
                  onChange={onChange}
                  value={values ? values[0] : false}
                  style={{ display: 'block' }}
                >
                  <Space direction="vertical">
                    {answers?.map((item: any) => (
                      <Radio
                        value={item.id}
                        key={item.id}
                        className={
                          is_submit && values?.includes(item.id) && is_true
                            ? 'right-answer'
                            : is_submit && values?.includes(item.id) && !is_true
                            ? 'wrong-answer'
                            : ''
                        }
                        disabled={is_submit || !quiz_status}
                      >
                        <ShowMarkdown markdown={item.content} />
                        <CheckOutlined />
                        <CloseOutlined />
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </QuizGroup.Radio>
            </Form.Item>
          </Form>
        ) : (
          <Form>
            <Form.Item className="multiple" name="answerCheckboxChosen">
              <QuizGroup.Checkbox style={{ width: '100%', display: 'block' }}>
                <Checkbox.Group
                  style={{ width: '100%', display: 'block' }}
                  value={values ? values : []}
                  onChange={onChangeCheckBox}
                >
                  <Row>
                    {answers?.map((item: any) => (
                      <Col span={24} key={item.id}>
                        <Checkbox
                          checked={true}
                          value={item.id}
                          className={
                            is_submit && values?.includes(item.id) && is_true
                              ? 'right-answer'
                              : is_submit && values?.includes(item.id) && !is_true
                              ? 'wrong-answer'
                              : ''
                          }
                          disabled={is_submit || !quiz_status}
                        >
                          <ShowMarkdown markdown={item.content} />
                          <CheckOutlined />
                          <CloseOutlined />
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </QuizGroup.Checkbox>
            </Form.Item>
          </Form>
        )}
      </QuizAnwser>
      <Divider />
      {is_submit && (
        <>
          <div>
            <h4>Đáp án:</h4>

            {getAnswertrue(answers)?.map((item: any, i: number) => (
              <ShowMarkdown key={i} markdown={item.content} />
            ))}
          </div>
          {explannation != '' && (
            <div>
              <h4>Giải thích:</h4>

              <ShowMarkdown markdown={explannation} />
            </div>
          )}
          <Divider />
        </>
      )}

      <ModalReportQuiz
        isModalVisible={isModalVisible}
        handleCancel={() => setIsModalVisible(false)}
      />
    </QuizzContent>
  );
};

export default SubSubQuizContent;
