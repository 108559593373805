import { ReactElement } from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface PropsType {
  subTitle: string;
  returnLink: string;
  returnTitle: string;
  title: string;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotFound = ({ subTitle, title, returnLink, returnTitle }: PropsType): ReactElement => {
  return (
    <Container>
      <Result
        status="404"
        title={title}
        subTitle={subTitle}
        extra={
          <Link to={returnLink}>
            <Button type="primary">{returnTitle}</Button>
          </Link>
        }
      />
    </Container>
  );
};

NotFound.defaultProps = {
  subTitle: 'Xin lỗi trang bạn đang truy cập không tồn tại',
  returnLink: '/',
  returnTitle: 'Quay về trang chủ',
  title: '404',
};

export default NotFound;
