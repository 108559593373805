import moment from 'moment';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectQuizStatus, updateDurationLeft, updateIsTimeSubmit } from 'store/practice.slice';

interface props {
  endTime: string;
}
const CountDownTimer = ({ endTime }: props): ReactElement => {
  const [timeRemaining, setTimeRemaining] = useState(
    moment.duration(moment(endTime).diff(moment())),
  );
  const dispatch = useDispatch();
  const quiz_status = useSelector(selectQuizStatus);

  useEffect(() => {
    if (!quiz_status) return;
    const intervalId = setInterval(() => {
      setTimeRemaining(moment.duration(moment(endTime).diff(moment())));
      dispatch(
        updateDurationLeft(Math.round(moment.duration(moment(endTime).diff(moment())).asSeconds())),
      );
    }, 1000);

    return () => clearInterval(intervalId);
  }, [endTime, quiz_status]);

  if (
    endTime != '0001-01-01T00:00:00Z' &&
    moment.duration(moment(endTime).diff(moment())).asSeconds() <= 0
  ) {
    dispatch(updateIsTimeSubmit(true));
  }
  if (timeRemaining.asSeconds() <= 0) {
    return <></>;
  }
  return (
    <h2 style={{ color: '#0275d8', fontSize: '24px', marginBottom: 0 }}>
      {timeRemaining.hours()}h {timeRemaining.minutes()}m {timeRemaining.seconds()}s
    </h2>
  );
};
export default CountDownTimer;
