import Modal from 'libraries/components/commons/Modal';
import { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetPracticeHistoryQuery } from 'services/class.service';

import TableQuizzHistory from './TableQuizzHistory';

interface Props {
  isModalVisible: boolean;
  handleCancel: any;
}

const ModalReportQuiz = ({ handleCancel, isModalVisible }: Props): ReactElement => {
  const onCloseModal = () => {
    handleCancel();
  };

  return (
    <>
      <Modal
        title="Báo cáo"
        visible={isModalVisible}
        onCancel={onCloseModal}
        footer={null}
        width={500}
        centered
      ></Modal>
    </>
  );
};

export default ModalReportQuiz;
