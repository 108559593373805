import { Col, Row } from 'antd';

import { Collapse, Panel } from 'components/commons/Collapse';
import EmptyData from 'components/commons/EmptyData';
import { Exercise } from 'models/exercise.model';
import ExerciseCollapseTitle from 'pages/class-detail/exercise/ExerciseCollapseTitle';
import ExerciseItem from 'pages/class-detail/exercise/item/ExerciseItem';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { selectExercises } from 'store/class.slice';
import Main from '../Main';
interface CollapseTitleProps {
  exercise: Exercise;
}

const CollapseTitle = ({ exercise }: CollapseTitleProps): ReactElement => {
  const { history, title, type: assignment_type, can_submit } = exercise;
  const historyLength = history.length;
  const status_submit = historyLength === 0 ? false : true;
  const status_mark = historyLength === 0 ? false : history[0].status_mark;
  const mark = historyLength === 0 ? '--' : history[0].status_mark ? history[0].mark : '--';

  return (
    <ExerciseCollapseTitle
      title={title}
      status_submit={status_submit}
      status_mark={status_mark}
      mark={mark}
      assignment_type={assignment_type}
      can_submit={can_submit}
    />
  );
};

const getExerciseId = () => {
  const { search } = useLocation();
  const id = useMemo(() => new URLSearchParams(search), [search]).get('id');

  return id !== null ? id : '';
};

const ExercisePage = (): ReactElement => {
  const currentExercise = getExerciseId();
  const exercises = useSelector(selectExercises);
  const scrollExerciseIntoView = (behavior: ScrollBehavior, block: ScrollLogicalPosition) => {
    const element = document.getElementById(`exercise-${currentExercise}`);
    if (element) element.scrollIntoView({ behavior, block });
  };
  useEffect(() => {
    if (currentExercise !== '') {
      setTimeout(() => {
        scrollExerciseIntoView('smooth', 'center');
      }, 500);
    }
  }, [currentExercise]);

  return (
    <Main>
      <Row gutter={[0, 30]}>
        <Col xs={24}>
          <Collapse expandIconPosition="end" accordion defaultActiveKey={[currentExercise]}>
            {exercises.length > 0 ? (
              exercises.map((exercise: Exercise, i: number) => {
                return (
                  <React.Fragment key={exercise.id}>
                    {i == 0 && (
                      <b
                        style={{
                          paddingBottom: '24px',
                          display: 'block',
                        }}
                      >
                        {exercise.lesson_name}
                      </b>
                    )}

                    {i != 0 && exercises[i - 1].lesson_name != exercise.lesson_name ? (
                      <b
                        style={{
                          paddingBottom: '24px',
                          display: 'block',
                        }}
                      >
                        {exercise.lesson_name}
                      </b>
                    ) : (
                      ''
                    )}

                    <Panel
                      header={<CollapseTitle exercise={exercise} />}
                      key={exercise.id}
                      id={`exercise-${exercise.id}`}
                      style={{ marginLeft: '32px' }}
                    >
                      <ExerciseItem key={exercise.id} exercise={exercise} />
                    </Panel>
                  </React.Fragment>
                );
              })
            ) : (
              <EmptyData title="Bạn chưa có bài tập nào" />
            )}
          </Collapse>
        </Col>
      </Row>
    </Main>
  );
};

export default ExercisePage;
