import { Modal as AntModal } from 'antd';
import styled from 'styled-components';

const Modal = styled(AntModal)`
  .ant-modal-header {
    border-bottom: none;
    text-align: center;
    .ant-modal-title {
      font-size: 20px;
      font-weight: 700;
      max-width: 95%;
      @media screen and (min-width: 1200px) {
        font-size: 24px;
      }
    }
  }
  .ant-modal-footer {
    border-top: none;
  }
`;

export default Modal;
