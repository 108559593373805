import { Button } from 'antd';
import Editor from 'libraries/editor';
import { ReactElement } from 'react';

interface CommentEditorProps {
  value: string;
  handleChange: (e: string) => void;
  handleSubmit: () => void;
  handleCancel?: () => void;
  replyComment?: boolean;
}

export const CommentEditor = ({
  value,
  handleChange,
  handleSubmit,
  handleCancel,
  replyComment,
}: CommentEditorProps): ReactElement => {
  console.log('CommentEditor rerendering with value:', value);
  return (
    <>
      <Editor height={200} value={value} onChange={handleChange}></Editor>
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '8px', gap: '12px' }}>
        {replyComment && (
          <Button type="primary" danger onClick={handleCancel}>
            Huỷ
          </Button>
        )}
        <Button type="primary" onClick={() => {
          handleSubmit();
          handleChange(''); // Clear the input value
        }}>
          Bình luận
        </Button>
      </div>
    </>
  );
};

export const EditCommentEditor = ({
  value,
  handleChange,
  handleSubmit,
  handleCancel,
}: CommentEditorProps): ReactElement => {
  return (
    <>
      <Editor height={150} value={value} onChange={handleChange}></Editor>
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '8px', gap: '8px' }}>
        <Button type="primary" danger onClick={handleCancel}>
          Huỷ bỏ
        </Button>
        <Button type="primary" onClick={handleSubmit}>
          Thay đổi
        </Button>
      </div>
    </>
  );
};
