import { Col, Row } from 'antd';
import Breadcrumb from 'components/commons/Breadcrumb';
import EmptyData from 'components/commons/EmptyData';
import NotFound from 'components/commons/NotFound';
import ShowNotFound from 'components/commons/ShowNotFound';
import ContentRoute from 'layouts/layout-components/ContentRoute';
import Drawer from 'layouts/layout-components/Drawer';
import MediaQuery from 'layouts/layout-components/MediaQuery';
import { ClassList } from 'models/class.model';
import { ReactElement } from 'react';
import { generatePath, useParams } from 'react-router';
import { routes } from 'routers/classroom.route';
import { classroom } from 'routers/routeNames';
import { useGetClassByIdQuery, useGetClassExercisesQuery } from 'services/class.service';
import Sidebar from './sidebar';
import Tabs from './tabs/Tabs';

const GetClass = (): ReactElement => {
  const { classroom: cls } = useParams<{ classroom: string }>();
  const { data, isError, error } = useGetClassByIdQuery(cls);
  const { isError: getExerciseError } = useGetClassExercisesQuery(cls);

  const classinfo = data ? data : ({} as ClassList);

  const breadcrumbs = {
    [generatePath(classroom.index, { classroom: cls })]: classinfo.name
      ? classinfo.name
      : 'Lớp học của tôi',
    [generatePath(classroom.exercise, { classroom: cls })]: 'Bài tập',
    [generatePath(classroom.exam, { classroom: cls })]: 'Luyện thi',
    [generatePath(classroom.ranking, { classroom: cls })]: 'Bảng xếp hạng',
    [generatePath(classroom.reviews, { classroom: cls })]: 'Đánh giá khóa học',
    [generatePath(classroom.info, { classroom: cls })]: 'Thông tin chung',
  };

  if (isError || getExerciseError) {
    return <ShowNotFound error={error} />;
  }

  return (
    <Row gutter={20}>
      <Col xs={0} lg={7} xl={6} xxl={5} className="fade left-sidebar">
        <Sidebar />

        <MediaQuery on={['xs', 'sm', 'md']}>
          <Drawer>
            <Sidebar />
          </Drawer>
        </MediaQuery>
      </Col>

      <Col xs={24} lg={17} xl={18} xxl={19}>
        {classinfo.id ? (
          <>
            <Breadcrumb routes={breadcrumbs} />

            <MediaQuery on={['lg', 'xl', 'xxl']}>
              <Tabs />
            </MediaQuery>
          </>
        ) : (
          <EmptyData />
        )}

        <ContentRoute routes={routes} />
      </Col>
    </Row>
  );
};

export default GetClass;
