import { ReactElement } from 'react';
import { Menu } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import NavLink from 'components/commons/NavLink';
import Icon from 'components/commons/Icon';

import { home } from 'routers/routeNames';
import { useLogoutMutation } from 'services/auth.service';

const Dropdown = styled(Menu)`
  &&& {
    margin-top: 10px;
    padding: 5px 0;
    background: #ffffff;
    border: 0;
    box-shadow: 0 2px 3px #d5d7da;
  }
`;

const DropdownItem = styled(Menu.Item)`
  &&& {
    padding-left: 25px;
    padding-right: 25px;
  }

  &&&.ant-menu-item-active {
    background: #e6f7ff;
  }
`;

const UserDropdown = (): ReactElement => {
  const [logout] = useLogoutMutation();

  const handleLogout = () => {
    logout();
  };

  return (
    <Dropdown>
      <DropdownItem key={1}>
        <NavLink to={home.profile}>
          <Icon.DropdownIcon icon={UserOutlined} /> Hồ sơ cá nhân
        </NavLink>
      </DropdownItem>

      <DropdownItem key={2}>
        <NavLink to="#" onClick={handleLogout}>
          <Icon.DropdownIcon icon={LogoutOutlined} /> Đăng xuất
        </NavLink>
      </DropdownItem>
    </Dropdown>
  );
};

export default UserDropdown;
