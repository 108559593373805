import HomeLayout from 'layouts/home/HomeLayout';
import ClassroomLayout from 'layouts/classroom/ClassroomLayout';
import LearningLayout from 'layouts/learning/LearningLayout';
import { app } from './routeNames';
import { ILayoutRoute } from 'models/route.model';


export const routes: ILayoutRoute[] = [
  {
    path: app.classroom,
    meta: {
      layout: ClassroomLayout,
    },
  },
  {
    path: app.learning,
    meta: {
      layout: LearningLayout,
    },
  },
  {
    path: app.index,
    meta: {
      layout: HomeLayout,
    },
  },
 
];
