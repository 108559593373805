import { ReactElement } from 'react';
import { Row, Col } from 'antd';
import { routes } from 'routers/home.route';
import Container from 'components/commons/Container';
import Header from 'layouts/layout-components/header';
import Drawer from 'layouts/layout-components/Drawer';
import ContentRoute from 'layouts/layout-components/ContentRoute';
import MediaQuery from 'layouts/layout-components/MediaQuery';
import Sider from './sidebar';

const HomeLayout = (): ReactElement => {
  return (
    <div style={{ height: '100%' }}>
      <Header />

      <Container>
        <Row gutter={20}>
          <Col xs={0} lg={7} xl={6} xxl={5} className="left-sidebar">
            <Sider />

            <MediaQuery on={['xs', 'sm', 'md']}>
              <Drawer>
                <Sider />
              </Drawer>
            </MediaQuery>
          </Col>

          <Col xs={24} lg={17} xl={18} xxl={19} style={{
            height: "calc(100vh - 66px)", overflowY: 'auto', boxSizing: 'border-box'
          }}>
            <ContentRoute routes={routes} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomeLayout;
