import { Typography } from 'antd';
import styled from 'styled-components';

const SubjectTitle = styled(Typography.Title)`
  &&& {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin-top: 15px;
  }
`;

SubjectTitle.defaultProps = {
  ellipsis: true,
};

export default SubjectTitle;
