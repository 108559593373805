import { home } from './routeNames';
import { IContentRoute } from 'models/route.model';

import HomePage from 'pages/home/HomePage';
import OnlineCourse from 'pages/online-course/OnlineCoursePage';
import ProfilePage from 'pages/profile/ProfilePage';
import TransactionPage from 'pages/transaction/TransactionPage';
import ChangePassword from 'pages/security/ChangePassword';

export const routes: IContentRoute[] = [
  {
    path: home.index,
    meta: {
      title: 'Lớp học của tôi',
      content: HomePage,
    },
  },
  {
    path: home.onlineCourse,
    meta: {
      title: 'Khóa học online',
      content: OnlineCourse,
    },
  },
  {
    path: home.profile,
    meta: {
      title: 'Thông tin cá nhân',
      content: ProfilePage,
    },
  },
  {
    path: home.security,
    meta: {
      title: 'Bảo mật tài khoản',
      content: ChangePassword,
    },
  },
  {
    path: home.transaction,
    meta: {
      title: 'Lịch sử giao dịch',
      content: TransactionPage,
    },
  },
];
