import {
  LeftOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import User from 'layouts/layout-components/header/User';
import MediaQuery from 'layouts/layout-components/MediaQuery';
import FindLectureInLesson from 'pages/learn/FindLecture';
import LessonTitle from 'pages/learn/style/LessonTitle';
import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { home } from 'routers/routeNames';
import {
  collapseSidebar,
  expandSidebar,
  selectBackLink,
  selectIsSidebarCollapse,
  showDrawer,
} from 'store/general.slice';
import { selectLecture } from 'store/onlinecourse.slice';
import styled from 'styled-components';

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #0275d8;
  padding: 11px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 15px;
  z-index: 2;
`;

const StyledBacklinkWrapper = styled.div`
  width: fit-content;
  font-size: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    color: #fff;
    @media (max-width: 576px) {
      font-size: 20px;
      padding-inline: 8px;
      span.text {
        display: none;
      }
    }
  }
`;

const StyleMenuButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyleButtonOnMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledButton = styled(Button)`
  height: 100%;
  border-radius: 0;
  background: transparent;
  &&&:hover {
    background-color: #042f54;
  }
`;

const Header = (): ReactElement => {
  const dispatch = useDispatch();
  const isSidebarCollapse = useSelector(selectIsSidebarCollapse);
  const history = useHistory();
  const backLink = useSelector(selectBackLink);
  const lecture = useSelector(selectLecture);

  const { title } = lecture;

  const { classId, lessonId, lectureId } = useParams<{
    classId: string;
    lessonId: string;
    lectureId: string;
  }>();

  const goBack = () => {
    const tab = window.localStorage.getItem('tabMenu');
    const newBackLink =
      tab == 'tab2' && backLink.includes('lop-hoc') ? backLink + '/phan-nhom' : backLink;

    if (newBackLink) {
      history.push(newBackLink);
      return;
    }
    history.push(home.onlineCourse);
  };

  const changeSidebar = () => {
    dispatch(isSidebarCollapse ? expandSidebar() : collapseSidebar());
  };

  return (
    <StyledHeader>
      <StyledBacklinkWrapper>
        <a onClick={goBack}>
          <LeftOutlined />
          <MediaQuery on={['md', 'lg', 'xl', 'xxl']}>
            <span className="text">Quay lại</span>
          </MediaQuery>
        </a>
      </StyledBacklinkWrapper>

      <LessonTitle className={title} />

      <FindLectureInLesson classId={classId} value={'Tìm bài giảng ...'} />
      <StyleMenuButton>
        <StyleButtonOnMobile>
          <User />

          <MediaQuery on={['xs', 'sm', 'md']}>
            <Button style={{ marginLeft: '25px' }} onClick={() => dispatch(showDrawer())}>
              <MenuOutlined />
            </Button>
          </MediaQuery>
        </StyleButtonOnMobile>
        <MediaQuery on={['lg', 'xl', 'xxl']}>
          <Tooltip title="Ẩn/hiện menu">
            <StyledButton type="text" onClick={changeSidebar}>
              {!isSidebarCollapse ? (
                <MenuUnfoldOutlined style={{ color: '#fff', fontSize: 22 }} />
              ) : (
                <MenuFoldOutlined style={{ color: '#fff', fontSize: 22 }} />
              )}
            </StyledButton>
          </Tooltip>
        </MediaQuery>
      </StyleMenuButton>
    </StyledHeader>
  );
};

export default Header;
