import { createSlice } from '@reduxjs/toolkit';
import { ClassInfo, Lesson, Module } from 'models/class.model';
import { Exercise, ExerciseDiscuss } from 'models/exercise.model';
import { classesApi } from 'services/class.service';
import { RootState } from 'store';

interface ClassState {
  classroom: ClassInfo;
  exercises: Exercise[];
  discusses: ExerciseDiscuss[];
  currentExerciseId: number;
  lessons: Lesson[];
  modules: Module[];
}

const initialState: ClassState = {
  classroom: {} as ClassInfo,
  exercises: [] as Exercise[],
  discusses: [] as ExerciseDiscuss[],
  currentExerciseId: 0,
  lessons: [] as Lesson[],
  modules: [] as Module[],
};

export const classSlice = createSlice({
  name: 'class',
  initialState,
  reducers: {
    setCurrentExerciseId: (state, { payload }) => {
      state.currentExerciseId = payload;
    },
    updateExercisesAfterSubmit: (state, { payload }) => {
      const { exerciseId, history } = payload;
      const findExercise = state.exercises.findIndex((item) => item.id === exerciseId);
      state.exercises[findExercise].history.unshift(history);
    },

    createModule: (state, { payload }) => {
      state.modules = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(classesApi.endpoints.getClassById.matchFulfilled, (state, { payload }) => {
      state.classroom = {
        ...payload,
      };
    });
    builder.addMatcher(
      classesApi.endpoints.getLecturesByClassId.matchFulfilled,
      (state, { payload }) => {
        if (payload.lessons.length > 0) {
          state.lessons = payload.lessons;
        }
      },
    );
    builder.addMatcher(
      classesApi.endpoints.getClassExercises.matchFulfilled,
      (state, { payload }) => {
        if (payload) state.exercises = [...payload];
      },
    );
    builder.addMatcher(
      classesApi.endpoints.getExerciseDiscusses.matchFulfilled,
      (state, { payload }) => {
        state.discusses = [...payload];
      },
    );
    builder.addMatcher(
      classesApi.endpoints.makeExerciseDiscuss.matchFulfilled,
      (state, { payload }) => {
        state.discusses.push(payload);
      },
    );
  },
});

export const { setCurrentExerciseId, updateExercisesAfterSubmit, createModule } =
  classSlice.actions;
export const selectClass = (state: RootState): ClassInfo => state.class.classroom;
export const selectDiscusses = (state: RootState): ExerciseDiscuss[] => state.class.discusses;
export const selectExercises = (state: RootState): Exercise[] => state.class.exercises;
export const selectLessons = (state: RootState): Lesson[] => state.class.lessons;
export const selectModules = (state: RootState): Module[] => state.class.modules;
export default classSlice.reducer;
