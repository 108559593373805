import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { selectComments } from 'store/comment.slice';
import NewUserComment from './UserComment';
import { List } from 'antd';

interface ListCommentProps {
  setShowCommentEditor: (value: boolean) => void;
}

const ListComment = ({ setShowCommentEditor }: ListCommentProps): ReactElement => {
  const listComment = useSelector(selectComments);

  return (
    <>
      {listComment && listComment.length > 0 ? (
        <List
          dataSource={listComment}
          // header={`${listComment.length} bình luận`}
          itemLayout="horizontal"
          renderItem={(props) => (
            <NewUserComment {...props} setShowCommentEditor={setShowCommentEditor} />
          )}
        />
      ) : (
        <h3>Chưa có bình luận nào</h3>
      )}
    </>
  );
};

export default ListComment;
