import { CommentOutlined, LeftOutlined, ReadOutlined, RightOutlined } from '@ant-design/icons';
import { Badge, Drawer } from 'antd';
import Tabs from 'components/commons/Tabs';
import ShowMarkdown from 'libraries/components/markdown/ShowMarkdown';
import { memo, ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Link, useParams } from 'react-router-dom';
import { learning } from 'routers/routeNames';
import { selectComments } from 'store/comment.slice';
import { selectLecture, setPlayVideo } from 'store/onlinecourse.slice';
import styled from 'styled-components';
import useTitle from 'utils/useTitle';
import NewLectureComment from './comment/LectureComment';
import CourseVideo from './CourseVideo';
import LectureAttachedFiles from './LectureAttachedFiles';
import UserNoteContent from './note/UserNoteContent';
import QuizContent from './quiz/QuizContent';
import LessonTitle from './style/LessonTitle';
import Button from 'libraries/components/commons/Button';

const LectureContentWrapper = styled.div`
  position: unset;
  .switch-lecture {
    bottom: calc(50% - 20px) !important ;
    position: sticky !important;
  }
  .switch-lecture.right {
    left: 100% !important;
  }
`;

const ButtonGroupStyle = styled.div`
  display: flex;
  justify-content: end;
  padding: 5px 10px;
`;

const ContainerStyle = styled.div`
  @media (min-width: 992px) {
    padding: 20px 40px;
  }
  padding: 12px;
`;

const ContentContainer = styled.div`
  font-family: 'Mulish';
  position: relative;
  .ant-tabs {
    padding: 12px;
    // .ant-tabs-content {
    //   padding: 15px;
    //   @media screen and (min-width: 992px) {
    //     padding: 20px 0;
    //   }
    // }
    @media screen and (min-width: 992px) {
      padding: 20px 40px;
    }
  }
`;

const { TabPane } = Tabs;

interface LectureContentProps {
  prevLessonId: string;
  nextLessonId: string;
  prevLectureId: string;
  nextLectureId: string;
  classId: string;
}

const LectureContent = ({
  prevLessonId,
  nextLessonId,
  prevLectureId,
  nextLectureId,
  classId,
}: LectureContentProps): ReactElement => {
  const [open, setOpen] = useState(false);
  const [openComment, setOpenComment] = useState(false);
  const dispatch = useDispatch();
  const showDrawer = () => {
    setOpen(true);
  };
  const showDrawerComment = () => {
    setOpenComment(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const onCloseComment = () => {
    setOpenComment(false);
  };

  const lecture = useSelector(selectLecture);
  const { notes } = lecture;

  const listComment = useSelector(selectComments);
  const [tabHeight, setTabHeight] = useState<number>(0);
  const { id, text, title, type } = lecture;
  useTitle(title);
  const { lessonId, lectureId } = useParams<{
    classId: string;
    lessonId: string;
    lectureId: string;
  }>();
  const mdRef = useRef<HTMLDivElement>(null);
  const noteRef = useRef<HTMLDivElement>(null);
  const attachmentRef = useRef<HTMLDivElement>(null);
  const commentRef = useRef<HTMLDivElement>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const onTbChange = () => {
    const mdHeight = mdRef?.current?.offsetHeight ?? 0;
    const noteHeight = noteRef?.current?.offsetHeight ?? 0;
    const attachmentHeight = attachmentRef?.current?.offsetHeight ?? 0;
    const commentHeight = commentRef?.current?.offsetHeight ?? 0;
    const HeightArray = [mdHeight, noteHeight, attachmentHeight, commentHeight];
    HeightArray.sort((a: number, b: number) => b - a);
    if (HeightArray[0] > tabHeight) setTabHeight(HeightArray[0]);
  }; // lấy height của tab lớn nhất
  useEffect(() => {
    // Hàm xử lý khi kích thước màn hình thay đổi
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    // Đăng ký sự kiện resize khi component được mount
    window.addEventListener('resize', handleResize);
    // Hủy đăng ký sự kiện resize khi component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    const mdHeight = mdRef?.current?.offsetHeight ?? 0;
    setTabHeight(mdHeight);
  }, [mdRef]); // gán giá trị cho tabHeight ban đầu

  useEffect(() => {
    localStorage.setItem(classId, JSON.stringify({ lessonId, lectureId }));
  }, []);

  return (
    <>
      {/* {!type && (
        <div style={{ textAlign: 'center', fontSize: '18px' }}>
          Hiên tại câu hỏi nâng cao chỉ hiển thị và làm ở phần luyện thi !
        </div>
      )} */}
      {type && (
        <LectureContentWrapper>
          {/* <MediaQuery on={['sm', 'xs']}>
            <FindLecture classId={classId} value={title} />
          </MediaQuery> */}

          <ContentContainer>
            {type === 'quiz' ? (
              <>
                <QuizContent {...lecture} />
              </>
            ) : (
              <>
                <CourseVideo />
              </>
            )}
            {/* Giao diện mới */}
            <ContainerStyle>
              <LectureAttachedFiles ref={attachmentRef} files={lecture.files} />
              <ButtonGroupStyle>
                <Button.Outline
                  icon={<ReadOutlined />}
                  style={{
                    background: '#fff',
                    color: '#0275D8',
                    borderColor: '#0275D8',
                    height: '40px',
                  }}
                  onClick={() => {
                    showDrawer(), dispatch(setPlayVideo(false));
                  }}
                >
                  Ghi chú
                </Button.Outline>

                <Button.Primary
                  icon={<CommentOutlined />}
                  style={{ marginLeft: 12, height: 40 }}
                  onClick={() => {
                    showDrawerComment();
                  }}
                >
                  Bình luận
                </Button.Primary>
              </ButtonGroupStyle>
              <ShowMarkdown ref={mdRef} markdown={text} />
            </ContainerStyle>
            {/* <Tabs defaultActiveKey={text ? '1' : '2'} onChange={onTbChange}>
              {text && (
                <TabPane
                  style={{ minHeight: `${tabHeight ?? 0}px` }}
                  tab={
                    <Badge size="small">
                      {lecture.type === 'lecture' ? 'Lý thuyết' : 'Nội dung'}
                    </Badge>
                  }
                  key="1"
                >
                  <ShowMarkdown ref={mdRef} markdown={text} />
                </TabPane>
              )}

              <TabPane
                style={{ minHeight: `${tabHeight ?? 0}px` }}
                tab={
                  <Badge
                    size="small"
                    count={
                      <span style={{ color: 'red' }}>
                        {lecture.notes && lecture.notes.length > 0 ? lecture.notes.length : ''}
                      </span>
                    }
                    offset={[6, 0]}
                  >
                    Ghi chú
                  </Badge>
                }
                key="2"
              >
                <UserNoteContent ref={noteRef} />
              </TabPane>
              {lecture.files.length > 0 && (
                <TabPane
                  style={{ minHeight: `${tabHeight ?? 0}px` }}
                  tab={
                    <Badge
                      size="small"
                      count={
                        <span style={{ color: 'red' }}>
                          {lecture.files && lecture.files.length > 0 ? lecture.files.length : ''}
                        </span>
                      }
                      offset={[6, 0]}
                    >
                      File đính kèm
                    </Badge>
                  }
                  key="3"
                ></TabPane>
              )}
              <TabPane
                style={{ minHeight: `${tabHeight ?? 0}px` }}
                tab={
                  <Badge
                    size="small"
                    count={
                      <span style={{ color: 'red' }}>
                        {listComment && listComment.length > 0 ? listComment.length : ''}
                      </span>
                    }
                    offset={[6, 0]}
                  >
                    Bình luận
                  </Badge>
                }
                key="4"
              >
                <NewLectureComment ref={commentRef} lectureId={id} />
              </TabPane>
            </Tabs> */}
            {prevLectureId !== '' && (
              <Link
                className="switch-lecture left"
                to={generatePath(learning.learn, {
                  classId,
                  lessonId: prevLessonId,
                  lectureId: prevLectureId,
                })}
              >
                <LeftOutlined />
              </Link>
            )}
            {nextLectureId !== '' && (
              <Link
                className="switch-lecture right"
                to={generatePath(learning.learn, {
                  classId,
                  lessonId: nextLessonId,
                  lectureId: nextLectureId,
                })}
              >
                <RightOutlined />
              </Link>
            )}
            <Drawer
              title={notes?.length > 0 ? `${notes.length} Ghi chú` : `Ghi chú`}
              placement="right"
              onClose={onClose}
              open={open}
              width={windowWidth > 768 ? '700px' : windowWidth > 578 ? '500px' : '100%'}
            >
              <UserNoteContent ref={noteRef} />
            </Drawer>
            <Drawer
              title={listComment.length > 0 ? ` ${listComment.length} Bình luận` : 'Bình luận'}
              placement="right"
              onClose={onCloseComment}
              open={openComment}
              width={windowWidth > 768 ? '700px' : windowWidth > 578 ? '500px' : '100%'}
            >
              <NewLectureComment ref={commentRef} lectureId={id} />
            </Drawer>
          </ContentContainer>
        </LectureContentWrapper>
      )}
    </>
  );
};

export default memo(LectureContent);
