import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Class, FindLesson, Lecture, LectureFound, Lesson } from 'models/class.model';
import { onlineCourseApi } from 'services/onlinecourse.service';
import { RootState } from 'store';

export interface QuizResult {
  id: string;
  isCorrect: boolean;
  score: number;
  isComplete: boolean;
  messaged: boolean;
  currentQuestion: number;
  isAnswered: boolean;
  notAnswer: boolean;
  quizLength: number;
}
export interface iQuizItem {
  isAnswered: boolean;
  notAnswer: boolean;
  isCorrect: boolean;
  id: string;
}
export interface updateQuizProps {
  id: any;
  is_true: boolean;
}
export interface iQuiz {
  id: string;
  isComplete: boolean;
  score: number;
  messaged: boolean;
  currentQuestion: number;
  is_true?: boolean | undefined;
  item: iQuizItem[];
}
interface PropsType {
  search: FindLesson[];
  course: Class;
  currentLectureId: string;
  lecture: Lecture;
  playVideo: boolean;
  playedSeconds: number;
  seekTime: number;
  quiz: iQuiz[];
  totalTrue: number;
  totalFalse: number;
  quizSubmit: boolean;
}
const initialState: PropsType = {
  course: {} as Class,
  currentLectureId: '',
  lecture: {} as Lecture,
  totalTrue: 0 as number,
  totalFalse: 0 as number,
  playVideo: false,
  playedSeconds: 0,
  quiz: [] as iQuiz[],
  seekTime: 1,
  search: [] as FindLesson[],
  quizSubmit: false as boolean,
};

interface iPayloadProps {
  id: string;
  itemId: string;
}
interface iIsAnsActionProps extends iPayloadProps {
  isAnswered: boolean;
}
interface iIsNotAnsActionProps extends iPayloadProps {
  notAnswer: boolean;
}
interface iCurrentQuestionProps extends iPayloadProps {
  currentQuestion: number;
}

export const onlineCourseSlice = createSlice({
  name: 'onlinecourse',
  initialState,
  reducers: {
    updateLearned: (state, { payload }: PayloadAction<number>) => {
      state.course.learned = payload;
    },
    setCurrentLectureId: (state, { payload }) => {
      state.currentLectureId = payload;
    },
    setPlayVideo: (state, { payload }) => {
      state.playVideo = payload;
    },
    setPlayedSeconds: (state, { payload }) => {
      state.playedSeconds = payload;
    },
    updateEditNote: (state, { payload }) => {
      const { id, content } = payload;
      const clnNotes = [...state.lecture.notes];
      const findIndex = clnNotes.findIndex((note) => note.id === id);
      clnNotes[findIndex].content = content;
      state.lecture = {
        ...state.lecture,
        notes: clnNotes,
      };
    },
    updateDeletedNote: (state, { payload }) => {
      const clnNotes = [...state.lecture.notes];
      state.lecture = {
        ...state.lecture,
        notes: clnNotes.filter((note) => note.id !== payload),
      };
    },
    setSeekTime: (state, { payload }) => {
      state.playVideo = true;
      state.seekTime = payload;
    },
    resetQuizState: (state, { payload }: PayloadAction<iPayloadProps>) => {
      const { id } = payload;
      const matchedQuiz = state.quiz.find((item: iQuiz) => item.id == id);

      if (matchedQuiz) {
        matchedQuiz.id;
        matchedQuiz.currentQuestion = 0;
        matchedQuiz.messaged = false;
        matchedQuiz.item = matchedQuiz.item.map(
          (item): iQuizItem => ({
            ...item,
            isAnswered: false,
            isCorrect: false,
            notAnswer: true,
          }),
        );
        matchedQuiz.isComplete = false;
        matchedQuiz.score = 0;
      }
    },
    setMessaged: (state, { payload }: PayloadAction<iPayloadProps>) => {
      const { id } = payload;
      const matchedQuiz = state.quiz.find((item: iQuiz) => item.id == id);
      if (matchedQuiz) {
        matchedQuiz.messaged = true;
      }
    },
    setCurrentQuestion: (state, { payload }: PayloadAction<iCurrentQuestionProps>) => {
      const { id, currentQuestion } = payload;
      const matchedQuiz = state.quiz.find((item: iQuiz) => item.id == id);
      if (matchedQuiz) {
        matchedQuiz.currentQuestion = currentQuestion;
      }
    },
    updateQuizList: (state, { payload }: PayloadAction<updateQuizProps>) => {
      const { id, is_true } = payload;
      state.lecture.quizes = state.lecture.quizes.map((item: any) =>
        item.id == id ? { ...item, is_true: is_true } : item,
      );

      if (is_true == true) {
        state.totalTrue += 1;
      } else {
        state.totalFalse += 1;
      }
    },
    setQuizSubmit: (state) => {
      state.quizSubmit = true;
    },
    resetQuizList: (state) => {
      state.lecture.quizes = state.lecture.quizes.map((item: any) => ({
        ...item,
        is_true: undefined,
      }));
      state.lecture.quizes.sort(() => Math.random() - 0.5);

      state.lecture.quizes.forEach((quizz) => {
        quizz.answers.sort(() => Math.random() - 0.5);
      });
      state.totalTrue = 0;
      state.totalFalse = 0;
      state.quizSubmit = false;
    },
    resetTotalQuiz: (state) => {
      state.totalTrue = 0;
      state.totalFalse = 0;
      state.quizSubmit = false;
    },
    setIsAnswered: (state, { payload }: PayloadAction<iIsAnsActionProps>) => {
      const { isAnswered, id, itemId } = payload;
      const matchedQuiz = state.quiz.find((item: iQuiz) => item.id == id);
      if (matchedQuiz) {
        const currentItem = matchedQuiz.item.find((item: iQuizItem) => item.id === itemId);
        if (currentItem) {
          currentItem.isAnswered = isAnswered;
        }
      }
    },
    setNotAnswer: (state, { payload }: PayloadAction<iIsNotAnsActionProps>) => {
      const { id, itemId, notAnswer } = payload;
      const matchedQuiz = state.quiz.find((item: iQuiz) => item.id == id);
      if (matchedQuiz) {
        const currentItem = matchedQuiz.item.find((item: iQuizItem) => item.id === itemId);
        if (currentItem) {
          currentItem.notAnswer = notAnswer;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      onlineCourseApi.endpoints.getLecturesByClassId.matchFulfilled,
      (state, { payload }) => {
        state.course = {
          ...payload,
          learned: 0,
          lessons: payload.lessons !== null ? payload.lessons : ([] as Lesson[]),
        };
      },
    );
    builder.addMatcher(
      onlineCourseApi.endpoints.getLecturesContent.matchFulfilled,
      (state, { payload }) => {
        state.lecture = {
          ...payload,
          video_url:
            payload.link_video !== ''
              ? payload.link_video
              : payload.link_youtube !== ''
              ? payload.link_youtube
              : '',
        };
        if (payload.quizes) {
          const isQuizExisted = state.quiz.some((quiz) => quiz.id === payload.id);
          if (!isQuizExisted) {
            state.quiz = [
              ...state.quiz,
              {
                id: payload.id,
                score: 0,
                isComplete: false,
                messaged: false,
                currentQuestion: 0,
                item: Array(payload.quizes.length)
                  .fill(null)
                  .map((_, index) => ({
                    id: payload.quizes[index].id,
                    isAnswered: false,
                    isCorrect: false,
                    notAnswer: true,
                  })),
              },
            ];
          }
        }
      },
    );
    builder.addMatcher(
      onlineCourseApi.endpoints.answerQuiz.matchFulfilled,
      (state, { payload }) => {
        const matchedQuiz = state.quiz.find((quiz: iQuiz) => quiz.id === state.lecture.id);
        if (matchedQuiz) {
          const matchedItem = matchedQuiz.item;
          const currentQuestion = matchedQuiz.currentQuestion;
          const currentItem = matchedItem[currentQuestion];
          currentItem.isAnswered = true;
          currentItem.notAnswer = false;
          currentItem.isCorrect = payload;
          const isComplete = matchedItem.every((item: iQuizItem) => {
            return item.isAnswered;
          });
          if (payload) {
            const score = matchedQuiz.score;
            matchedQuiz.score = score + 1;
          }
          if (isComplete) {
            matchedQuiz.isComplete = true;
          }
        }
      },
    );
    builder.addMatcher(
      onlineCourseApi.endpoints.createUserNote.matchFulfilled,
      (state, { payload }) => {
        state.lecture.notes.push(payload);
      },
    );
    builder.addMatcher(
      onlineCourseApi.endpoints.getFindLecture.matchFulfilled,
      (state, { payload }) => {
        state.search = payload ?? [];
      },
    );
  },
});
export const {
  setCurrentLectureId,
  setPlayVideo,
  setPlayedSeconds,
  updateEditNote,
  updateDeletedNote,
  setSeekTime,
  resetQuizState,
  setIsAnswered,
  updateQuizList,
  setMessaged,
  resetQuizList,
  setCurrentQuestion,
  resetTotalQuiz,
  setNotAnswer,
  setQuizSubmit,
  updateLearned,
} = onlineCourseSlice.actions;
export const selectCourse = (state: RootState): Class => state.onlinecourse.course;
export const selectTotalTrue = (state: RootState): number => state.onlinecourse.totalTrue;
export const selectQuizSubmit = (state: RootState): boolean => state.onlinecourse.quizSubmit;
export const selectTotalFalse = (state: RootState): number => state.onlinecourse.totalFalse;
export const selectLecture = (state: RootState): Lecture => state.onlinecourse.lecture;
export const selectSeekTime = (state: RootState): number => state.onlinecourse.seekTime;
export const selectPlayVideo = (state: RootState): boolean => state.onlinecourse.playVideo;
export const selectPlayedSeconds = (state: RootState): number => state.onlinecourse.playedSeconds;
export const selectQuiz = (state: RootState): iQuiz[] => state.onlinecourse.quiz;
export const selectSearch = (state: RootState): FindLesson[] => state.onlinecourse.search;
export default onlineCourseSlice.reducer;
