import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ReactElement, useEffect, useState } from 'react';
import NotFound from './NotFound';

interface PropsType {
  error: Pick<PropsType, never> &
    Partial<Pick<PropsType, 'error'>> &
    Partial<Pick<{ error: string }, never>>;
}

function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}
const ShowNotFound = ({ error }: PropsType): ReactElement => {
  const [errorCurrent, setErrorCurrent] = useState<string | any>('');
  const [status, setStatus] = useState<string | any>('404');
  useEffect(() => {
    if (isFetchBaseQueryError(error)) {
      setStatus(error.status);
      setErrorCurrent(error.data);
    }
  }, [error]);
  return <NotFound subTitle={errorCurrent} title={status} />;
};

ShowNotFound.defaultProps = {
  error: 'Lỗi tải dữ liệu từ sever',
};

export default ShowNotFound;
