import { ReactElement } from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/auth.slice';
import styled from 'styled-components';
import UserWrapperBackground from 'assets/images/sidebar.png';

const UserWrapper = styled.div`
  background: url(${UserWrapperBackground}) no-repeat;
  background-size: cover;
  padding-top: 35px;
  padding-bottom: 20px;
  padding-left: 30px;
  .ant-avatar {
    border: 5px solid #ffffff;
  }
`;

const UserName = styled.p`
  font-family: Mulish;
  margin-top: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #ffffff;
`;

const UserAvatarSidebar = (): ReactElement => {
  const dataUser = useSelector(selectUser);

  const { avatar, full_name } = dataUser;

  return (
    <UserWrapper>
      <Avatar size={128} icon={<UserOutlined />} src={avatar} alt="avatar" />
      <UserName>{full_name}</UserName>
    </UserWrapper>
  );
};

export default UserAvatarSidebar;
