import { Col, Row } from 'antd';
import { checkBeforeDate } from 'libraries/utils/timeDistanceToNow';
import { ReactElement, SyntheticEvent } from 'react';
import styled from 'styled-components';
import LessonStatus from './LessonStatus';
import LessonTitle from './LessonTitle';

interface LessonCollapseHeaderProps {
  title: string;
  lessonId: string;
  courseId: string;
  isAttendance: boolean;
  isVote: boolean;
  handleOpenRateModal: (e: SyntheticEvent, lessonId: string) => void;
  complete_date: string;
}

const RightSide = styled(Col)`
  @media (min-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
  }
`;

const LessonCollapseHeader = ({
  title,
  lessonId,
  isAttendance,
  isVote,
  handleOpenRateModal,
  complete_date,
}: LessonCollapseHeaderProps): ReactElement => {
  return (
    <>
      <Row justify="space-between" align="middle" gutter={[8, 0]}>
        <Col xs={24} sm={24} md={14} lg={16}>
          <LessonTitle title={title} />
        </Col>

        {/* Nút đánh giá */}

        <RightSide xs={24} sm={24} md={10} lg={8}>
          <LessonStatus
            {...{ isAttendance, isVote, handleOpenRateModal, lessonId, complete_date }}
          />
        </RightSide>
      </Row>
    </>
  );
};

export default LessonCollapseHeader;
