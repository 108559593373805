import { Collapse as AntCollapse } from 'antd';
import styled from 'styled-components';

const { Panel: AntPanel } = AntCollapse;

export const Collapse = styled(AntCollapse)`
  border: none;
  font-size: 16px;
  background-color: #ffffff;
`;

export const Panel = styled(AntPanel)`
  && {
    margin-bottom: 25px;
    border-bottom: none;
    border-radius: 5px;
    background-color: #e3f2fd;
    box-shadow: 0px 2px 2px rgb(0 0 0 / 10%);
    .ant-collapse-header {
      color: #343a40;
      font-weight: 700;
    }
    .ant-collapse-content {
      &.ant-collapse-content-active {
        border-radius: 0 0 5px 5px;
        border-top: none;
        .ant-collapse-content-box {
          padding: 12px;
        }
      }
      & > .ant-collapse-content-box,
      &.ant-collapse-content-active > .ant-collapse-content-box {
        padding: 12px;
      }
    }
  }
`;
