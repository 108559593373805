import { ForwardRefExoticComponent, ReactElement } from 'react';

const navIconStyle = {
  fontSize: '20px',
  marginRight: '15px',
  transition: 'all 0s',
};

const dropdownIconStyle = {
  fontSize: '16px',
  marginRight: '5px',
  transition: 'all 0s',
};

const buttonIconStyle = {
  fontSize: '16px',
  marginRight: '5px',
};

interface AntdIconProps {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
  style?: React.CSSProperties;
}

interface SidebarLinkIconProps {
  icon: ForwardRefExoticComponent<AntdIconProps>;
}

const NavIcon = ({ icon: Icon }: SidebarLinkIconProps): ReactElement => {
  return <Icon style={navIconStyle} />;
};

const DropdownIcon = ({ icon: Icon }: SidebarLinkIconProps): ReactElement => {
  return <Icon style={dropdownIconStyle} />;
};

const ButtonIcon = ({ icon: Icon }: SidebarLinkIconProps): ReactElement => {
  return <Icon style={buttonIconStyle} />;
};

export default { NavIcon, DropdownIcon, ButtonIcon };
