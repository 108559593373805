import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base.service';
import {
  StudentRank,
  ClassList,
  Lecture,
  Lesson,
  Class,
  RateType,
  ClassInfo,
  RateCourseType,
  QuizzesPractice,
} from 'models/class.model';
import { ExerciseDiscuss, ExerciseHistory, Exercise } from 'models/exercise.model';
import { Teacher } from 'models/user.model';

interface SendRateProps {
  id: string;
  lessonId: string;
  data: { vote_content: number; vote_teacher: number; student_note: string };
}
interface ExamClass {
  class_id: string;
  data: {
    type: string;
    duration: number;
    subjects: string[];
    count_quiz: number;
    is_advance: boolean;
  };
}
interface HistoryPractice {
  id: number;
  start_time: string;
  subjects: string[];
  type: string;
  mark: string;
}

interface SendRateCourseProps {
  id: string;
  data: { user_id: string; vote: number; review: string };
}
interface ClassSubject {
  id: string;
  name: string;
  weight: number;
  total_quiz: number;
  correct_rate: number;
}

interface SubmitExerciseProps {
  classroomId: string;
  data: { id: number; note: string; links: string[] };
}

interface MakeDiscussProps {
  id: string;
  exerciseId: number;
  data: { exercise_name: string; text: string };
}

export const classesApi = createApi({
  reducerPath: 'classesApi',
  baseQuery,
  refetchOnMountOrArgChange: true,
  // keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getClasses: builder.query<ClassList[], void>({
      query: () => `classes`,
      transformResponse: (response: ClassList[]) => {
        response = response ?? ([] as ClassList[]);
        return response;
      },
    }),
    getPractice: builder.query<QuizzesPractice, { classId: string; practiceId: string }>({
      query: ({ classId, practiceId }) => `classes/${classId}/practice/${practiceId}`,
    }),
    getCheckPractice: builder.query<{ id: number }, { classId: string }>({
      query: ({ classId }) => `classes/${classId}/check-practice`,
    }),
    getPracticeHistory: builder.query<HistoryPractice[], { classId: string }>({
      query: ({ classId }) => `classes/${classId}/history-practice`,
    }),

    getClassById: builder.query<ClassInfo, string>({
      query: (id) => {
        return { url: `classes/${id}`, method: 'GET' };
      },
      transformResponse: (response: ClassInfo) => {
        response.teachers = response.teachers ?? ([] as Teacher[]);
        return response;
      },
    }),

    getLecturesByClassId: builder.query<Class, {id: string, types: string}>({
      query: ({id, types}) => {
        return { url: `classes/${id}/lessons?type=${types}`, method: 'GET' };
      },
      transformResponse: (response: Class) => {
        response.lessons = response.lessons ?? ([] as Lesson[]);
        response.lessons.map((lesson) => {
          lesson.lectures = lesson.lectures ?? ([] as Lecture[]);
        });
        return response;
      },
    }),

    getClassExercises: builder.query<Exercise[], string>({
      query: (id) => {
        return { url: `classes/${id}/exercises`, method: 'GET' };
      },
      transformResponse: (response: Exercise[]) => {
        response = response ?? ([] as Exercise[]);
        response.map((item: Exercise) => {
          item.history = item.history ?? ([] as ExerciseHistory[]);
          item.history.map((history) => {
            history.status_mark = history.mark_at.includes('0001-01-01T00') ? false : true;
          });
        });

        return response;
      },
    }),

    getClassesRanking: builder.query<StudentRank[], string>({
      query: (id) => {
        return {
          url: `classes/${id}/rankings`,
          method: 'GET',
        };
      },
      transformResponse: (response: StudentRank[]) => {
        response = response ?? ([] as StudentRank[]);
        return response;
      },
    }),

    sendRate: builder.mutation<RateType, SendRateProps>({
      query: (arg) => {
        const { id, lessonId, data } = arg;
        return {
          url: `classes/${id}/lessons/${lessonId}/review`,
          method: 'POST',
          body: data,
        };
      },
    }),

    getRate: builder.query<RateType, { id: string; lessonId: string }>({
      query: (arg) => {
        const { id, lessonId } = arg;
        return {
          url: `classes/${id}/lessons/${lessonId}/review`,
          method: 'GET',
        };
      },
    }),
    sendCourseRate: builder.mutation<RateCourseType, SendRateCourseProps>({
      query: (arg) => {
        const { id, data } = arg;
        return {
          url: `classes/${id}/review`,
          method: 'POST',
          body: data,
        };
      },
    }),

    submitExercise: builder.mutation<ExerciseHistory, SubmitExerciseProps>({
      query: (arg) => {
        const { classroomId, data } = arg;
        return {
          url: `classes/${classroomId}/submit-exercise`,
          method: 'POST',
          body: data,
        };
      },
    }),
    submitPractice: builder.mutation<ExerciseHistory, { classId: string; practiceId: string }>({
      query: (arg) => {
        const { classId, practiceId } = arg;
        return {
          url: `/classes/${classId}/practice/${practiceId}/submit`,
          method: 'POST',
          body: {},
        };
      },
    }),
    SendDurationLeft: builder.mutation<
      ExerciseHistory,
      { classId: string; practiceId: string; duration_left: number }
    >({
      query: (arg) => {
        const { classId, practiceId, duration_left } = arg;
        return {
          url: `/classes/${classId}/practice/${practiceId}/stop`,
          method: 'POST',
          body: { duration_left },
        };
      },
    }),
    createExam: builder.mutation<{ id: string }, ExamClass>({
      query: (arg) => {
        const { class_id, data } = arg;
        return {
          url: `classes/${class_id}/practice`,
          method: 'POST',
          body: data,
        };
      },
    }),
    sendAnswerQuiz: builder.mutation<
      void,
      {
        class_id: string;
        practice_id: string;
        data: {
          answers: number[];
          quiz_id: string;
        };
      }
    >({
      query: (arg) => {
        const { class_id, practice_id, data } = arg;
        return {
          url: `classes/${class_id}/practice/${practice_id}/answer-quiz`,
          method: 'POST',
          body: data,
        };
      },
    }),

    getExerciseDiscusses: builder.query<ExerciseDiscuss[], { id: string; exerciseId: number }>({
      query: (arg) => {
        const { id, exerciseId } = arg;
        return { url: `classes/${id}/lectures/${exerciseId}/private-discuss`, method: 'GET' };
      },
      transformResponse: (response: ExerciseDiscuss[]) => {
        response = response ?? ([] as ExerciseDiscuss[]);
        return response;
      },
    }),
    getClassSubject: builder.query<ClassSubject[], { id: string }>({
      query: (arg) => {
        const { id } = arg;
        return { url: `classes/${id}/subjects`, method: 'GET' };
      },
    }),

    makeExerciseDiscuss: builder.mutation<ExerciseDiscuss, MakeDiscussProps>({
      query: (arg) => {
        const { id, exerciseId, data } = arg;

        return {
          url: `classes/${id}/lectures/${exerciseId}/private-discuss`,
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetClassesQuery,
  useGetPracticeQuery,
  useGetCheckPracticeQuery,
  useGetPracticeHistoryQuery,
  useGetClassSubjectQuery,
  useGetClassByIdQuery,
  useGetLecturesByClassIdQuery,
  useGetClassExercisesQuery,
  useGetClassesRankingQuery,
  useSubmitExerciseMutation,
  useSubmitPracticeMutation,
  useGetExerciseDiscussesQuery,
  useMakeExerciseDiscussMutation,
  useCreateExamMutation,
  useSendRateMutation,
  useSendDurationLeftMutation,
  useGetRateQuery,
  useSendCourseRateMutation,
  useSendAnswerQuizMutation,
} = classesApi;
