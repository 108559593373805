import { ReactElement } from 'react';

interface ParentTitleProp {
  title: string;
}

const ParentTitle = ({ title }: ParentTitleProp): ReactElement => {
  return (
    <div>
      <span>{title}</span>
    </div>
  );
};

export default ParentTitle;
