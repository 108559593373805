import { ReactElement, useEffect, useState } from 'react';
import { Badge, Menu } from 'antd';
import {
  LineChartOutlined,
  ReadOutlined,
  TeamOutlined,
  InfoCircleOutlined,
  LikeOutlined,
  FormOutlined,
  PicLeftOutlined,
} from '@ant-design/icons';
import { generatePath, useParams } from 'react-router';

import Icon from 'components/commons/Icon';
import NavLink from 'components/commons/NavLink';

import { classroom } from 'routers/routeNames';
import { useSelector } from 'react-redux';
import { selectClass, selectExercises } from 'store/class.slice';

const links = [
  { id: 1, path: classroom.index, label: 'Tiến độ học tập', icon: LineChartOutlined },
  { id: 2, path: classroom.module, label: 'Khung chương trình', icon: PicLeftOutlined },
  { id: 3, path: classroom.exercise, label: 'Bài tập', icon: ReadOutlined },
  { id: 4, path: classroom.exam, label: 'Luyện thi', icon: FormOutlined },
  { id: 5, path: classroom.ranking, label: 'Bảng xếp hạng', icon: TeamOutlined },
  { id: 6, path: classroom.reviews, label: 'Đánh giá khóa học', icon: LikeOutlined },
  { id: 7, path: classroom.info, label: 'Thông tin chung', icon: InfoCircleOutlined },
];
const links1 = [
  { id: 1, path: classroom.index, label: 'Tiến độ học tập', icon: LineChartOutlined },
  { id: 2, path: classroom.module, label: 'Khung chương trình', icon: PicLeftOutlined },
  { id: 3, path: classroom.exercise, label: 'Bài tập', icon: ReadOutlined },
  { id: 4, path: classroom.exam, label: 'Luyện thi', icon: FormOutlined },
  { id: 5, path: classroom.ranking, label: 'Bảng xếp hạng', icon: TeamOutlined },
  { id: 6, path: classroom.info, label: 'Thông tin chung', icon: InfoCircleOutlined },
];

const Tabs = (): ReactElement => {
  const { classroom: cls } = useParams<{ classroom: string }>();
  const exercises = useSelector(selectExercises);
  const [count, setCount] = useState(0);
  const { total_learns, learned, absences } = useSelector(selectClass);
  const urlParams = window.location.href;

  useEffect(() => {
    if (urlParams.includes('phan-nhom')) {
      window.localStorage.setItem('tabMenu', 'tab2');
    } else if (!urlParams.includes('phan-nhom') && !urlParams.includes('learn')) {
      window.localStorage.setItem('tabMenu', 'tab1');
    }
  }, [urlParams]);
  useEffect(() => {
    const tmpCount = exercises.reduce((total, item) => {
      if (item.history.length === 0) {
        total++;
      }
      return total;
    }, 0);
    setCount(tmpCount);
  }, [exercises]);

  return (
    <nav>
      <Menu selectedKeys={[location.pathname]} mode="horizontal">
        {(total_learns > learned + absences ? links1 : links).map(({ path, label, icon }) => (
          <Menu.Item key={generatePath(path, { classroom: cls })}>
            <NavLink
              to={generatePath(path, {
                classroom: cls,
              })}
            >
              <Icon.NavIcon icon={icon} />
              {path === classroom.exercise ? (
                <Badge size="small" count={count}>
                  {label}
                </Badge>
              ) : (
                label
              )}
            </NavLink>
          </Menu.Item>
        ))}
      </Menu>
    </nav>
  );
};

export default Tabs;
