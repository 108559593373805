import MainPage from 'pages/class-detail/lesson/LessonPage';
import ExercisePage from 'pages/class-detail/exercise/ExercisePage';
import RankPage from 'pages/class-detail/rank/RankPage';
import InfoPage from 'pages/class-detail/info/InfoPage';
import ModulePage from 'pages/class-detail/module/ModulePage';

import { classroom } from './routeNames';
import { IContentRoute } from 'models/route.model';
import ReviewsPage from 'pages/class-detail/reviews/ReviewsPage';
import ExamPage from 'pages/class-detail/exercise/exam/ExamPage';
import PracticePage from 'pages/class-detail/practice/PracticePage';

export const routes: IContentRoute[] = [
  {
    path: classroom.exercise,
    meta: {
      title: 'Bài tập',
      content: ExercisePage,
    },
  },
  {
    path: classroom.exam,
    meta: {
      title: 'Luyện thi',
      content: ExamPage,
    },
  },
  {
    path: classroom.practice,
    meta: {
      title: 'Bài thi',
      content: PracticePage,
    },
  },
  {
    path: classroom.ranking,
    meta: {
      title: 'Bảng xếp hạng',
      content: RankPage,
    },
  },
  {
    path: classroom.info,
    meta: {
      title: 'Thông tin chung',
      content: InfoPage,
    },
  },
  {
    path: classroom.reviews,
    meta: {
      title: 'Đánh giá khóa học',
      content: ReviewsPage,
    },
  },
  {
    path: classroom.index,
    meta: {
      title: 'Tiến độ học tập',
      content: MainPage,
    },
  },
  {
    path: classroom.module,
    meta: {
      title: 'Khung chương trình',
      content: ModulePage,
    },
  },
];
