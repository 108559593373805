import { ReactElement } from 'react';

interface EditButtonProps {
  handleEdit: () => void;
}

const EditButton = ({ handleEdit }: EditButtonProps): ReactElement => {
  const handleEditContent = () => {
    handleEdit();
  };

  return (
    <span key="comment-reply" className="reply-btn" onClick={handleEditContent}>
      Sửa
    </span>
  );
};

export default EditButton;
