import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base.service';
import {
  ClassList,
  Lecture,
  Class,
  Note,
  Lesson,
  AttachedFiles,
  FindLesson,
} from 'models/class.model';

const PREVIOUS_LECTURES_KEY = 'previous_lectures';

interface CreateNoteProps {
  id: string;
  lectureId: string;
  data: { content: string; played_seconds: number };
}

interface EditNoteProps {
  classId: string;
  lectureId: string;
  noteId: string;
  data: { content: string };
}

interface AnswerQuizProps {
  classId: string;
  id: string;
  data: { answers: number[] };
}

export const savePreviousLectures = (
  classId: string,
  lessonId: string,
  lectureId: string,
): void => {
  try {
    const previousLectures = JSON.parse(localStorage.getItem(PREVIOUS_LECTURES_KEY) || '{}');
    previousLectures[classId] = { lesson_id: lessonId, lecture_id: lectureId };
    localStorage.setItem(PREVIOUS_LECTURES_KEY, JSON.stringify(previousLectures));
  } catch (err) {
    localStorage.setItem(PREVIOUS_LECTURES_KEY, '{}');
  }
};

export const getPreviousLectures = (): Record<string, unknown> => {
  let previousLectures = {};
  try {
    previousLectures = JSON.parse(localStorage.getItem(PREVIOUS_LECTURES_KEY) || '{}');
  } catch (err) {
    return {};
  }

  return previousLectures;
};

export const onlineCourseApi = createApi({
  reducerPath: 'onlineCourseApi',

  baseQuery,

  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    getOnlineClasses: builder.query<ClassList[], { type: string }>({
      query: (arg) => {
        const { type } = arg;
        return {
          url: `classes`,
          method: 'GET',
          params: { type },
        };
      },
      transformResponse: (response: ClassList[]) => {
        response = response ?? ([] as ClassList[]);
        //TODO: Lỗi lấy previous lecture, tạm comment
        // const previousLectures = getPreviousLectures();

        // for (const item of response) {
        //   if (previousLectures[item.id]) {
        //     const cls = previousLectures[item.id] as ClassList;
        //     item.lesson_id = cls.lesson_id;
        //     item.lecture_id = cls.lecture_id;
        //   }
        // }

        return response;
      },
    }),

    getLecturesByClassId: builder.query<Class, string>({
      query: (id) => {
        return { url: `classes/${id}/lessons`, method: 'GET' };
      },
      transformResponse: (response: Class) => {
        response.lessons = response.lessons ?? ([] as Lesson[]);
        response.lessons.length > 0 &&
          response.lessons.map((lesson) => {
            lesson.lectures = lesson.lectures ?? ([] as Lecture[]);
          });
        return response;
      },
    }),

    createUserNote: builder.mutation<Note, CreateNoteProps>({
      query: ({ id, lectureId, data }) => {
        return {
          url: `classes/${id}/lectures/${lectureId}/note`,
          method: 'POST',
          body: data,
        };
      },
    }),

    editUserNote: builder.mutation<string, EditNoteProps>({
      query: ({classId, lectureId, noteId, data }) => {
        return {
          url: `classes/${classId}/lectures/${lectureId}/lecturenote/${noteId}`,
          method: 'POST',
          body: data,
        };
      },
    }),

    deleteUserNote: builder.mutation<string, { classId: string; lectureId: string; noteId: string }>({
      query: ({classId, lectureId, noteId }) => {
        return {
          url: `classes/${classId}/lectures/${lectureId}/lecturenote/${noteId}`,
          method: 'DELETE',
        };
      },
    }),

    getLecturesContent: builder.query<Lecture, { id: string; lessonId: string; lectureId: string }>(
      {
        query: (arg) => {
          const { id, lessonId, lectureId } = arg;

          return { url: `classes/${id}/lessons/${lessonId}/lectures/${lectureId}`, method: 'GET' };
        },
        transformResponse: (response: Lecture) => {
          response = response ?? ({} as Lecture);
          response.notes = response.notes ?? ([] as Note[]);
          response.files = response.files ?? ([] as AttachedFiles[]);

          return response;
        },
      },
    ),

    getFindLecture: builder.query<FindLesson[], { classes_id: string; keyword: string }>({
      query: ({ classes_id, keyword }) => `classes/${classes_id}/lectures?keyword=${keyword}`,
    }),

    answerQuiz: builder.mutation<boolean, AnswerQuizProps>({
      query: ({classId, id, data }) => {
        return {
          url: `classes/${classId}/quizes/${id}/answer-quiz`,
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetOnlineClassesQuery,
  useGetLecturesByClassIdQuery,
  useCreateUserNoteMutation,
  useEditUserNoteMutation,
  useDeleteUserNoteMutation,
  useGetLecturesContentQuery,
  useAnswerQuizMutation,
  useGetFindLectureQuery,
} = onlineCourseApi;
