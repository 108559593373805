import { createSlice } from '@reduxjs/toolkit';
import { Quizzes, QuizzesPractice } from 'models/class.model';
import { Exercise, ExerciseDiscuss } from 'models/exercise.model';
import { classesApi } from 'services/class.service';
import { RootState } from 'store';

interface PracticeState {
  practice: QuizzesPractice;
  is_time_submit: boolean;
  duration_left: number;
  quiz_status: boolean;
  update_localStorage: boolean;
}
const initialState: PracticeState = {
  practice: {} as QuizzesPractice,
  is_time_submit: false as boolean,
  duration_left: 0 as number,
  quiz_status: true as boolean,
  update_localStorage: false as boolean,
};

export const practiceSlice = createSlice({
  name: 'practices',
  initialState,
  reducers: {
    updateQuizAfterSelect: (state, { payload }) => {
      const { quizzesId, has_answer, answers_choose } = payload;

      function updateQuiz(quizes: any, targetId: any, newValue: any) {
        for (let i = 0; i < quizes.length; i++) {
          if (quizes[i].id === targetId) {
            quizes[i] = { ...quizes[i], ...newValue };
            return true;
          }
          if (quizes[i].quizzes) {
            // nếu không tìm thấy ID cần cập nhật, kiểm tra các câu hỏi con
            const updated = updateQuiz(quizes[i].quizzes, targetId, newValue);
            if (updated) {
              return true;
            }
          }
        }
        return false; // nếu không tìm thấy ID cần cập nhật ở cả câu hỏi và câu hỏi con, trả về false
      }
      updateQuiz(state.practice.quizzes, quizzesId, payload);
    },
    updateQuizAfterIsTrue: (state, { payload }) => {
      const { quizzesId, is_true } = payload;

      state.practice.quizzes = state.practice.quizzes.map((item) => {
        if (item.id == quizzesId) {
          return {
            ...item,
            is_true,
          };
        } else return item;
      });
    },

    updateDurationLeft: (state, { payload }) => {
      state.duration_left = payload;
    },
    changeQuizStatus: (state, { payload }) => {
      state.quiz_status = payload;
    },
    updateIsTimeSubmit: (state, { payload }) => {
      state.is_time_submit = payload;
    },
    updateIsSubmit: (state, { payload }) => {
      state.practice.is_submit = payload;
    },
    updateShowAnswer: (state, { payload }) => {
      state.update_localStorage = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(classesApi.endpoints.getPractice.matchFulfilled, (state, { payload }) => {
      state.practice = {
        ...payload,
      };
    });
  },
});

export const {
  updateQuizAfterSelect,
  updateIsTimeSubmit,
  updateIsSubmit,
  updateQuizAfterIsTrue,
  updateDurationLeft,
  changeQuizStatus,
  updateShowAnswer,
} = practiceSlice.actions;
export const selectPractice = (state: RootState): QuizzesPractice => state.practices.practice;
export const selectTimeSubmit = (state: RootState): boolean => state.practices.is_time_submit;
export const selectDurationLeft = (state: RootState): number => state.practices.duration_left;
export const selectQuizStatus = (state: RootState): boolean => state.practices.quiz_status;
export const selectUpdateLocalStorage = (state: RootState): boolean =>
  state.practices.update_localStorage;

export default practiceSlice.reducer;
