import { createApi } from '@reduxjs/toolkit/query/react';
import { Transaction } from 'models/user.model';
import { baseQuery } from './base.service';

export const transactionApi = createApi({
  reducerPath: 'transactionApi',

  baseQuery,

  endpoints: (builder) => ({
    getUserTransaction: builder.query<Transaction[], void>({
      query: () => ({
        url: `transaction`,
        method: 'GET',
      }),
      transformResponse: (response: Transaction[]) => {
        response = response ?? ([] as Transaction[]);
        return response;
      },
    }),
  }),
});

export const { useGetUserTransactionQuery } = transactionApi;
