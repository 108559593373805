const getNumberQuizNoHasAnswer = (quizzes: any) => {
  let count = 0;
  if (!quizzes) return;
  quizzes?.forEach((quiz1: any) => {
    if (quiz1.answers && quiz1.has_answer == false) {
      count++;
    }
    quiz1?.quizzes?.forEach((quiz2: any) => {
      if (quiz2.answers && quiz2.has_answer == false) {
        count++;
      }
      quiz2?.quizzes?.forEach((quiz3: any) => {
        if (quiz3.answers && quiz3.has_answer == false) {
          count++;
        }
      });
    });
  });
  return count;
};
export const getNumberQuizNoHasAnswer1 = (quiz: any) => {
  let count = 0;
  if (!quiz) return 0;
  if (quiz.answers && quiz.has_answer == false) {
    count++;
  }
  quiz?.quizzes?.forEach((quiz2: any) => {
    if (quiz2.answers && quiz2.has_answer == false) {
      count++;
    }
    quiz2?.quizzes?.forEach((quiz3: any) => {
      if (quiz3.answers && quiz3.has_answer == false) {
        count++;
      }
    });
  });

  return count;
};

export default getNumberQuizNoHasAnswer;
