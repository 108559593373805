import { createSlice } from '@reduxjs/toolkit';
import { commentApi } from 'services/comment.service';
import { LectureComment } from 'models/comment.model';
import { RootState } from 'store';

const initialState = {
  commentList: [] as LectureComment[],
};

export const commentSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    replyUserComment: (state, { payload }) => {
      const { parentId, sub_comment } = payload;
      const parentComment = state.commentList.find((item) => item.id === parentId);

      if (parentComment) {
        if (parentComment.sub_comments == null) {
          parentComment.sub_comments = [];
        }
        parentComment.sub_comments.push(sub_comment);
      }
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      commentApi.endpoints.getLectureComments.matchFulfilled,
      (state, { payload }) => {
        state.commentList = payload ?? ([] as LectureComment[]);
      },
    );

    // Push new comment to comment list
    builder.addMatcher(commentApi.endpoints.postComment.matchFulfilled, (state, { payload }) => {
      state.commentList.push(payload);
    });

    builder.addMatcher(commentApi.endpoints.deleteComment.matchFulfilled, (state, { payload }) => {
      const { parent_id, id } = payload;
      if (!parent_id) {
        const newList = state.commentList.filter((item) => item.id != id);
        state.commentList = newList;
      } else {
        const parentComment = state.commentList.find((item) => item.id === parent_id);
        if (parentComment) {
          parentComment.sub_comments = parentComment.sub_comments.filter((item) => item.id != id);
        }
      }
    });

    builder.addMatcher(commentApi.endpoints.editComment.matchFulfilled, (state, { payload }) => {
      console.log('Cmt slice edit: ', payload);
    });
  },
});

export const selectComments = (state: RootState): LectureComment[] => state.comments.commentList;

export const { replyUserComment } = commentSlice.actions;
export default commentSlice.reducer;
