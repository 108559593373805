import { Divider } from 'antd';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { selectPractice } from 'store/practice.slice';
import styled from 'styled-components';
import getListAllQuiz from 'utils/getListAllQuiz';
import { getNumberQuizNoHasAnswer1 } from 'utils/getNumberQuizNoHasAnswer';
import getQuizTrue from 'utils/getQuizTrue';
import getQuizTrueNumber from 'utils/getQuizTrueNumber';
import CountDownTimer from './CountDownTimer';
import { CheckOutlined } from '@ant-design/icons';
const QuizzStyle = styled.div`
  background-color: #eeeeee;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #58a700;
  font-weight: 700;
  font-size: 18px;
  margin: 12px;
  cursor: pointer;
`;
const QuizzListStyle = styled.div`
  // @media (max-width: 568px) {
  //   display: none;
  // }

  border-right: 1px solid #ccc;
  height: 100%;
  margin-right: 12px;
`;
const SpanMarginRight = styled.span`
  margin-right: 12px;
`;
const Quizzcontainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  // justify-content:center;
  margin: 0 24px;
`;
const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const CircleStyle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 4px;
`;

interface props {
  quizzes: any;
  end_time?: any;
  is_submit?: any;
}
const Circle = ({ color }: any): ReactElement => {
  return <CircleStyle style={{ background: `${color}` }}></CircleStyle>;
};

const QuizzList = ({ end_time, quizzes, is_submit }: props): ReactElement => {
  const { classroom: cls } = useParams<{ classroom: string }>();
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const question = query.get('question');

  const changeQuestion = (newQuestion: number) => {
    const currentLocation = history.location;
    const currentSearch = currentLocation.search;
    const searchParams = new URLSearchParams(currentSearch);
    searchParams.set('question', newQuestion.toString());
    history.push({
      pathname: currentLocation.pathname,
      search: searchParams.toString(),
    });
  };
  const handleClick = (quizzNUmber: number) => {
    changeQuestion(quizzNUmber);
  };

  return (
    <QuizzListStyle>
      <div
        style={{
          paddingTop: '34.5px',
          margin: ' 0 24px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2 style={{ margin: 0 }}>{quizzes?.length} câu hỏi</h2>
        {end_time && is_submit == false && <CountDownTimer endTime={end_time} />}
        {is_submit == true && (
          <div style={{ fontSize: '20px' }}>
            <span style={{ color: '#58a700;' }}>{getQuizTrueNumber(getListAllQuiz(quizzes))}</span>/
            <span>{getListAllQuiz(quizzes).length}</span> Đáp án đúng
          </div>
        )}
      </div>
      <Divider />

      <Quizzcontainer>
        {is_submit == false && (
          <FlexContainer>
            <FlexContainer>
              <Circle color={'#d7ffb8'}></Circle>
              <SpanMarginRight>Đã trả lời</SpanMarginRight>
            </FlexContainer>
            <FlexContainer>
              <Circle color={'#f6f9fc'}></Circle>
              <SpanMarginRight>Chưa trả lời</SpanMarginRight>
            </FlexContainer>
          </FlexContainer>
        )}
        {is_submit == true && (
          <FlexContainer>
            <FlexContainer>
              <Circle color={'#d7ffb8'}></Circle>
              <SpanMarginRight>Trả lời đúng</SpanMarginRight>
            </FlexContainer>
            <FlexContainer>
              <Circle color={' #ffdfe0'}></Circle>
              <SpanMarginRight>Trả lời sai</SpanMarginRight>
            </FlexContainer>
            <FlexContainer>
              <CheckOutlined style={{ color: '#58a700', display: 'flex', alignItems:'center',fontSize:'20px',marginRight:'3px'}} />
              <SpanMarginRight> Đáp án đúng</SpanMarginRight>
            </FlexContainer>
          </FlexContainer>
        )}

        <p style={{ margin: '12px 0 0 0' }}>Bấm vào ô dưới để xem lại câu hỏi</p>
        <Divider />
        {quizzes &&
          quizzes?.map((item: any, i: number) =>
            is_submit == true ? (
              <QuizzStyle
                key={i}
                onClick={() => handleClick(i + 1)}
                style={{
                  border: question === (i + 1).toString() ? '2px solid #58a700' : '0px  solid #ea2b2b',
                  backgroundColor: getQuizTrue(item) ? '#d7ffb8' : ' #ffdfe0',
                  color: getQuizTrue(item) ? '#58a700;' : '#ea2b2b',
                }}
              >
                <span>{i + 1}</span>
              </QuizzStyle>
            ) : (
              <div key={i}>
                <QuizzStyle
                  key={i}
                  onClick={() => handleClick(i + 1)}
                  style={{
                    border: question === (i + 1).toString() ? '2px solid #58a700' : '0px  solid #ea2b2b',
                    backgroundColor: getNumberQuizNoHasAnswer1(item) == 0 ? '#d7ffb8' : '#f6f9fc',
                    color: getNumberQuizNoHasAnswer1(item) == 0 ? '#58a700' : '#58a700',
                  }}
                >
                  <span>{i + 1}</span>
                </QuizzStyle>
              </div>
            ),
          )}
      </Quizzcontainer>
    </QuizzListStyle>
  );
};

export default QuizzList;
