import styled from 'styled-components';
import { Menu as AntMenu } from 'antd';

const { SubMenu: AntSubMenu } = AntMenu;

const Main = styled(AntMenu)`
  background-color: #fff;
`;

const SubMenu = styled(AntSubMenu)`
  &&& {
    border-bottom: 1px solid #aaa;
    .ant-menu-sub.ant-menu-inline {
      background-color: #ffffff;
    }
    .ant-menu-submenu-title {
      padding-left: 13px;
      height: 60px;
      line-height: 36px;
      margin: 0;
      background-color: #fff;
      span {
        font-size: 18px;
        font-weight: 400;
        color: #000;
      }
    }
  }
  &.ant-menu-submenu-open {
    .ant-menu-submenu-title {
      background-color: #e3f2fd !important;
      border-bottom: 1px solid #aaa;
    }
  }
`;

const Item = styled(AntMenu.Item)`
  &&& {
    margin: 0;
    padding-left: 15px !important;
    padding: 15px;
    min-height: 60px;
    height: auto;
    line-height: normal;
    border-bottom: 1px solid #aaa;
    border-left: 5px solid transparent;
    white-space: normal;
    &:last-child {
      border-bottom-color: transparent;
    }
    a {
      display: block;
      color: #000000;
      &.current-lecture {
        border-left-color: #0275d8;
      }
    }
    &.ant-menu-item-selected {
      background-color: #f5fdff;
      border-left-color: #0275d8;
    }
    .video-duration {
      color: #7e7373;
      font-size: 13px;
      margin-bottom: 0;
    }
  }
`;

export default { Main, SubMenu, Item };
