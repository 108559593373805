import { DownloadOutlined } from '@ant-design/icons';
import { AttachedFiles } from 'models/class.model';
import { forwardRef, ReactElement, Ref } from 'react';
import styled from 'styled-components';

const AttachedFilesWrapper = styled.div`
  .link-item {
    margin-bottom: 15px;
  }
  .link-content {
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: 400px;
    padding: 10px 20px;
    background-color: #f0f0f0;
    border-radius: 5px;
    .file-name {
      width: calc(100% - 100px);
    }
    .file-size {
      width: 70px;
    }
    a {
      width: 30px;
    }
  }
`;

interface PropsType {
  files: AttachedFiles[];
}

const LectureAttachedFiles = forwardRef(
  ({ files }: PropsType, ref: Ref<HTMLDivElement>): ReactElement => {
    return (
      <AttachedFilesWrapper ref={ref}>
        {files.map((file, index) => (
          <div className="link-item" key={index}>
            <div className="link-content">
              <span className="file-name">{file.name}</span>
              <a href={file.link}>
                <DownloadOutlined />
              </a>
            </div>
          </div>
        ))}
      </AttachedFilesWrapper>
    );
  },
);

LectureAttachedFiles.displayName = 'LectureAttachedFiles';

export default LectureAttachedFiles;
