export const app = {
  index: '/',
  classroom: '/lop-hoc/:classroom',
  learning: '/learn/:classId',
};

export const home = {
  index: app.index,
  onlineCourse: app.index + 'khoa-hoc-truc-tuyen',
  transaction: app.index + 'lich-su-giao-dich',
  profile: app.index + 'ho-so-ca-nhan',
  security: app.index + 'bao-mat-tai-khoan',
  notFound: app.index + '404',
};

export const classroom = {
  index: app.classroom,
  exercise: app.classroom + '/bai-tap',
  module: app.classroom + '/phan-nhom',
  exam: app.classroom + '/luyen-thi',
  practice: app.classroom + `/practice/:practiceId`,
  ranking: app.classroom + '/bang-xep-hang',
  reviews: app.classroom + '/danh-gia-khoa-hoc',
  info: app.classroom + '/thong-tin-chung',
};
export const learning = {
  welcomePage: app.learning + '/welcome-page',
  learn: app.learning + '/:lessonId/:lectureId',
};
