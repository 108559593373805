import { PlusCircleFilled } from '@ant-design/icons';
import Button from 'libraries/components/commons/Button';
import Duration from 'libraries/components/Duration';
import Editor from 'libraries/editor';
import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCreateUserNoteMutation } from 'services/onlinecourse.service';
import {
  selectCourse,
  selectLecture,
  selectPlayedSeconds,
  setPlayVideo,
} from 'store/onlinecourse.slice';
import styled from 'styled-components';
import TimeSlot from './TimeSlot';
import NoteStyle from './NoteStyle';
import { message } from 'antd';

// TODO: Khi play video, hiện đang lưu played_seconds (thời gian chạy video) vào redux để hiển thị ở nút tạo note mới. Dẫn đến component bị re-render liên tục, cần tối ưu lại.

const SubmitGroupButton = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 20px;
  gap: 24px;
`;

const AddNewNote = (): ReactElement => {
  const dispatch = useDispatch();
  const { id: classId } = useSelector(selectCourse);
  const { id: lectureId, video_url, type } = useSelector(selectLecture);
  const playedSeconds = useSelector(selectPlayedSeconds);
  const [addNewNote, setAddNewNote] = useState(false);
  const [contentNote, setContentNote] = useState('');
  const [createUserNote] = useCreateUserNoteMutation();

  const handleClickAddMoreNote = () => {
    setAddNewNote(true);
    dispatch(setPlayVideo(false));
  };

  const handleAddNewNote = () => {
    const data = {
      content: contentNote,
      played_seconds: Math.floor(playedSeconds),
    };
    createUserNote({ id: classId, lectureId, data })
      .unwrap()
      .then(() => {
        message.success('Tạo mới ghi chú thành công!');
        setContentNote('');
        setAddNewNote(false);
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  return (
    <NoteStyle.Wrapper>
      {addNewNote && video_url !== '' && type !== 'quiz' && (
        <NoteStyle.Info>
          <TimeSlot>
            <Duration seconds={playedSeconds} />
          </TimeSlot>
        </NoteStyle.Info>
      )}

      <NoteStyle.Content
        style={{ width: video_url !== '' && type !== 'quiz' ? '100%' : 'calc(100% - 70px)' }}
      >
        {!addNewNote ? (
          <>
            <Button.Cancel
              onClick={handleClickAddMoreNote}
              style={{ display: 'flex', alignItems: 'center', height: 40 }}
            >
              Tạo ghi chú mới
              {video_url !== '' && type !== 'quiz' && (
                <>
                  &nbsp;tại <Duration seconds={playedSeconds} />
                </>
              )}
              <PlusCircleFilled style={{ position: 'static', color: '#096dd9' }} />
            </Button.Cancel>
          </>
        ) : (
          <>
            <Editor
              height={200}
              value={contentNote}
              onChange={(value) => setContentNote(value)}
            ></Editor>

            <SubmitGroupButton>
              <Button.Default onClick={contentNote !== '' ? () => handleAddNewNote() : undefined}>
                Lưu
              </Button.Default>
              <Button.Cancel onClick={() => setAddNewNote(false)}>Huỷ</Button.Cancel>
            </SubmitGroupButton>
          </>
        )}
      </NoteStyle.Content>
    </NoteStyle.Wrapper>
  );
};

export default AddNewNote;
