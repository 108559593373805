import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentExerciseId } from 'store/class.slice';
import styled from 'styled-components';
import Tabs from 'components/commons/Tabs';
import ExerciseSubmitForm from './ExerciseSubmitForm';
import ExerciseHistorySubmitted from './ExerciseHistorySubmitted';
import ShowMarkdown from 'libraries/components/markdown/ShowMarkdown';
import ExerciseDiscuss from './ExerciseDiscuss';
import { Exercise } from 'models/exercise.model';
import { Badge } from 'antd';
import LectureAttachedFiles from 'pages/learn/LectureAttachedFiles';
import { selectPlayVideo, selectSeekTime } from 'store/onlinecourse.slice';
import VideoPlayer from 'components/video/VideoPlayer';

const { TabPane } = Tabs;

const Wrapper = styled.div`
  & {
    .btn {
      padding: 3px 10px;
      font-size: 14px;
    }
    .content-area {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .answer-area {
      .answer-btn-group {
        display: flex;
        align-items: center;
        gap: 15px;
        h5 {
          margin-bottom: 0;
        }
        @media screen and (max-width: 768px) {
          flex-direction: column;
          align-items: start;
          gap: 10px;
        }
      }
    }
  }
`;

interface PropsType {
  exercise: Exercise;
}

const ExerciseItem = ({ exercise }: PropsType): ReactElement => {
  const dispatch = useDispatch();
  const { id, title, content, history, video, link_youtube, files } = exercise;
  dispatch(setCurrentExerciseId(id));
  const video_url =
    video && video.link && video.link !== '' ? video.link : link_youtube !== '' ? link_youtube : '';
  const playVideo = useSelector(selectPlayVideo);
  const seekTime = useSelector(selectSeekTime);

  return (
    <Wrapper>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Đề bài" key="1">
          <ShowMarkdown markdown={content} />
        </TabPane>
        {video_url && video_url !== '' && (
          <TabPane
            tab={
              <Badge size="small" dot={true} offset={[6, 0]}>
                Video
              </Badge>
            }
            key="2"
          >
            <VideoPlayer url={video_url} seekTime={seekTime} width="50%" playVideo={playVideo} />
          </TabPane>
        )}
        {files && files.length > 0 && (
          <TabPane
            tab={
              <Badge
                size="small"
                count={<span style={{ color: 'red' }}>{files.length > 0 ? files.length : ''}</span>}
                offset={[6, 0]}
              >
                File đính kèm
              </Badge>
            }
            key="3"
          >
            <LectureAttachedFiles files={files} />
          </TabPane>
        )}
        <TabPane disabled={!exercise.can_submit} tab="Nộp bài" key="4">
          <ExerciseSubmitForm id={id} />
        </TabPane>
        {history?.length > 0 && (
          <TabPane tab="Lịch sử nộp bài" key="5">
            <ExerciseHistorySubmitted history={history} />
          </TabPane>
        )}
        <TabPane tab="Trao đổi với giảng viên" key="6">
          <ExerciseDiscuss exerciseId={id} exercise_name={title} />
        </TabPane>
      </Tabs>
    </Wrapper>
  );
};

export default ExerciseItem;
