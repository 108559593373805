import EmptyData from 'components/commons/EmptyData';
import ShowNotFound from 'components/commons/ShowNotFound';
import { Lesson } from 'models/class.model';
import { ReactElement, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useGetLecturesByClassIdQuery } from 'services/class.service';
import Main from '../Main';
import GetModules from './GetModules';

const ModulePage = (): ReactElement => {
  const { classroom: cls } = useParams<{ classroom: string }>();

  const { data, isError, isLoading, error } = useGetLecturesByClassIdQuery({id: cls, types: ""});

  const lessons = data ? data.lessons : ([] as Lesson[]);

  const mainRef = useRef<HTMLDivElement>(null);

  // Auto scroll to bottom
  useEffect(() => {
    const element: HTMLDivElement | null = mainRef.current;
    if (element) element.scroll({ top: element.scrollHeight, behavior: 'smooth' });
  }, [isLoading]);

  if (isError) {
    return (
      <Main>
        <ShowNotFound error={error} />
      </Main>
    );
  }

  return (
    <Main ref={mainRef}>
      {lessons.length > 0 ? <GetModules /> : <EmptyData title="Lớp học chưa có bài giảng nào" />}
    </Main>
  );
};

export default ModulePage;
