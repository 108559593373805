import ShowMarkdown from 'libraries/components/markdown/ShowMarkdown';
import { Lecture } from 'models/class.model';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  iQuiz,
  iQuizItem,
  resetQuizList,
  resetQuizState,
  selectCourse,
  selectLecture,
  selectQuiz,
  setCurrentQuestion,
  setIsAnswered,
  updateLearned,
} from 'store/onlinecourse.slice';
import styled from 'styled-components';
import EmptyData from '../../../components/commons/EmptyData';
import { ILocalStorageProps } from '../menu/LessonsMenu';
import QuizItem from './QuizItem';
const QuizContainer = styled.div`
  width: 100%;
  // max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  word-wrap: break-word;
`;
const ExplainStyle = styled.div`
  @media (min-width: 992px) {
    margin-left: 24px;
  }
  @media (max-width: 576px) {
    margin-top: 12px;
  }
`;

const QuizContent = ({ quizes }: Lecture): ReactElement => {
  const history = useHistory();
  const url = window.location.href;
  const urlObject = new URL(url);
  const question = urlObject.searchParams.get('question') || 1;
  const currentLocation = history.location;
  if (!quizes || !quizes.length)
    return (
      <QuizContainer>
        <EmptyData />
      </QuizContainer>
    );
  const dispatch = useDispatch();
  const { learned } = useSelector(selectCourse);
  const [isCompleteLocal, setisCompleteLocal] = useState<boolean | undefined>();
  const [scoreLocal, setScoreLocal] = useState<number | undefined>();
  const quizesArr = useSelector(selectQuiz);
  const { id } = useSelector(selectLecture);
  useEffect(() => {
    resetQuiz();
  }, []);
  const defaultQuizesArr = useMemo(
    (): iQuiz => ({
      id,
      score: 0,
      isComplete: false,
      currentQuestion: 0,
      messaged: false,
      item: Array(quizes?.length)
        ?.fill(null)
        ?.map(
          (_, index): iQuizItem => ({
            id: quizes[index]?.id,
            isAnswered: false,
            isCorrect: false,
            notAnswer: true,
          }),
        ),
    }),
    [],
  );
  const currentQuiz = quizesArr.find((item: iQuiz) => item.id == id) ?? defaultQuizesArr;
  const { score, isComplete, currentQuestion, messaged, item } = currentQuiz ?? defaultQuizesArr;
  const { isAnswered } = item[+question - 1] ?? defaultQuizesArr.item[+question - 1];
  const [isMobile, setIsMobile] = useState<boolean>();
  const requiredActionProps = { id: id, itemId: item[+question - 1].id };

  // add to local để tính progress bar
  const addToLocal = () => {
    const json = localStorage.getItem('checkbox');
    if (json) {
      const items = JSON.parse(json);
      const isItemExist = items.some((item: ILocalStorageProps) => item.id === id);

      if (!isItemExist) {
        localStorage.setItem('checkbox', JSON.stringify([...items, { id: id, score: score }]));
        dispatch(updateLearned(learned + 1));
      }
    }
  };
  const deleteFrLocal = () => {
    const json = localStorage.getItem('checkbox');
    if (json) {
      const item = JSON.parse(json);
      const newArr = item.filter((el: ILocalStorageProps) => el.id !== id);
      localStorage.setItem('checkbox', JSON.stringify(newArr));
      setScoreLocal(undefined);
      setisCompleteLocal(undefined);
      dispatch(updateLearned(learned - 1));
    }
  };

  const updateAnwser = () => {
    dispatch(setCurrentQuestion({ ...requiredActionProps, currentQuestion: +question - 1 }));
    dispatch(setIsAnswered({ ...requiredActionProps, isAnswered: true }));
  };

  const resetQuiz = () => {
    window.localStorage.removeItem('AnwserQuiz');
    history.push({
      pathname: currentLocation.pathname,
      search: `question=1`,
    });
    const json = localStorage.getItem('checkbox');
    if (json) {
    }
    dispatch(resetQuizState({ ...requiredActionProps }));
    dispatch(resetQuizList());

    if (score === quizes.length || scoreLocal === quizes.length) {
      deleteFrLocal();
    }
  };

  const onPageChange = (page: number): void => {
    //  page tinh tu 1, nen - 1
    dispatch(setCurrentQuestion({ ...requiredActionProps, currentQuestion: page - 1 }));
  };
  useEffect(() => {
    setIsMobile(window.matchMedia('(max-width: 576px)').matches);
    window.addEventListener('resize', () =>
      setIsMobile(window.matchMedia('(max-width: 576px)').matches),
    );

    return () => {
      window.removeEventListener('resize', () =>
        setIsMobile(window.matchMedia('(max-width: 576px)').matches),
      );
    };
  }, [isMobile]);

  // * Lưu score và local ở local đểlàm progress bar
  useEffect(() => {
    const json = localStorage.getItem('checkbox');
    if (json) {
      const items = JSON.parse(json);
      const foundItem = items.find((item: ILocalStorageProps) => item.id === id);
      foundItem && setisCompleteLocal(true);
      if (foundItem) {
        setisCompleteLocal(true);
        setScoreLocal(foundItem.score);
      }
    }
  }, []);

  // useEffect(() => {
  //   if (isComplete) {
  //     if (score === quizes.length) {
  //       !messaged && message.success('Chúc mừng bạn đã vượt qua bài trắc nghiệm');
  //       addToLocal();
  //       dispatch(setMessaged({ ...requiredActionProps }));
  //     } else {
  //       !messaged && message.error('Rất tiếc bạn chưa vượt qua bài trác nghiệm. Hãy thử lại nhé');
  //       dispatch(setMessaged({ ...requiredActionProps }));
  //     }
  //   }
  // }, [isComplete]);

  return (
    <QuizContainer>
      {quizes ? (
        <>
          <QuizItem
            isCompleteLocal={isCompleteLocal}
            scoreLocal={scoreLocal}
            currentQuiz={currentQuiz}
            currentQuizItem={item[+question - 1]}
            defaultQuizesArr={defaultQuizesArr}
            quizes={quizes}
            currentQuestion={+question - 1}
            resetQuiz={resetQuiz}
            updateAnwser={updateAnwser}
          />
          <div style={{ textAlign: 'end' }}></div>

          {/* <PaginationWrapper>
            <Pagination
              current={currentQuestion + 1}
              defaultCurrent={currentQuestion + 1}
              onChange={onPageChange}
              showTotal={(total) => (isMobile ? undefined : `Tất cả ${total} câu quizes`)}
              showQuickJumper
              showSizeChanger={false}
              pageSize={1}
              total={quizes.length}
              responsive
              itemRender={(page, type, originalElement) => {
                // page tinh tu 1 , nen -1
                if (page && item && type == 'page') {
                  if (item[page - 1].isAnswered && item[page - 1].isCorrect) {
                    return <PageBtn className="correct">{originalElement}</PageBtn>;
                  }
                  if (item[page - 1].isAnswered && !item[page - 1].isCorrect) {
                    return <PageBtn className="wrong">{originalElement}</PageBtn>;
                  }
                  return <PageBtn>{originalElement}</PageBtn>;
                }

                if (item && type == 'jump-next') {
                  return <>{originalElement}</>;
                }
                if (item && type == 'jump-prev') {
                  return <>{originalElement}</>;
                }
                if (item && type == 'prev') {
                  return <PageBtn>{originalElement}</PageBtn>;
                }
                if (item && type == 'next') {
                  return <PageBtn>{originalElement}</PageBtn>;
                }
              }}
            />
          </PaginationWrapper> */}

          {quizes[+question - 1].explannation !== '' && isAnswered && (
            <ExplainStyle>
              <p>
                <strong>Hướng dẫn:</strong>
              </p>
              <ShowMarkdown markdown={quizes[+question - 1].explannation} />
            </ExplainStyle>
          )}
        </>
      ) : (
        <>
          <EmptyData />
        </>
      )}
    </QuizContainer>
  );
};

const PageBtn = styled.span`
  a {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    @media screen and (max-width: 567px) {
      border-color: transparent;
    }
  }
`;
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  transition: all 1s ease-in-out;
  .ant-pagination-item {
    border: unset;
  }
  @media screen and (max-width: 567px) {
    .ant-pagination-total-text {
      display: none;
    }
  }
  @media screen and (max-width: 567px) {
    transition: all 1s ease-in-out;
    .ant-pagination-item:hover,
    .ant-pagination-item {
      ${PageBtn}.correct a {
        color: rgb(38, 166, 154);
      }
      ${PageBtn}.wrong a {
        border-color: transparent;
        color: rgb(229, 115, 115);
      }
    }
    .ant-pagination-item:hover {
      ${PageBtn} a {
        border-color: transparent;
        color: #1890ff;
      }
    }
    .ant-pagination-item-active,
    .ant-pagination-item-active:hover {
      ${PageBtn} a {
        border-color: #1890ff;
        background: #f5fdff;
      }
      ${PageBtn}.correct a {
        border-color: rgb(38, 166, 154);
        background-color: #d8ecd2;
        color: rgb(38, 166, 154);
      }
      ${PageBtn}.wrong a {
        border-color: rgb(229, 115, 115);
        background-color: #f9e0df;
        color: rgb(229, 115, 115);
      }
    }
  }
  @media screen and (min-width: 568px) {
    .ant-pagination-item {
      ${PageBtn}.correct a {
        border-color: rgb(38, 166, 154);
        background-color: unset;
        color: rgb(38, 166, 154);
      }
      ${PageBtn}.wrong a {
        border-color: rgb(229, 115, 115);
        background-color: unset;
        color: rgb(229, 115, 115);
      }
    }
    .ant-pagination-item:hover {
      ${PageBtn} a {
        border-color: #1890ff;
      }
    }
    .ant-pagination-item-active {
      ${PageBtn} a {
        border-color: #1890ff;
        background: #f5fdff;
      }
    }
  }
  .ant-pagination-item-active,
  .ant-pagination-item:hover {
    @media screen and (min-width: 568px) {
      ${PageBtn}.correct a {
        border-color: rgb(38, 166, 154);
        background-color: #d8ecd2;
        color: rgb(38, 166, 154);
      }
      ${PageBtn}.wrong a {
        border-color: rgb(229, 115, 115);
        background-color: #f9e0df;
        color: rgb(229, 115, 115);
      }
    }
  }
`;
export default QuizContent;
