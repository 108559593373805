import { UpCircleOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import LoadingSpin from 'libraries/components/LoadingSpin';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router';
import { useGetPracticeQuery } from 'services/class.service';
import { selectPractice } from 'store/practice.slice';
import styled from 'styled-components';
import scrollToTop from 'utils/scrollToTop';
import QuizzList from './QuizzList';
import QuizzListNoTime from './QuizzListNoTime';
import QuizzPracticeContent from './QuizzPracticeContent';
import QuizzPracticeContentNoTime from './QuizzPracticeContentNoTime';
const PracticeContainer = styled.div`
  background-color: #f2f2f2;
  margin: 24px -10px 0 -10px;
  display: flex;
  justify-content: center;
`;
const PracticeContent = styled.div`
  background-color: #fff;
  max-width: 1300px;
  @media (min-width: 1300px) {
    min-width: 1300px;
  }
  @media (min-width: 1200px) {
    min-width: 1200px;
  }
  margin: 24px 0;
`;
const PracticePage = (): ReactElement => {
  const { classroom: cls } = useParams<{ classroom: string }>();
  const { practiceId: practice_id } = useParams<{ practiceId: string }>();
  const {
    data: practice,
    refetch,
    isLoading,
  } = useGetPracticeQuery({
    classId: cls,
    practiceId: practice_id,
  });
  const { end_time, quizzes, is_submit } = useSelector(selectPractice);
  const { type } = useSelector(selectPractice);
  return (
    <div>
      {isLoading ? (
        <LoadingSpin text="Đang lấy dữ liệu bài luyện " />
      ) : (
        <PracticeContainer>
          <PracticeContent>
            <Row>
              <Col xs={0} sm={8}>
                {type == 'time' && (
                  <QuizzList end_time={end_time} quizzes={quizzes} is_submit={is_submit} />
                )}
                {type == 'no_time' && <QuizzListNoTime />}
              </Col>
              <Col xs={24} sm={16}>
                {type == 'time' && (
                  <QuizzPracticeContent
                    end_time={end_time}
                    quizzes={quizzes}
                    is_submit={is_submit}
                    refetch={refetch}
                  />
                )}
                {type == 'no_time' && <QuizzPracticeContentNoTime refetch={refetch} />}
              </Col>
            </Row>
          </PracticeContent>
          <div
            style={{ position: 'fixed', bottom: '24px', right: '20px', cursor: 'pointer' }}
            onClick={scrollToTop}
          >
            <UpCircleOutlined
              style={{ fontSize: '40px', background: ' #fff', borderRadius: '50% ' }}
            />
          </div>
        </PracticeContainer>
      )}
    </div>
  );
};

export default PracticePage;
