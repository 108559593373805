// neededRoles: các Role yêu cầu user có để thực hiện 1 chức năng

import { useSelector } from 'react-redux';
import { selectUser } from 'store/auth.slice';

const checkRoles = (neededRoles: number[], roles: number[]): boolean => {
  for (let i = 0; i < roles.length; i++) {
    for (let j = 0; j < neededRoles.length; j++) {
      if (roles[i] === neededRoles[j]) return true;
    }
  }
  return false;
};

const checkUserRole = (neededRoles: number[]): boolean => {
  const { roles } = useSelector(selectUser);
  const checkRole = checkRoles(neededRoles, roles);

  return checkRole;
};

export default checkUserRole;
