import { ReactElement } from 'react';
import { Result } from 'antd';
import styled from 'styled-components';

interface PropsType {
  title: string;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmptyData = ({ title }: PropsType): ReactElement => {
  return (
    <Container>
      <Result title={title} />
    </Container>
  );
};

EmptyData.defaultProps = {
  title: 'Chưa có dữ liệu nào.',
};

export default EmptyData;
