import { ReactElement, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';
import { setPlayedSeconds, setPlayVideo } from 'store/onlinecourse.slice';
import styled from 'styled-components';

interface PropsType {
  url: string;
  seekTime: number;
  width?: string;
  playVideo: boolean;
}

const Wrapper = styled.div`
  padding-inline: 20%;
  padding-top: 8px;
  .video-content {
    position: relative;
    padding-top: 56.25%;
    height: 100% !important;
  }
  .video-content > div {
    inset: 0;
    position: absolute;
  }

  .video-content > video {
    position: absolute;
    inset: 0;
  }

  @media (max-width: 768px) {
    padding-inline: 8%;
  }
`;

const VideoPlayer = ({ url, seekTime, width, playVideo }: PropsType): ReactElement => {
  const dispatch = useDispatch();
  const playerRef = useRef<ReactPlayer>(null);

  useEffect(() => {
    playerRef.current && playerRef.current.seekTo(seekTime, 'seconds');
  }, [seekTime]);

  return (
    <Wrapper>
      <ReactPlayer
        url={url}
        controls={true}
        className="video-content"
        width={width}
        style={{ maxWidth: '100%' }}
        playing={playVideo}
        onProgress={(state) => dispatch(setPlayedSeconds(state.playedSeconds))}
        onPlay={() => dispatch(setPlayVideo(true))}
        onPause={() => dispatch(setPlayVideo(false))}
        config={{
          file: {
            hlsOptions: {
              // eslint-disable-next-line
              xhrSetup: function xhrSetup(xhr: any) {
                xhr.withCredentials = true;
                xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
              },
            },
          },
        }}
      />
    </Wrapper>
  );
};

VideoPlayer.defaultProps = {
  width: '100%',
};

export default VideoPlayer;
