import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  Checkbox,
  Col,
  Divider,
  Form,
  message,
  Popconfirm,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
} from 'antd';
import Button from 'libraries/components/commons/Button';
import ShowMarkdown from 'libraries/components/markdown/ShowMarkdown';
import QuizGroup from 'pages/learn/quiz/QuizGroup';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import {
  selectPractice,
  selectTimeSubmit,
  selectUpdateLocalStorage,
  updateIsSubmit,
  updateIsTimeSubmit,
  updateQuizAfterSelect,
  updateShowAnswer,
} from 'store/practice.slice';
import styled from 'styled-components';

import {
  useGetPracticeQuery,
  useSendAnswerQuizMutation,
  useSubmitPracticeMutation,
} from 'services/class.service';
import SubQuizContent from './SubQuizContent';
import SubQuizContentNoTime from './SubQuizContentNoTime';
import getNumberQuizNoHasAnswer, {
  getNumberQuizNoHasAnswer1,
} from 'utils/getNumberQuizNoHasAnswer';
import updateQuizAnswers from 'utils/updateQuizAnswers';
interface props {
  refetch: any;
}
const QuizzContent = styled.div`
  padding: 24px;
  h2 {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
  }
  .Is_single {
    font-size: 18px;
    margin-left: 12px;
    background-color: #ccc;
    padding: 4px 8px;
  }
`;
const FlexStyle = styled.div`
  display: flex;
  align-items: center;
`;
const QuizAnwser = styled.div``;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 455px) {
    flex-direction: column;
  }
  button {
    padding: 6px 24px;
    height: 40px;
    margin-top: 12px;
  }
`;

const getAnswertrue = (arrayAnswer: any) => {
  return arrayAnswer.filter((item: any) => item.is_true);
};

const QuizzPracticeContentNoTime = ({ refetch }: props): ReactElement => {
  const [values, setValues] = useState<any>();

  const [is_true_value, setIs_true_value] = useState<any>();
  const [arrIdQuizShow, setArrIdQuizShow] = useState<any>(
    JSON.parse(localStorage.getItem('ArrIdQuizShow') || '[]'),
  );
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const question = query.get('question');

  const { classroom: cls } = useParams<{ classroom: string }>();
  const { practiceId: practice_id } = useParams<{ practiceId: string }>();

  const { quizzes, is_submit } = useSelector(selectPractice);
  const is_time_submit = useSelector(selectTimeSubmit);
  const [sendAnswerQuiz] = useSendAnswerQuizMutation();
  const [submitPractice] = useSubmitPracticeMutation();
  const updateLocalStorage = useSelector(selectUpdateLocalStorage);
  useEffect(() => {
    if (!question || !quizzes) return;
    const quizzItem = quizzes[+question - 1];
    setValues(() => quizzItem.answers_choose);
    localStorage.setItem('question', question);
  }, [question]);

  useEffect(() => {
    if (!is_time_submit || is_submit) return;
    dispatch(updateIsSubmit(true));
    dispatch(updateIsTimeSubmit(false));
  }, [is_submit]);

  useEffect(() => {
    if (!question || !quizzes) return;
    const quizzItem = quizzes[+question - 1];
    if (quizzItem.has_answer == false) {
      setIs_true_value(undefined);
    } else {
      setIs_true_value(quizzItem.is_true);
    }
  }, [question]);
  if (!question || !quizzes) return <></>;

  const quizzItem = quizzes[+question - 1];
  const {
    is_single,
    content,
    answers,
    id,
    answers_choose,
    has_answer,
    explannation,
    is_true,
    quizzes: Subquizzes,
  } = quizzItem;
  const text = 'Bạn có chắc chắn muốn kết thúc bài kiểm tra này?';

  const confirm = () => {
    handleSubmitAll();
  };
  const handleClickConfirm = () => {
    // refetch();
  };
  const changeQuestion = (newQuestion: number) => {
    const currentLocation = history.location;
    const currentSearch = currentLocation.search;
    const searchParams = new URLSearchParams(currentSearch);
    searchParams.set('question', newQuestion.toString());
    history.push({
      pathname: currentLocation.pathname,
      search: searchParams.toString(),
    });
  };
  const handlePrevQuiz = () => {
    if (+question == 1) return;
    changeQuestion(+question - 1);
  };
  const handleNextQuiz = () => {
    if (+question == quizzes.length) return;
    changeQuestion(+question + 1);
  };
  function handleShowAnwser() {
    dispatch(updateShowAnswer(!updateLocalStorage));

    if (
      getNumberQuizNoHasAnswer1(quizzItem) !== 0 ||
      (quizzItem.has_answer == false && quizzItem.answers)
    ) {
      message.error('Vui lòng điền đủ đáp án để xem câu trả lời!');
      return;
    }
    if (!arrIdQuizShow.includes(id)) {
      setArrIdQuizShow([...arrIdQuizShow, id]);
    }
    localStorage.setItem('ArrIdQuizShow', JSON.stringify([...arrIdQuizShow, id]));
  }

  function handleSubmit() {
    if ((!values && answers) || (values?.length == 0 && answers)) {
      message.error('Vui lòng chọn 1 đáp án để trả lời');
      return;
    }
    if (answers) {
      sendAnswerQuiz({
        class_id: cls,
        practice_id,
        data: {
          answers: values,
          quiz_id: id,
        },
      })
        .unwrap()
        .then((data: any) => {
          dispatch(updateQuizAfterSelect({ quizzesId: id, has_answer: true }));
          setIs_true_value(data.is_true);
        })
        .catch((error) => {
          message.error(error.data);
        });
    }
  }
  function handleSubmitAll() {
    localStorage.setItem('ArrIdQuizShow', '[]');
    submitPractice({
      classId: cls,
      practiceId: practice_id,
    })
      .unwrap()
      .then((data: any) => {
        message.success('Nộp bài thành công!');
        refetch();
      })
      .catch((error: any) => {
        message.error(error.data);
      });
  }

  const onChangeCheckBox = (checkedValues: any) => {
    setValues(checkedValues);
    if (answers) {
      sendAnswerQuiz({
        class_id: cls,
        practice_id,
        data: {
          answers: checkedValues,
          quiz_id: id,
        },
      })
        .unwrap()
        .then((data: any) => {
          setIs_true_value(data.is_true);
          dispatch(
            updateQuizAfterSelect({
              quizzesId: id,
              is_true: data.is_true,
              has_answer: true,
              answers_choose: checkedValues,
              answers: updateQuizAnswers(answers, data.real_answers),
              explannation: data.explannation,
            }),
          );
        })
        .catch((error) => {
          message.error(error.data);
        });
    }
  };
  const onChange = (e: RadioChangeEvent) => {
    setValues([e.target.value]);
    if (answers) {
      sendAnswerQuiz({
        class_id: cls,
        practice_id,
        data: {
          answers: [e.target.value],
          quiz_id: id,
        },
      })
        .unwrap()
        .then((data: any) => {
          setIs_true_value(data.is_true);
          dispatch(
            updateQuizAfterSelect({
              quizzesId: id,
              is_true: data.is_true,
              has_answer: true,
              answers_choose: [e.target.value],
              answers: updateQuizAnswers(answers, data.real_answers),
              explannation: data.explannation,
            }),
          );
        })
        .catch((error) => {
          message.error(error.data);
        });
    }
  };

  return (
    <QuizzContent>
      <FlexStyle>
        <h2>Đề bài {question}</h2>
        {/* <span className="Is_single">{is_single ? '1 đáp án' : 'Nhiều đáp án'}</span> */}
      </FlexStyle>

      <div>
        <ShowMarkdown markdown={content} />
      </div>
      <Divider />

      {!is_submit && answers && (
        <>
          {!arrIdQuizShow.includes(id) && (
            <>
              <QuizAnwser>
                {is_single ? (
                  <Form>
                    <Form.Item name="answers">
                      <QuizGroup.Radio>
                        <Radio.Group
                          onChange={onChange}
                          value={values ? values[0] : false}
                          style={{ display: 'block' }}
                        >
                          <Space direction="vertical">
                            {answers?.map((item: any) => (
                              <Radio
                                value={item.id}
                                key={item.id}
                                className={
                                  is_true_value == undefined
                                    ? ''
                                    : arrIdQuizShow.includes(id) &&
                                      values?.includes(item.id) &&
                                      is_true_value
                                      ? 'right-answer'
                                      : arrIdQuizShow.includes(id) &&
                                        values?.includes(item.id) &&
                                        !is_true_value
                                        ? 'wrong-answer'
                                        : ''
                                }
                                disabled={arrIdQuizShow.includes(id) || is_submit}
                              >
                                <ShowMarkdown markdown={item.content} />
                                <CheckOutlined />
                                <CloseOutlined />
                              </Radio>
                            ))}
                          </Space>
                        </Radio.Group>
                      </QuizGroup.Radio>
                    </Form.Item>
                  </Form>
                ) : (
                  <Form>
                    <Form.Item className="multiple" name="answerCheckboxChosen">
                      <QuizGroup.Checkbox style={{ width: '100%', display: 'block' }}>
                        <Checkbox.Group
                          style={{ width: '100%', display: 'block' }}
                          value={values ? values : []}
                          onChange={onChangeCheckBox}
                        >
                          <Row>
                            {answers?.map((item: any) => (
                              <Col span={24} key={item.id}>
                                <Checkbox
                                  checked={true}
                                  value={item.id}
                                  className={
                                    arrIdQuizShow.includes(id) &&
                                      values?.includes(item.id) &&
                                      is_true_value
                                      ? 'right-answer'
                                      : arrIdQuizShow.includes(id) &&
                                        values?.includes(item.id) &&
                                        !is_true_value
                                        ? 'wrong-answer'
                                        : ''
                                  }
                                  disabled={arrIdQuizShow.includes(id) || is_submit}
                                >
                                  <ShowMarkdown markdown={item.content} />
                                  <CheckOutlined />
                                  <CloseOutlined />
                                </Checkbox>
                              </Col>
                            ))}
                          </Row>

                        </Checkbox.Group>
                      </QuizGroup.Checkbox>
                    </Form.Item>

                    {/* {submitButton} */}
                  </Form>
                )}
              </QuizAnwser>
              <Divider />

              {!arrIdQuizShow.includes(id) && !is_submit && (
                <>
                  <Button.History
                    style={{ padding: '0  40px', height: '40px' }}
                    onClick={handleShowAnwser}
                  >
                    Mở đáp án
                  </Button.History>
                  <Divider />
                </>
              )}
            </>
          )}

          {/*  hiển thị sau khi mở đáp án  */}
          {arrIdQuizShow.includes(id) && (
            <>
              <Form.Item className="multiple" name="answerCheckboxChosen">
                <QuizGroup.Checkbox style={{ width: '100%', display: 'block' }}>
                  <Checkbox.Group
                    style={{ width: '100%', display: 'block' }}
                    value={values ? values : []}
                    onChange={onChangeCheckBox}
                  >
                    <Row>
                      {answers?.map((item: any) => {
                        const isSelected = values?.includes(item.id);
                        const isCorrected = item.is_true;
                        return (
                          <Col span={24} key={item.id}>
                            <Checkbox
                              checked={isSelected}
                              value={item.id}
                              className={
                                (is_submit && isSelected) || (arrIdQuizShow.includes(id) && isSelected)
                                  ? isCorrected
                                    ? 'right-answer'
                                    : 'wrong-answer'
                                  : ''
                              }
                              disabled={arrIdQuizShow.includes(id) || is_submit}
                            >
                              <ShowMarkdown markdown={item.content} />

                              <CloseOutlined />
                            </Checkbox>
                            {arrIdQuizShow.includes(id) && isCorrected && (
                              <CheckOutlined
                                style={{
                                  color: '#52c41a',
                                  position: 'absolute',
                                  fontSize: '25px', marginTop: '-52px',
                                  right: '11px'
                                }}
                              />
                            )}
                          </Col>
                        );
                      })}

                    </Row>

                  </Checkbox.Group>
                </QuizGroup.Checkbox>
              </Form.Item>

              {explannation !== '' && (
                <div>
                  <h4>Giải thích:</h4>
                  <ShowMarkdown markdown={explannation} />
                </div>
              )}
              <Divider />
            </>
          )}



        </>
      )}
      {/* hiển thị sau khi kết thúc luyện tập */}
      {is_submit && (
        <>

          <Form.Item className="multiple" name="answerCheckboxChosen">
            <QuizGroup.Checkbox style={{ width: '100%', display: 'block' }}>
              <Checkbox.Group
                style={{ width: '100%', display: 'block' }}
                value={values ? values : []}
                onChange={onChangeCheckBox}
              >

                <Row>
                  {answers?.map((item: any) => {
                    const isSelected = values?.includes(item.id);
                    const isCorrect = item.is_true;
                    return (
                      <Col span={24} key={item.id}>
                        <Checkbox
                          checked={true}
                          value={item.id}
                          className={
                            is_submit && isSelected
                              ? isCorrect
                                ? 'right-answer'
                                : 'wrong-answer'
                              : ''
                          }
                          disabled={is_submit || !quizzes}
                        >
                          <ShowMarkdown markdown={item.content} />
                          {/*   <CheckOutlined /> */}
                          <CloseOutlined />

                        </Checkbox>
                        {is_submit && (
                          <span className={`correct-answer ${isCorrect ? 'show' : ''}`}>
                            {isCorrect && <CheckOutlined style={{ color: '#52c41a', position: 'absolute', marginTop: '-52px', fontSize: '25px', right: '11px' }} />}
                          </span>
                        )}
                      </Col>
                    );
                  })}
                </Row>

              </Checkbox.Group>
            </QuizGroup.Checkbox>
          </Form.Item>
          {explannation != '' && (
            <div>
              <h4>Giải thích:</h4>

              <ShowMarkdown markdown={explannation} />
            </div>
          )}
          <Divider />
        </>
      )}

      {/* {Subquizzes &&
        Subquizzes?.map((quizItem: any, i: any) => (
          <SubQuizContentNoTime
            key={i}
            quizItem={quizItem}
            numberQuesiton={i + 1}
            has_answer={has_answer}
            showAnwser={arrIdQuizShow.includes(id)}
          />
        ))}
      {Subquizzes && !arrIdQuizShow.includes(id) && !is_submit && (
        <>
          <Button.History style={{ padding: '0  40px', height: '40px' }} onClick={handleShowAnwser}>
            Mở đáp án
          </Button.History>
          <Divider />
        </>
      )} */}
      <ButtonContainer>
        <Button.Outline disabled={+question == 1 ? true : false} onClick={handlePrevQuiz}>
          Câu trước
        </Button.Outline>

        <Button.Outline
          onClick={handleNextQuiz}
          disabled={+question == quizzes?.length ? true : false}
        >
          Câu sau
        </Button.Outline>
        {!is_submit && (
          <Popconfirm
            placement="top"
            title={
              <>
                {getNumberQuizNoHasAnswer(quizzes) != 0 && (
                  <>
                    <span style={{ fontSize: '16px', fontWeight: '600' }}>
                      Còn {getNumberQuizNoHasAnswer(quizzes)} câu chưa trả lời
                    </span>
                    <br />
                  </>
                )}
                Bạn có chắc chắn muốn kết thúc bài kiểm tra này?
              </>
            }
            onConfirm={confirm}
            okText="Có"
            cancelText="Không"
          >
            <Button.Primary onClick={handleClickConfirm}>Kết thúc luyện tập</Button.Primary>
          </Popconfirm>
        )}
        {is_submit && <Button.Primary disabled={true}>Kết thúc luyện tập</Button.Primary>}
      </ButtonContainer>
    </QuizzContent>
  );
};

export default QuizzPracticeContentNoTime;
