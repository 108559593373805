import { ReactElement } from 'react';
import {
  BookOutlined,
  YoutubeOutlined,
  UserOutlined,
  DollarCircleOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { hideDrawer, selectOpenDrawer } from 'store/general.slice';
import { useLocation } from 'react-router-dom';
import { home } from 'routers/routeNames';
import Icon from 'components/commons/Icon';
import NavLink from 'components/commons/NavLink';
import NavItem from 'components/commons/NavItem';
import NavMenu from 'components/commons/NavMenu';

const links = [
  {
    path: home.index,
    label: 'Lớp học của tôi',
    icon: BookOutlined,
  },
  {
    path: home.onlineCourse,
    label: 'Khóa học trực tuyến',
    icon: YoutubeOutlined,
  },
  {
    path: home.transaction,
    label: 'Lịch sử giao dịch',
    icon: DollarCircleOutlined,
  },
  {
    path: home.profile,
    label: 'Hồ sơ cá nhân',
    icon: UserOutlined,
  },
  {
    path: home.security,
    label: 'Bảo mật tài khoản',
    icon: LockOutlined,
  },
];

const Nav = (): ReactElement => {
  const openDrawer = useSelector(selectOpenDrawer);
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <nav>
      <NavMenu
        selectedKeys={[location.pathname]}
        onClick={() => {
          openDrawer && dispatch(hideDrawer());
        }}
      >
        {links.map(({ path, label, icon }) => (
          <NavItem key={path}>
            <NavLink to={path}>
              <Icon.NavIcon icon={icon} />
              {label}
            </NavLink>
          </NavItem>
        ))}
      </NavMenu>
    </nav>
  );
};

export default Nav;
