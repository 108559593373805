import { ReactElement } from 'react';

interface ModuleNoteProp {
  title: string;
}

const ModuleNote = ({ title }: ModuleNoteProp): ReactElement => {
  return (
    <div>
      <span>{title}</span>
    </div>
  );
};

export default ModuleNote;
