import { Teacher } from 'models/user.model';
import { ReactElement } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import styled from 'styled-components';

interface PropsType {
  teachers: Teacher[];
}

const CommonInfoContainer = styled.div`
  img {
    width: 100px;
    display: inline-block;
    border-radius: 50%;
  }
  p,
  a {
    margin: 0;
    font-size: 16px;
  }
  .ant-table-cell {
    text-align: center;
  }
`;

const columns: ColumnsType<Teacher> = [
  {
    title: 'Ảnh đại diện',
    dataIndex: 'avatar',
    render: (avatar) => <img src={avatar} />,
  },
  {
    title: 'Họ tên ',
    dataIndex: 'name',
    render: (name) => <p>{name}</p>,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    render: (email) => <a href={`mailto:${email}`}>{email}</a>,
  },
];

const CommonInfo = ({ teachers }: PropsType): ReactElement => {
  return (
    <CommonInfoContainer>
      <Table columns={columns} dataSource={teachers} bordered pagination={false} rowKey="email" />
    </CommonInfoContainer>
  );
};

export default CommonInfo;
