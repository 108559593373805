import { Teacher } from 'models/user.model';
import { ReactElement, useState } from 'react';
import { Button, message, Rate, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import styled from 'styled-components';
import TextArea from 'antd/lib/input/TextArea';
import { useSelector } from 'react-redux';
import { selectClass } from 'store/class.slice';
import { selectUser } from 'store/auth.slice';
import { useSendCourseRateMutation } from 'services/class.service';

const Review = (): ReactElement => {
  const { id: class_id } = useSelector(selectClass);
  const [rateLesson] = useSendCourseRateMutation();
  const [rateCourse, setRateCourse] = useState<any>({
    vote: 0,
    review: '',
  });

  const handleSubmit = () => {
    if (rateCourse.vote == 0) {
      message.error('Vui lòng chọn số sao để đánh giá khóa học');
      return;
    }
    rateLesson({
      id: class_id,
      data: rateCourse,
    })
      .unwrap()
      .then(() => {
        message.success('Cảm ơn vì đánh giá của bạn 😇');
      })
      .catch((error: any) => {
        message.error(error.data);
      });
  };

  return (
    <>
      <Rate onChange={(value) => setRateCourse({ ...rateCourse, vote: value })} />
      <div className="rate-content">
        <h4 style={{ marginTop: '20px' }}>Góp ý chi tiết </h4>
        <TextArea
          rows={4}
          value={rateCourse.review}
          onChange={(e: any) => setRateCourse({ ...rateCourse, review: e.target.value })}
        />
      </div>
      <Button type="primary" onClick={handleSubmit} style={{ marginTop: '20px' }}>
        Đánh giá
      </Button>
    </>
  );
};

export default Review;
