import { ReactElement } from 'react';
import {
  LineChartOutlined,
  ReadOutlined,
  TeamOutlined,
  InfoCircleOutlined,
  FormOutlined,
  PicLeftOutlined,
} from '@ant-design/icons';
import { generatePath, useParams } from 'react-router';
import { classroom } from 'routers/routeNames';
import { useSelector, useDispatch } from 'react-redux';
import { hideDrawer, selectOpenDrawer } from 'store/general.slice';
import Icon from 'components/commons/Icon';
import NavLink from 'components/commons/NavLink';
import NavMenu from 'components/commons/NavMenu';
import NavItem from 'components/commons/NavItem';

const links = [
  {
    path: classroom.index,
    label: 'Tiến độ học tập',
    icon: LineChartOutlined,
  },
  {
    path: classroom.module,
    label: 'Khung chương trình',
    icon: PicLeftOutlined,
  },
  {
    path: classroom.exercise,
    label: 'Bài tập',
    icon: ReadOutlined,
  },
  {
    path: classroom.exam,
    label: 'Luyện thi',
    icon: FormOutlined,
  },
  {
    path: classroom.ranking,
    label: 'Bảng xếp hạng',
    icon: TeamOutlined,
  },
  {
    path: classroom.info,
    label: 'Thông tin chung',
    icon: InfoCircleOutlined,
  },
];

const Nav = (): ReactElement => {
  const { classroom: cls } = useParams<{ classroom: string }>();

  const openDrawer = useSelector(selectOpenDrawer);
  const dispatch = useDispatch();

  return (
    <nav>
      <NavMenu
        onClick={() => openDrawer && dispatch(hideDrawer())}
        selectedKeys={[location.pathname]}
      >
        {links.map(({ path, label, icon }) => (
          <NavItem key={generatePath(path, { classroom: cls })}>
            <NavLink to={generatePath(path, { classroom: cls })}>
              <Icon.NavIcon icon={icon} />
              {label}
            </NavLink>
          </NavItem>
        ))}
      </NavMenu>
    </nav>
  );
};

Nav.defaultProps = {
  mode: 'horizontal',
};

export default Nav;
