import { Upload, message } from 'antd';
import { useState, ReactElement } from 'react';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { useUploadImageMutation } from 'libraries/services/media.service';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserAvatar, setUserAvatar } from 'store/auth.slice';
import ImgCrop from 'antd-img-crop';
import { RcFile } from 'antd/es/upload/interface';
import { Image } from 'libraries/models/media.model';
import styled from 'styled-components';

const UploadButton = styled(Upload)`
  &&& .ant-upload-select-picture-card {
    width: 200px !important;
    height: 46px !important;
    margin-top: 20px;
  }
`;

interface ChangeAvatarProps {
  uploading: boolean;
  setUploading: (value: boolean) => void;
  updateUserAvatar: (value: string) => void;
}
const acceptTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];
const checkFileType = (type: string) => {
  return acceptTypes.includes(type);
};

const ChangeAvatar = ({ uploading, updateUserAvatar }: ChangeAvatarProps): ReactElement => {
  const avatar = useSelector(selectUserAvatar);
  const dispatch = useDispatch();

  const [image, setImage] = useState<RcFile>({} as RcFile);
  const [updateAvatar] = useUploadImageMutation();

  const beforeUpload = (file: RcFile) => {
    if (checkFileType(file.type)) {
      setImage(file);
    } else {
      message.error(`${file.name} không phải định dạng cho phép upload`);
    }
    return checkFileType(file.type) ? false : Upload.LIST_IGNORE;
  };

  const handleUpdateAvatar = () => {
    const formData = new FormData();
    formData.append('upload', image);

    updateAvatar({ data: formData })
      .unwrap()
      .then((image: Image) => {
        dispatch(setUserAvatar(image.url));
        updateUserAvatar(image.url);
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  const uploadButton = (
    <div>
      {uploading ? (
        <LoadingOutlined />
      ) : (
        <div>
          <UploadOutlined /> Thay đổi ảnh đại diện
        </div>
      )}
    </div>
  );

  return (
    <>
      <img style={{ width: '200px' }} src={avatar} />
      <ImgCrop rotate>
        <UploadButton
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleUpdateAvatar}
        >
          {uploadButton}
        </UploadButton>
      </ImgCrop>
    </>
  );
};

export default ChangeAvatar;
