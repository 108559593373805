import { ReactElement } from 'react';

import Modal from 'components/commons/Modal';
import Button from 'libraries/components/commons/Button';

import ShowMarkdown from 'libraries/components/markdown/ShowMarkdown';

interface ModalProp {
  isModalVisible: boolean;
  comment: string;
  closeModal: () => void;
}

const HistoryCommentModal = ({ isModalVisible, comment, closeModal }: ModalProp): ReactElement => {
  return (
    <Modal
      title="Lịch sử nộp bài"
      visible={isModalVisible}
      onOk={closeModal}
      closable={false}
      keyboard={false}
      width={800}
      footer={[
        <Button.Primary key="submit" onClick={closeModal}>
          OK
        </Button.Primary>,
      ]}
    >
      <ShowMarkdown markdown={comment} />
    </Modal>
  );
};

export default HistoryCommentModal;
