import { ReactElement } from 'react';

import UserAvatarSidebar from 'layouts/layout-components/UserAvatarSidebar';
import Nav from './Nav';
import Overview from './Overview';
import MediaQuery from 'layouts/layout-components/MediaQuery';

const Sidebar = (): ReactElement => {
  return (
    <aside>
      <UserAvatarSidebar />

      <MediaQuery on={['lg', 'xl', 'xxl']}>
        <Overview />
      </MediaQuery>

      <MediaQuery on={['xs', 'sm', 'md']}>
        <Nav />
      </MediaQuery>
    </aside>
  );
};

export default Sidebar;
