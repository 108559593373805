import { Menu } from 'antd';
import styled from 'styled-components';

const NavMenu = styled(Menu)`
  &&& {
    background-color: transparent;
    border: 0;
  }
`;

export default NavMenu;
