import { Input, Rate } from 'antd';
import { RateType } from 'models/class.model';
import { ChangeEvent, ReactElement } from 'react';

import styled from 'styled-components';

const RateTag = styled.div`
  p {
    font-size: 16px;
    font-weight: 500;
    margin: 0 8px 0 0;
  }
  & .rate-star {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
  }
  & .ant-rate-text {
    font-size: 14px;
  }
  & .rate-content {
    textarea {
      margin-top: 8px;
    }
    margin-top: 20px;
  }
`;

const { TextArea } = Input;

const rateLesson = ['Nhàm chán', 'Không thú vị', 'Bình thường', 'Dễ hiểu', 'Hấp dẫn'];

const rateTeacher = [
  'Không tương tác',
  'Chưa hướng dẫn chi tiết',
  'Có sự tương tác',
  'Giảng bài dễ hiểu',
  'Giảng bài & hướng dẫn tường tận',
];

interface PropsType extends RateType {
  handleRate: (field: string, value: number | string) => void;
}

const RateContent = ({
  vote_content,
  vote_teacher,
  student_note,
  handleRate,
}: PropsType): ReactElement => {
  return (
    // TODO: handle when user vote again
    <RateTag>
      <div className="rate-star">
        <p>Chất lượng buổi học</p>
        <span>
          <Rate
            tooltips={rateLesson}
            onChange={(value: number) => handleRate('vote_content', value)}
            value={vote_content}
          />
          {vote_content ? (
            <span className="ant-rate-text">{rateLesson[vote_content - 1]}</span>
          ) : (
            ''
          )}
        </span>
      </div>
      <div className="rate-content">
        <p>Góp ý chi tiết </p>
        <TextArea
          rows={4}
          value={student_note}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            handleRate('student_note', e.target.value)
          }
        />
      </div>
    </RateTag>
  );
};

export default RateContent;
