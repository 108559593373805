import { ReactElement } from 'react';

interface LessonNoteProp {
  title: string;
}

const LessonNote = ({ title }: LessonNoteProp): ReactElement => {
  return (
    <div>
      <span>{title}</span>
    </div>
  );
};

export default LessonNote;
