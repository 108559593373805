import { Popconfirm } from 'antd';
import { ReactElement } from 'react';
interface DeleteButtonProps {
  handleDelete: () => void;
}

const DeleteButton = ({ handleDelete }: DeleteButtonProps): ReactElement => {
  function confirm() {
    handleDelete();
  }

  return (
    <Popconfirm
      title="Xác nhận xoá bình luận"
      onConfirm={confirm}
      okText="Đồng ý"
      cancelText="Huỷ"
      placement="bottom"
    >
      <span key="comment-reply" className="reply-btn">
        Xoá
      </span>
    </Popconfirm>
  );
};

export default DeleteButton;
