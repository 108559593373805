import { Col } from 'antd';
import styled from 'styled-components';

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

const Title = styled.span`
  font-size: 18px;
  font-weight: 600;
`;

const Content = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const CountAnswer = styled.span`
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  margin-left: 10px;
  padding: 3px 5px;
  background-color: #ddd;
`;

const Count = styled.span`
  display: inline-block;
  font-size: 14px;
  padding: 3px 20px;
  border: 1px solid #8c8c8d;
  border-radius: 50px;
`;

const Score = styled(Col)`
  padding-right: 10px;
  margin-bottom: 16px;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    background: #f5fdff;
    border-radius: 6px;
    border: 1px solid #01a7c2;
    gap: 4px;
    @media screen and (max-width: 567px) {
      flex-direction: row;
      justify-content: space-between;
    }
    p {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0px;
      color: #4c4c47;
      &.score {
        font-size: 16px;
        color: #ed6b86;
      }
    }
    @media screen and (min-width: 992px) {
      padding: 12px 16px;
      p {
        font-size: 16px;
        &.score {
          font-size: 20px;
        }
      }
    }
  }
  /* @media screen and (min-width: 992px) {
    padding: 15px;
  } */
`;

export default { Container, Header, Title, Content, CountAnswer, Count, Score };
