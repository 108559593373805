import { useEffect } from 'react';

const useTitle = (title: string | undefined): void => {
  if (!title) return;

  useEffect(() => {
    document.title = title;
  }, [title]);
};

export default useTitle;
