import styled from 'styled-components';

const TableContainer = styled.div`
  width: 100%;
  table {
    .ant-select,
    .ant-input {
      width: 100%;
    }
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    padding: 8px;
  }
  .ant-table-selection-column {
    width: 3%;
  }
  .ant-table-column-sorters {
    align-items: flex-start;
  }
  .ant-table-thead > tr > th {
    font-size: 14px;
    font-weight: 600;
    /* .ant-input {
      min-width: 150px;
    } */
  }
  @media screen and (max-width: 991px) {
    overflow-x: auto;
    table {
      width: auto;
    }
  }
`;

export default TableContainer;
