import { ReactElement } from 'react';

interface ReplayButtonProps {
  handleReply: () => void;
}

const ReplyButton = ({ handleReply }: ReplayButtonProps): ReactElement => {
  return (
    <span key="comment-reply" className="reply-btn" onClick={handleReply}>
      Trả lời
    </span>
  );
};

export default ReplyButton;
