import EmptyData from 'components/commons/EmptyData';
import MediaQuery from 'layouts/layout-components/MediaQuery';
import Drawer from 'layouts/learning/drawer/Drawer';
import { Lesson } from 'models/class.model';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetLectureCommentsQuery } from 'services/comment.service';
import { useGetLecturesByClassIdQuery } from 'services/onlinecourse.service';
import { selectIsSidebarCollapse } from 'store/general.slice';
import styled from 'styled-components';
import useMedia from 'utils/useMedia';
import LessonsMenu from './menu/LessonsMenu';
import LecturePageContainer from './style/LecturePageContainer';

const ContentContainer = styled.div`
  position: relative;
  @media screen and (max-width: 991px) {
    position: static !important;
  }
  @media screen and (min-width: 992px) {
    height: 100vh;
    // overflow-y: scroll;
  }
`;

const getTotalLessons = (lessons: Lesson[]) => {
  return lessons.reduce((total, item) => {
    total += item.lectures.length;
    return total;
  }, 0);
};

const WelcomePage = (): ReactElement => {
  const { xs, sm, md } = useMedia();
  const isSidebarCollapse = useSelector(selectIsSidebarCollapse);

  const { classId, lessonId, lectureId } = useParams<{
    classId: string;
    lessonId: string;
    lectureId: string;
  }>();
  const { data: course, isLoading, error } = useGetLecturesByClassIdQuery(classId);
  useGetLectureCommentsQuery({ classId, lectureId });

  const lessons = course?.lessons ?? ([] as Lesson[]);
  const totalLessons = getTotalLessons(lessons);

  return (
    <LecturePageContainer>
      <ContentContainer
        style={{
          // paddingTop: '66px',
          marginRight: xs || sm || md ? 0 : isSidebarCollapse ? 0 : 320,
        }}
      >
        <EmptyData title="Chào mừng đến buổi học" />
      </ContentContainer>

      <MediaQuery on={['lg', 'xl', 'xxl']}>
        <LessonsMenu
          totalLessons={totalLessons}
          currentLesson={lessonId}
          currentLecture={lectureId}
          isLoading={isLoading}
        />
      </MediaQuery>

      <MediaQuery on={['xs', 'sm', 'md']}>
        <Drawer>
          <LessonsMenu
            totalLessons={totalLessons}
            currentLesson={lessonId}
            currentLecture={lectureId}
          />
        </Drawer>
      </MediaQuery>
    </LecturePageContainer>
  );
};

export default WelcomePage;
