import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { selectClass } from 'store/class.slice';
import styled from 'styled-components';
import ClassStatus from './ClassStatus';

const OverViewWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  @media screen and (min-width: 992px) {
    padding: 20px;
  }
`;

const ClassOverview = (): ReactElement => {
  const classroom = useSelector(selectClass);

  const { total_learns, learned, absences } = classroom;

  return (
    <OverViewWrapper>
      <ClassStatus
        totalLessons={total_learns}
        totalAbsentLessons={learned}
        totalNotAbsentLessons={absences}
      />
    </OverViewWrapper>
  );
};

export default ClassOverview;
