import { ReactElement } from 'react';
import { Result, Button } from 'antd';
import styled from 'styled-components';

interface PropsType {
  title: string;
  returnLink: string;
  returnTitle: string;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmptyClass = ({ title, returnLink, returnTitle }: PropsType): ReactElement => {
  return (
    <Container>
      <Result
        title={title}
        extra={
          <a href={returnLink}>
            <Button type="primary">{returnTitle}</Button>
          </a>
        }
      />
    </Container>
  );
};

EmptyClass.defaultProps = {
  title: 'Bạn chưa có lớp học nào',
  returnLink: 'https://techmaster.vn/khoa-hoc/',
  returnTitle: 'Đăng ký học',
};

export default EmptyClass;
