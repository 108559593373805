import ContentRoute from 'layouts/layout-components/ContentRoute';
import { ReactElement } from 'react';
import { routes } from 'routers/learning.route';
import Header from './header';

const LearningLayout = (): ReactElement => {
  return (
    <div>
      <Header />
      <ContentRoute routes={routes} useTransition={false} />
    </div>
  );
};

export default LearningLayout;
