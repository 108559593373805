import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import authReducer from './auth.slice';
import classReducer from './class.slice';
import generalReducer from './general.slice';
import onlinecourseReducer from './onlinecourse.slice';
import commentSlice from './comment.slice';
import imageSlice from 'libraries/store/image.slice';

import { authApi } from 'libraries/services/auth.service';
import { mediaApi } from 'libraries/services/media.service';
import { classesApi } from 'services/class.service';
import { onlineCourseApi } from 'services/onlinecourse.service';
import { commentApi } from 'services/comment.service';
import { transactionApi } from 'services/transaction.service';
import practiceSlice from './practice.slice';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [classesApi.reducerPath]: classesApi.reducer,
    [onlineCourseApi.reducerPath]: onlineCourseApi.reducer,
    [commentApi.reducerPath]: commentApi.reducer,
    [mediaApi.reducerPath]: mediaApi.reducer,
    [transactionApi.reducerPath]: transactionApi.reducer,
    auth: authReducer,
    class: classReducer,
    practices: practiceSlice,
    general: generalReducer,
    onlinecourse: onlinecourseReducer,
    comments: commentSlice,
    image: imageSlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(classesApi.middleware)
      .concat(onlineCourseApi.middleware)
      .concat(commentApi.middleware)
      .concat(mediaApi.middleware)
      .concat(transactionApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
