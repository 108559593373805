import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

interface GeneralState {
  openDrawer: boolean;
  backLink: string;
  isSidebarOpen: boolean;
  isSidebarCollapse: boolean;
}

const initialState: GeneralState = {
  openDrawer: false,
  backLink: '',
  isSidebarOpen: false,
  isSidebarCollapse: false,
};

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    showDrawer: (state) => {
      state.openDrawer = true;
    },
    hideDrawer: (state) => {
      state.openDrawer = false;
    },
    setBackLink: (state, action) => {
      state.backLink = action.payload;
    },
    showSidebar: (state) => {
      state.isSidebarOpen = true;
    },
    hideSidebar: (state) => {
      state.isSidebarOpen = false;
    },
    collapseSidebar: (state) => {
      state.isSidebarCollapse = true;
    },
    expandSidebar: (state) => {
      state.isSidebarCollapse = false;
    },
  },
});

export const {
  showDrawer,
  hideDrawer,
  setBackLink,
  showSidebar,
  hideSidebar,
  collapseSidebar,
  expandSidebar,
} = generalSlice.actions;
export const selectOpenDrawer = (state: RootState): boolean => state.general.openDrawer;
export const selectBackLink = (state: RootState): string => state.general.backLink;
export const selectIsSidebarOpen = (state: RootState): boolean => state.general.isSidebarOpen;
export const selectIsSidebarCollapse = (state: RootState): boolean =>
  state.general.isSidebarCollapse;
export default generalSlice.reducer;
