import { Menu } from 'antd';
import styled from 'styled-components';

const NavItem = styled(Menu.Item)`
  &&& {
    height: 56px;
    line-height: 56px;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 30px;
    transition: all 0s;
  }

  &&&.ant-menu-item-selected {
    background-color: #0275d8;

    a {
      color: #ffffff;
    }
  }
`;

export default NavItem;
