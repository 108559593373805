import { ReactElement } from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';

import Progress from './Progress';

interface ClassStatusProp {
  totalLessons: number;
  totalAbsentLessons: number;
  totalNotAbsentLessons: number;
}

const Label = styled.span`
  display: block;
  font-weight: 600;
  font-size: 18px;
`;

const ProgressContainer = styled.div`
  position: relative;
`;

const RowStyle = styled(Row)`
  margin-top: 15px;
  .exam {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 10px;
    &.absent {
      background-color: #0275d8;
    }
    &.not-absent {
      background-color: #ee6464;
    }
    &.total {
      background-color: rgba(2, 117, 216, 0.43);
    }
  }
`;

const ClassStatus = ({
  totalLessons,
  totalAbsentLessons,
  totalNotAbsentLessons,
}: ClassStatusProp): ReactElement => {
  const percentAbsent =
    totalAbsentLessons === 0
      ? 0
      : totalAbsentLessons > totalLessons
      ? 100
      : (totalAbsentLessons / totalLessons) * 100; // % đi học

  const percentTotal =
    totalAbsentLessons + totalNotAbsentLessons > totalLessons
      ? 100
      : ((totalAbsentLessons + totalNotAbsentLessons) / totalLessons) * 100;
  return (
    <>
      <Label>Tiến độ hoàn thành: {Math.floor(percentTotal)}%</Label>

      <ProgressContainer>
        <Progress showInfo={false} percent={percentTotal} success={{ percent: percentAbsent }} />
      </ProgressContainer>

      <RowStyle gutter={[20, 0]}>
        <Col xs={24} xl={12}>
          <span className="exam absent"></span>Số buổi đi học: {totalAbsentLessons}
        </Col>

        <Col xs={24} xl={12}>
          <span className="exam not-absent"></span>Số buổi nghỉ: {totalNotAbsentLessons}
        </Col>

        <Col xs={24}>
          <span className="exam total"></span>Tổng số buổi: {totalLessons}
        </Col>
      </RowStyle>
    </>
  );
};

ClassStatus.defaultProps = {
  totalLessons: 12,
  totalAbsentLessons: 0,
  totalNotAbsentLessons: 0,
};

export default ClassStatus;
