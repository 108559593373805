import { ReactElement, useState, useEffect } from 'react';
import { LectureComment } from 'models/comment.model';
import { Comment, Tooltip, Avatar, message } from 'antd';
import timeDistanceToNow from 'utils/timeDistanceToNow';
import ReplyButton from './buttons/ReplyButton';
import EditButton from './buttons/EditButton';
import DeleteButton from './buttons/DeleteButton';
import ShowMarkdown from 'libraries/components/markdown/ShowMarkdown';
import SubComment from './SubComment';
import { selectUser } from 'store/auth.slice';
import { useDispatch, useSelector } from 'react-redux';
import { CommentEditor, EditCommentEditor } from './CommentEditor';
import { selectLecture } from 'store/onlinecourse.slice';
import {
  useDeleteCommentMutation,
  useEditCommentMutation,
  useReplyCommentMutation,
} from 'services/comment.service';
import { replyUserComment } from 'store/comment.slice';
import { useParams } from 'react-router';
interface CommentState extends LectureComment {

  lectureId?: string;
  setShowCommentEditor: (value: boolean) => void;
}

const NewUserComment = ({
  id,
  text,
  author_name,
  author_avatar,
  author_id,
  created_at,
  sub_comments,
  setShowCommentEditor,
}: CommentState): ReactElement => {
  const user = useSelector(selectUser);
  const lecture = useSelector(selectLecture);
  const { id: userId } = user;
  const { id: lectureId } = lecture;
  const { classId: classId } = useParams<{ classId: string }>();
  const [editComment] = useEditCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();
  const [replyComment] = useReplyCommentMutation();

  const [contentComment, setContentComment] = useState('');
  // const [showMainComment, setShowMainComment] = useState(true);
  const [showReplyCommentEditor, setShowReplyCommentEditor] = useState(false);
  const [showEditCommentEditor, setShowEditCommentEditor] = useState(false);
  const [contentReplyComment, setContentReplyComment] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    setContentComment(text);
  }, [text]);

  const handleCancel = () => {
    setShowReplyCommentEditor(false);
    setShowCommentEditor(true);
  };

  // const handleSubmit = () => {
  //   setShowMainComment(false);
  // };

  const showMainCommentEditor = (value: boolean) => {
    setShowCommentEditor(value);
    // setShowReplyCommentEditor(value);
    // setShowEditCommentEditor(value);
  };

  const handleChange = (e: string) => {
    setContentReplyComment(e);
  };

  //// DELETE
  const handleDelete = () => {
    deleteComment({ classId, lectureId, commentId: id })
      .unwrap()
      .then(() => message.success('Xoá bình luận thành công'))
      .catch((error) => {
        message.error(error.data);
      });
  };

  //// EDIT
  const handleEdit = () => {
    setShowEditCommentEditor(true);
    setShowReplyCommentEditor(false);
    setShowCommentEditor(false);
  };

  const handleEditComment = (e: string) => {
    setContentComment(e);
  };

  const handleEditCommentSubmit = () => {
    setShowEditCommentEditor(false);
    setShowReplyCommentEditor(false);
    setShowCommentEditor(true);
    editComment({ lectureId, commentId: id, contentEdit: { text: contentComment }, classId })
      .unwrap()
      .then(() => message.success('Sửa bình luận thành công'))
      .catch((error) => {
        message.error(error.data);
      });
  };

  const handleCancelReply = () => {
    setShowEditCommentEditor(false);
    setShowReplyCommentEditor(false);
    setShowCommentEditor(true);
  };

  //// REPLY
  const handleReply = () => {
    setShowReplyCommentEditor(true);
    setShowCommentEditor(false);
    setShowEditCommentEditor(false);
    setContentReplyComment('');
  };

  const handleReplySubmit = () => {
    setShowReplyCommentEditor(false);
    setShowCommentEditor(true);
    replyComment({ data: { text: contentReplyComment, parent_id: id }, lectureId, classId })
      .unwrap()
      .then((response) => {
        const temp = { ...response, parent_id: id };

        const data = {
          parentId: id,
          sub_comment: temp,
        };
        dispatch(replyUserComment(data));
        message.success('Trả lời bình luận thành công');
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  const multiAction = [
    <ReplyButton key="comment-reply" handleReply={handleReply} />,
    <EditButton key="comment-edit" handleEdit={handleEdit} />,
    <DeleteButton key="comment-delete" handleDelete={handleDelete} />,
  ];

  const singleAction = [<ReplyButton key="comment-reply" handleReply={handleReply} />];

  return (
    <>
      <Comment
        actions={userId === author_id ? multiAction : singleAction}
        author={<h3>{author_name}</h3>}
        avatar={<Avatar src={author_avatar} alt={author_name} />}
        content={
          showEditCommentEditor ? (
            <EditCommentEditor
              value={contentComment}
              handleChange={handleEditComment}
              handleSubmit={handleEditCommentSubmit}
              handleCancel={handleCancelReply}
            />
          ) : (
            <ShowMarkdown markdown={contentComment} />
          )
        }
        datetime={
          <Tooltip title={created_at}>
            <span>{timeDistanceToNow(created_at)}</span>
          </Tooltip>
        }
      >
        {/* Render when have sub_comments */}
        {sub_comments &&
          sub_comments.map((sub_comment) => (
            <SubComment
              key={sub_comment.id}
              sub_comment={sub_comment}
              showMainCommentEditor={showMainCommentEditor}
            />
          ))}

        {showReplyCommentEditor && (
          <CommentEditor
            value={contentReplyComment}
            handleChange={handleChange}
            handleSubmit={handleReplySubmit}
            handleCancel={handleCancel}
            replyComment={true}
          />
        )}
      </Comment>
    </>
  );
};

export default NewUserComment;
